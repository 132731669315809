import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import exportingImg from '../../../Assets/Our Products and Export process/one-big-blank-sealed-package-with-product-presented-top-plastic-box-with-other-packages 1.png';
import privateLabelImg from '../../../Assets/Our Products and Export process/closeup-courier-attaching-address-label-package-while-working-office 1.png';
import qualityImg from '../../../Assets/Our Products and Export process/exclusive-premium-quality-brand-graphic-concept 1.png';
import { Link } from 'react-router-dom';

function OurProductionAndExportProcess() {
  const cards = [
    {
      img: exportingImg,
      title: "Exporting Services",
      subtitle: "From Us to You: Reliable, efficient, and world-class export solutions.",
    },
    {
      img: privateLabelImg,
      title: "Green Spice Extracts",
      subtitle: "Nature Refined: Sustainable, high-quality spice extracts for every industry.",
    },
    {
      img: qualityImg,
      title: "Quality Assurance",
      subtitle: "Purity and Precision: Elevating standards with rigorous quality checks at every stage. ",
    }
  ];

  return (
    <div className='mt-[120px] sm:mx-[80px] mx-4'>
      <div className='sm:text-[40px] text-[32px] text-center text-[#070C3D]'>Our Production and Export Process</div>
      <div className='text-[16px] sm:w-[628px] font-light text-center mt-4 text-[#4B4B4B] mx-auto'>
        Crafting Excellence, Delivering Globally: A seamless blend of precision and passion in every step.
      </div>

      <Swiper
        spaceBetween={30}
        slidesPerView={1}
        className='mt-14'
        breakpoints={{
          640: { slidesPerView: 1 },
          768: { slidesPerView: 2 },
          1024: { slidesPerView: 3 },
        }}
      >
        {cards.map((card, index) => (
          <SwiperSlide key={index}>
            <Link to="services">
              <div className="relative group overflow-hidden rounded-3xl  shadow-lg">
                <img src={card.img} alt={card.title} className="w-full h-full object-cover transition-transform duration-300 transform group-hover:scale-110" />


                <h3 className="absolute bottom-4 left-4 text-white sm:text-[28px] text-[20px] font-medium transition-opacity duration-300 opacity-100 group-hover:opacity-0">
                  {card.title}
                </h3>


                <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-end p-[20px] sm:p-[35px] opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                  <h3 className="text-white sm:text-[28px] text-[20px]  font-medium mb-2">{card.title}</h3>
                  <p className="text-gray-200 text-[16px] sm:font-normal font-light sm:leading-6">{card.subtitle}</p>
                </div>
              </div>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default OurProductionAndExportProcess;
