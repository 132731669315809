import React, { useEffect, useRef } from "react";
import AbtImg1 from "../../../Assets/AboutUsHome/aboutImg1.png";
import AbtImg2 from "../../../Assets/AboutUsHome/aboutImg2.png";
import AbtImg3 from "../../../Assets/AboutUsHome/aboutImg3.png";
import iconRead from "../../../Assets/AboutUsHome/iconRead.svg";
import '../AboutUSHome/abtHome.css'
import { Link } from "react-router-dom";

function AboustUsHome() {
    const foundationYear = 2019;
  const currentYear = new Date().getFullYear();
  const yearsOfTrust = currentYear - foundationYear;

  const imageRefs = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add("show");
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.3 }
    );

    imageRefs.current.forEach(img => {
      if (img) observer.observe(img);
    });
  }, []);
  
  return (
    <div className="flex flex-col sm:flex-row sm:mt-[120px] sm:px-[80px] px-4 mt-[72px]   ">
      {/* Left Section - Images */}
      <div className="sm:flex hidden flex-col items-center sm:items-start">
      <div className="flex relative">
        {/* 4 Years of Trust Box */}
        <div className="absolute top-[110px] left-[90px] w-[184px] h-[184px] transform bg-white px-4 py-2 rounded-[24px] justify-center items-center z-10">
          <div className="text-[82px] mt-[43px] item-center justify-center leading-[24px] font-semibold text-[#2DC3EF]">
            {yearsOfTrust}
            <span className="text-[34px] font-light text-[#71CCE6]">
              Years
            </span>
          </div>
          <div className="text-[37px] font-normal text-[#71CCE6] text-center">
            Of Trust
          </div>
        </div>

        {/* Images */}
        <div>
          <div
            ref={el => (imageRefs.current[0] = el)}
            className="fade-in h-[196px] w-[193px] rounded-2xl object-cover transition-transform duration-300 hover:scale-105"
          >
            <img src={AbtImg3} alt="Spices" />
          </div>
          <div
            ref={el => (imageRefs.current[1] = el)}
            className="fade-in mt-[24px] h-[334px] w-[193px] rounded-[16px] object-cover transition-transform duration-300 hover:scale-105"
          >
            <img src={AbtImg2} alt="Shipping" />
          </div>
        </div>
        <div
          ref={el => (imageRefs.current[2] = el)}
          className="fade-in h-[551px] w-[302px] ml-[24px] rounded-2xl object-cover transition-transform duration-300 hover:scale-105"
        >
          <img src={AbtImg1} alt="Herbal Extract" />
        </div>
      </div>
    </div>
      {/* Right Section - Text Content */}
      <div className="sm:w-1/2 sm:ml-[132px]">
        <div className="sm:text-[24px] text-xl font-normal">About Us</div>
        <div className="sm:text-[40px] text-[32px] text-[#070C3D] font-normal sm:leading-[48px] leading-[32px] sm:mt-6 mt-4 ">
          Premier Exporter in the <br /> Realm of natural products
        </div>
        <div className="mt-6 sm:text-[16px]   text-justify text-base sm:leading-[32px] leading-[28px] text-[#4B4B4B] font-light justify-start ">
        King Life LLC is a globally recognized leader in producing high-quality natural extracts and value-added ingredients derived from spices, herbs, and botanicals. Our diverse product portfolio includes essential oils, oleoresins, natural flavors, and health-enhancing ingredients tailored for the food, beverage, personal care, cosmetics, and nutraceutical industries. With a commitment to purity, safety, and sustainability, we deliver innovative solutions that enhance flavor, color, and nutritional value.King Life LLC prides itself on advanced extraction techniques, strict quality control, and the ability to customize products to meet client-specific needs. Trusted by partners worldwide, we continue to set industry standards with our dedication to excellence and environmental responsibility. 
        </div>
        <Link to ='about'>
        <button className="border sm:mt-[51px] mt-[32px] sm:flex border-[#2DC3EF] sm:w-[181px] w-[153px] h-[56px] sm:h-[56px] rounded-lg font-normal items-center text-[#2DC3EF] text-[18px] px-8 py-4 group">
          Read More{" "}
          <img src={iconRead} className="ml-1 sm:block hidden  transform transition-transform duration-300 group-hover:translate-x-1 " alt="" />
        </button>
        </Link>
       
      </div>
    </div>
  );
}

export default AboustUsHome;
