import React from 'react';
import Slider from 'react-slick';
import prof1 from '../../../Assets/Our Client Says/Rectangle 42.png';
import prof2 from '../../../Assets/Our Client Says/Rectangle 42 (1).png';
import prof3 from '../../../Assets/Our Client Says/Rectangle 42 (2).png';
import Quote from '../../../Assets/Our Client Says/quote.svg';
import './ClientSays.css';

const testimonials = [
  {
    name: "Justin Samuel",
    company: "RGW Exports and Imports Pvt Ltd",
    text: "Working with King Life LLC has been transformative for our business. Their team’s professionalism and commitment to quality are unmatched. We’ve seen significant improvements in efficiency and client satisfaction since partnering with them.",
    image: prof1,
  },
  {
    name: "Abraham",
    company: "VN Logistics",
    text: "King Life LLC truly understands the needs of our industry. Their solutions have streamlined our operations and helped us scale seamlessly. We value their expertise and look forward to a continued partnership.",
    image: prof2,
  },
  {
    name: "Romeo Roshan",
    company: "SVN Exports and Imports Pvt Ltd",
    text: "Exceptional service and incredible results! King Life LLC has been a reliable partner from day one, providing tailored solutions that perfectly meet our needs. Highly recommend their services to any business looking to grow.",
    image: prof3,
  },
];

function OurClientSay() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  return (
    <div className="mt-[120px] sm:mx-[80px]">
      <div className="sm:text-[40px] text-[32px] text-center text-[#070C3D] fadeInText">What Our Client Say</div>
      <div className="text-[16px] sm:w-[411px] font-light text-center mt-4 text-[#4B4B4B] mx-auto fadeInDelay">
        Trusted by clients worldwide, their words inspire our commitment to quality and excellence.
      </div>

      <div className="sm:mt-20 mt-10 px-7">
        <Slider {...settings}>
          {testimonials.map((testimonial, index) => (
            <div key={index} className="p-4 relative">
              <div className="bg-white p-6  min-h-[340px] fullBoxShadow text-center relative">
                <div><img src={Quote} alt="" /></div>

                {/* Profile Image Container */}
                <div className="w-16 h-16 rounded-full overflow-hidden absolute left-1/2 transform -translate-x-1/2 -top-8">
                  <img src={testimonial.image} alt={testimonial.name} className="w-full h-full object-cover" />
                </div>

                {/* Text Content */}
                <h3 className="text-[20px] font-normal text-black mt-10 fadeInDelay">{testimonial.name}</h3>
                <p className="text-[16px] font-light text-[#4B4B4B] fadeInDelay">{testimonial.company}</p>
                <p className="text-[16px] font-light text-[#4B4B4B] leading-6 mt-4 fadeInLongDelay">{testimonial.text}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default OurClientSay;
