import React from "react";
import { motion } from "framer-motion";
function calculateYearsSince(startYear) {
  const currentYear = new Date().getFullYear();
  return currentYear - startYear;
}

function OurStoryMission() {
  const foundingYear = 2019; // Replace with King Life LLC's founding year
  const years = calculateYearsSince(foundingYear);

  return (
    <div className="mt-[120px] sm:mx-[80px] text-[#FFFFFF]">
      {/* Title */}
      <div
        className="sm:text-[40px] text-[32px] justify-center text-center text-[#070C3D]"

      >
        Our Story & Mission
      </div>

      {/* Subtext */}
      <div
        className="text-[16px] sm:max-w-[628px] font-light text-center mt-4 text-[#4B4B4B] mx-auto"

      >
        "King life LLC crafts premium natural extracts, enriching products
        across industries with purity, sustainability, and innovation, driven by
        a commitment to quality and customer satisfaction worldwide."
      </div>

      {/* Animated Section */}
      <div
        className="sm:flex justify-between sm:bg-OurStoryMission bg-AboutOurStoryMob bg-cover sm:h-[406px] w-full h-[566px] mx-auto sm:mt-[56px] sm:rounded-2xl"
       
       >
        {/* Left Content */}
        <motion.div
          className="sm:mt-[60px] sm:ml-[80px] ml-4 mt-8"
          whileInView={{ opacity: 1, x: 0 }}
          initial={{ opacity: 0, x: -50 }}
          transition={{ duration: 0.8, delay: 0.6 }}
          viewport={{ once: true }}
        >
          <div className="text-[40px] font-medium pt-8 sm:pt-0">
            {years} Years
          </div>
          <div className="text-[14px] font-light leading-6 sm:w-[432px] tracking-wider">
            For {years} years, King Life has delivered exceptional natural
            ingredients, combining advanced extraction techniques with a
            commitment to sustainability, quality, and innovation in every
            product
          </div>
        </motion.div>

        {/* Right Content */}
        <motion.div
          className="text-right sm:mt-[225px] mt-[110px] sm:mr-[80px] mr-4"
          whileInView={{ opacity: 1, x: 0 }}
          initial={{ opacity: 0, x: 50 }}
          transition={{ duration: 0.8, delay: 0.8 }}
          viewport={{ once: true }}
        >
          <div className="text-[40px] font-medium">30+ Products</div>
          <div className="text-[14px] font-light leading-6 sm:w-[432px] tracking-wider">
            With 30+ premium products, including essential oils and oleoresins,
            King Life enhances flavor, color, and health benefits, delivering
            clean, natural ingredients for exceptional client creations.
          </div>
        </motion.div>
      </div>
    </div>
  );
}

export default OurStoryMission;
