import React from 'react';
import Slider from 'react-slick';
import articleImg from '../../../Assets/Latest Insights/Rectangle 44.png';
import rightDoubleArrow from '../../../Assets/keyboard_double_arrow_right.svg';
import Article from "../../../Assets/Latest Insights/article.svg"
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './LatestInsights.css';
import { NavLink } from 'react-router-dom';
import { text } from 'framer-motion/client';

const Insights = [
    {
        name: "Unlocking the Power of Natural Ingredients in Skincare",
        text: "Discover how natural ingredients can enhance skin health and beauty. Learn about the latest trends in skincare and how our products incorporate these powerful, nature-based solutions to benefit your skin."
    },
    {
        name: "Fragrance Innovations: What's Next in the Perfume Industry?",
        text: "Stay updated with the newest advancements in fragrance formulation. Explore how emerging technologies and trends are shaping the future of perfumes and what this means for our unique product line"
    }
]

function LatestInsights() {
    // Settings for the Slick carousel
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768, // Displays two items side-by-side for screens wider than 768px
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                },
            },
        ],
    };

    return (
        <div className='mt-[120px] sm:mx-[80px] mx-4'>
            {/* Title and Description */}
            <div className='sm:text-[40px] text-[28px] text-center text-[#070C3D]'>Latest Insights</div>
            <div className='text-[16px] font-light text-center mt-4 text-[#4B4B4B]'>
                Harnessing Nature’s Finest: Revolutionizing Skincare with Pure and Powerful Ingredients
            </div>

            {/* Carousel */}
            <div className='sm:mt-[56px] px-3 sm:px-0'>
                <Slider {...settings}>
                    {Insights.map((article, index) => (
                        <div key={index} className='px-2'> {/* Added padding for spacing */}
                            <div className='flex flex-col lg:flex-row gap-6'>

                                <div className='relative'>
                                    <img src={articleImg} className='w-full md:w-[302px] md:h-[232px] h-auto' alt="article" />

                                    {/* Badge - positioned top right */}
                                    <div className="absolute top-0 left-0">
                                        <img src={Article} alt="" />
                                    </div>
                                </div>

                                <NavLink to="blogPage">
                                    <div className='group md:w-[302px] text-[#4B4B4B] hover:text-[#324D9F]'>
                                        <div className='text-[20px] font-medium leading-6 mt-6 md:mt-0 group-hover:underline'>
                                            {article.name}
                                        </div>
                                        <div className='text-[14px] font-normal leading-6 mt-2 md:mt-4 '>
                                            {article.text}
                                        </div>
                                        <div className='btn flex gap-1 mt-4 cursor-pointer'>
                                            <div className=''>Read more</div>
                                            <img src={rightDoubleArrow} className='h-6 w-6 Btnarrow' alt="arrow icon" />
                                        </div>
                                    </div>
                                </NavLink>

                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
}

export default LatestInsights;
