import React, { useState } from 'react';
import "./AdminProductPage.css";
import DeleteIcon from "../../../Assets/Admin/delete.svg";
import RightArrow from "../../../Assets/Admin/chevron_forward.svg";
import Swal from 'sweetalert2';
import CloseIcon from "../../../Assets/header/close.svg";



function AdminProductPage() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [isSubProductModalOpen, setIsSubProductModalOpen] = useState(false);
  const [isSubPreviewModalOpen, setIsSubPreviewModalOpen] = useState(false);
  const [categoryName, setCategoryName] = useState('');
  const [image, setImage] = useState(null);
  const [categories, setCategories] = useState([]);
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(null);
  const [subProductName, setSubProductName] = useState('');
  const [subProductDescription, setSubProductDescription] = useState('');
  const [subProductImage, setSubProductImage] = useState(null);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);

    setCategoryName('');
    setImage(null);
  };

  const closePreviewModal = () => {
    setIsPreviewModalOpen(false);
  };

  const openSubProductModal = (index) => {
    setSelectedCategoryIndex(index);
    setIsSubProductModalOpen(true);
  };

  const closeSubProductModal = () => {
    setIsSubProductModalOpen(false);
    setSubProductName('');
    setSubProductDescription('');
    setSubProductImage(null);
  };
  const closeSubPreviewModal = () => {
    setIsSubPreviewModalOpen(false);
  };
  const handlePublish = (subProductIndex) => {
  const updatedSubProducts = [...selectedCategory.subProducts];
  updatedSubProducts[subProductIndex].published = true;  
  const updatedCategory = { ...selectedCategory, subProducts: updatedSubProducts };
  const updatedCategories = [...categories];
  updatedCategories[selectedCategoryIndex] = updatedCategory;
  setCategories(updatedCategories);  
};


const handleSave = () => {
  if (!categoryName.trim()) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Category name cannot be empty.',
    });
    return;
  }
  const newCategory = {
    categoryName,
    image,
    subProducts: [],
  };
  setCategories([...categories, newCategory]);
  closeModal();
};


  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  const handleSubProductFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSubProductImage(file);
    }
  };

  const handleAddSubProduct = () => {
  if (!subProductName.trim()) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Sub-Product name cannot be empty.',
    });
    return;
  }
  const updatedCategories = categories.map((category, index) => {
    if (index === selectedCategoryIndex) {
      return {
        ...category,
        subProducts: [
          ...category.subProducts,
          { subProductName, subProductDescription, subProductImage }
        ]
      };
    }
    return category;
  });
  setCategories(updatedCategories);
  closeSubProductModal();
};


const handleDelete = (index) => {
  Swal.fire({
    title: 'Are you sure?',
    text: 'You won\'t be able to revert this!',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, delete it!',
    cancelButtonText: 'No, keep it'
  }).then((result) => {
    if (result.isConfirmed) {
      setCategories(categories.filter((_, i) => i !== index));
      if (selectedCategoryIndex === index) {
        setSelectedCategoryIndex(null);
      } else if (selectedCategoryIndex > index) {
        setSelectedCategoryIndex(selectedCategoryIndex - 1);
      }
    }
  });
};
const handleSubProductDelete = (subProductIndex) => {
  Swal.fire({
    title: 'Are you sure?',
    text: 'You won\'t be able to revert this!',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, delete it!',
    cancelButtonText: 'No, keep it'
  }).then((result) => {
    if (result.isConfirmed) {
      const updatedCategories = categories.map((category, index) => {
        if (index === selectedCategoryIndex) {
          const updatedSubProducts = category.subProducts.filter((_, i) => i !== subProductIndex);
          return { ...category, subProducts: updatedSubProducts };
        }
        return category;
      });
      setCategories(updatedCategories);
    }
  });
};
const handlePublishToggle = (subProductIndex) => {
  const updatedSubProducts = [...selectedCategory.subProducts];
  updatedSubProducts[subProductIndex].published = !updatedSubProducts[subProductIndex].published;
  const updatedCategory = { ...selectedCategory, subProducts: updatedSubProducts };
  const updatedCategories = [...categories];
  updatedCategories[selectedCategoryIndex] = updatedCategory;
  setCategories(updatedCategories);  
};


  const selectedCategory = selectedCategoryIndex !== null ? categories[selectedCategoryIndex] : null;

  
  const isSaveButtonDisabled = !categoryName.trim() || !image;
  const isSubSaveButtonDisabled = !subProductName.trim() || !subProductDescription || !subProductImage;

  return (
    <div className="p-10 w-full">
      {!selectedCategory && (
        <div className="flex justify-between">
          <div className="font-normal text-[20px]">All Product Categories</div>
          <button onClick={openModal} className="px-6 py-2 rounded-lg bg-[#707FDD] text-white font-normal text-[16px]">
            Create New
          </button>
        </div>
      )}

      {selectedCategory ? (
  <div className="mt-10">
    <div className="flex justify-between items-center">
      <div className='flex items-center'>
        <button
          onClick={() => setSelectedCategoryIndex(null)}
          className="rounded-lg text-[#6E6E6E] font-normal text-[20px]"
        >
          All Product Categories
        </button>
        <h2 className="font-normal text-[20px] flex items-center ml-2">
          <img className='mr-2' src={RightArrow} alt="" />
          {selectedCategory.categoryName}
        </h2>
      </div>
      <button
        onClick={() => openSubProductModal(selectedCategoryIndex)}
        className="mt-4 px-6 py-2 rounded-lg bg-[#707FDD] text-white font-normal text-[16px]"
      >
        Create New
      </button>
    </div>

    {/* Display Sub-Products */}
    <div className="mt-8">
    {selectedCategory.subProducts.length === 0 ? (
      <p className="text-gray-600 mt-2">No sub-products available.</p>
    ) : (
      <ul className="space-y-2 mt-3">
        {selectedCategory.subProducts.map((subProduct, subProductIndex) => (
          <li key={subProductIndex} className="bg-white custom-box-shadow flex justify-between items-center  py-4 pl-[12px] pr-[38px] border-l-[12px] border-transparent hover:border-[#B7BDE3] transition-all duration-300">
            <div className="">
              <div>
                <div className="text-[#4B5DD3] font-medium text-[14px]">{subProduct.subProductName}</div>
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <div
                onClick={() => handlePublishToggle(subProductIndex)}
                className={`text-[14px] font-light border px-4 py-2 rounded-md cursor-pointer   transition-all duration-200 ${
                  subProduct.published ? 'text-white bg-[#707FDD] ' : 'text-[#4B5DD3] border-[#707FDD]'
                }`}
              >
                {subProduct.published ? 'Un Publish' : 'Publish'}
              </div>
            </div>
          </li>
        ))}
      </ul>
    )}
  </div>
  </div>
) : (
  // categories list rendering
  <div className="mt-8">
    {categories.length === 0 ? (
      <p className="text-gray-600">No categories available. Click "Create New" to add one.</p>
    ) : (
      <ul className="space-y-2">
        {categories.map((category, categoryIndex) => (
          <li key={categoryIndex} onClick={() => setSelectedCategoryIndex(categoryIndex)} className="bg-white custom-box-shadow cursor-pointer">
            <div className="flex justify-between items-center text-[14px] font-normal   py-4 pl-[12px] pr-[38px] border-l-[12px] border-transparent hover:border-[#B7BDE3] transition-all duration-300">
              <span className="text-[#4B5DD3] font-normal text-[14px] cursor-pointer">{category.categoryName}</span>
              <div className="flex items-center space-x-[20px] font-normal text-[14px]">
                <span className="text-[#4B5DD3] cursor-default">
                  {category.subProducts.length} {category.subProducts.length === 1 ? 'product' : 'products'}
                </span>
               
              </div>
            </div>
          </li>
        ))}
      </ul>
    )}
  </div>
)}



      {/* Modal for Adding Category */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-10 rounded-lg w-[807px] max-w-full mx-4">
            <h2 className="text-[14px] font-light">Add Product Category</h2>
            <input
              type="text"
              placeholder="Category Name"
              value={categoryName}
              onChange={(e) => setCategoryName(e.target.value)}
              className="bg-[#F7F7F7] rounded-lg px-4 py-3 mt-3 w-full text-[16px] font-light focus:outline-none placeholder:text-[16px] placeholder:font-light"
            />
            <div className="text-[14px] font-light mt-6">Add Image</div>
            <div className="flex">
              <div className="mt-3 flex items-center bg-[#F8FCFF] p-4 w-fit">
                <label className="border-[2px] border-[#707FDD] rounded cursor-pointer flex items-center text-[#707FDD] font-normal px-4 py-2 text-[14px]">
                  Choose file
                  <input type="file" onChange={handleFileChange} className="hidden" />
                </label>
                <span className="ml-2 text-gray-500 text-sm">
                  {image ? image.name : 'No file chosen'}
                </span>
              </div>
              {image && (
                <button
                  onClick={() => setIsPreviewModalOpen(true)}
                  className="mt-3 text-blue-500 underline ml-3"
                >
                  Preview Image
                </button>
              )}
            </div>



            <div className="flex justify-end mt-6">
              <button
                onClick={closeModal}
                className="px-8 py-[15px] border-[2px] border-[#707FDD] text-[#707FDD] rounded-lg text-[16px] font-medium"
              >
                Cancel
              </button>
              <button
                onClick={handleSave}
                className={`px-8 py-[15px] ${isSaveButtonDisabled
                  ? 'bg-[#707FDD] opacity-50 text-white cursor-not-allowed'
                  : 'bg-[#707FDD] text-white'
                  } rounded-lg text-[16px] font-medium ml-4`}
                disabled={isSaveButtonDisabled}
              >
                Save
              </button>

            </div>
          </div>
        </div>
      )}


      {/* Preview Modal */}
      {isPreviewModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6  w-[700px]  mx-4">
          <div className='flex justify-between items-end'>
            <h2 className="text-[16px] font-light mb-4">Image Preview</h2>
            <button
                onClick={closePreviewModal}
                className='mb-4'
              >
              <img src={CloseIcon} alt="" />
              </button>
            </div>
            {image ? (
              <img
                src={URL.createObjectURL(image)}
                alt="Preview"
                className="w-full h-auto r shadow-sm"
              />
            ) : (
              <p className="text-gray-500 text-sm">No image selected</p>
            )}
            <div className="flex justify-end mt-6">
           
            </div>
          </div>
        </div>
      )}


      {/* Modal for Adding Sub-Product */}
      {isSubProductModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-10 rounded-lg w-[807px] max-w-full mx-4">
            <h2 className="text-[14px] font-light">Add Sub-Product</h2>
            <input
              type="text"
              placeholder="Sub-Product Name"
              value={subProductName}
              onChange={(e) => setSubProductName(e.target.value)}
              className="bg-[#F7F7F7] rounded-lg px-4 py-3 mt-3 w-full text-[16px] font-light focus:outline-none placeholder:text-[16px] placeholder:font-light"
            />
            <textarea
              placeholder="Description"
              value={subProductDescription}
              onChange={(e) => setSubProductDescription(e.target.value)}
              className="bg-[#F7F7F7] rounded-lg px-4 py-3 mt-3 w-full text-[16px] font-light focus:outline-none placeholder:text-[16px] placeholder:font-light"
            />
            <div className="text-[14px] font-light mt-6">Add Image</div>
         <div className='flex'>
              <div className='mt-3 flex items-center bg-[#F8FCFF] p-4 w-fit'>
                  <label className="border-[2px] border-[#707FDD] rounded cursor-pointer flex items-center text-[#707FDD] font-normal px-4 p-2 text-[14px]">
                    Choose file
                    <input
                      type="file"
                      onChange={handleSubProductFileChange}
                      className="hidden"
                    />
                  </label>
                  <span className="ml-2 text-gray-500 text-sm">
                    {subProductImage ? subProductImage.name : "No file chosen"}
                  </span>
                </div>
  
                {subProductImage && (
                  <button
                    onClick={() => setIsSubPreviewModalOpen(true)}
                    className="mt-3 text-blue-500 underline ml-3"
                  >
                    Preview Image
                  </button>
                )}
         </div>
            <div className="flex justify-end mt-6">
              <button
                onClick={closeSubProductModal}
                className="px-8 py-[15px] border-[2px] border-[#707FDD] text-[#707FDD] rounded-lg text-[16px] font-medium"
              >
                Cancel
              </button>
              <button
                onClick={handleAddSubProduct}
                className={`px-8 py-[15px] ${isSubSaveButtonDisabled
                    ? "bg-[#707FDD] opacity-50 text-[#A9A9A9] cursor-not-allowed"
                    : "bg-[#707FDD] text-white"
                  } rounded-lg text-[16px] font-medium ml-4`}
                disabled={isSubSaveButtonDisabled}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}

       {/* Sub-Product Preview Modal */}
       {isSubPreviewModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6  w-[700px] ">
          <div className="flex justify-between  items-center">
            <h2 className="text-[16px] font-light mb-4">Image Preview</h2>
            <button
                onClick={closeSubPreviewModal}
                className="mb-4"
              >
                <img src={CloseIcon} alt="" />
              </button>
              </div>
            {subProductImage ? (
              <img
                src={URL.createObjectURL(subProductImage)}
                alt="Preview"
                className="w-full h-auto  shadow-sm"
              />
            ) : (
              <p className="text-gray-500 text-sm">No image selected</p>
            )}
           
         
       
          </div>
        </div>
      )}

    </div>
  );
}

export default AdminProductPage;
