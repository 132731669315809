import React from "react";
function calculateYearsSince(startYear) {
  const currentYear = new Date().getFullYear();
  return currentYear - startYear;
}

function AboutOurstory() {

  const foundingYear = 2019; // Replace with King Life LLC's founding year
  const years = calculateYearsSince(foundingYear);

  return (
    <div className="mt-[120px] sm:mx-[80px]  text-[#FFFFFF]">
      <div className="sm:text-[40px] font-normal text-[32px] justify-center text-center text-[#070C3D]">
        Our Story & Mission
      </div>
      <div className="text-[16px] sm:w-[628px] font-light text-center mt-4 text-[#4B4B4B] mx-auto">
      Empowering industries with natural excellence, driven by innovation, sustainability, and a passion for quality 
      </div>
      <div className="sm:flex justify-between sm:bg-OurStoryMission bg-AboutOurStoryMob bg-cover sm:rounded-[16px]  sm:h-[406px] w-full h-[566px] mx-auto sm:mt-[56px] mt-[32px]">
        <div className="sm:mt-[60px] sm:px-[80px] px-4 pt-8 sm:pt-0">
          <div className="text-[40px]  font-medium ">{years} Years</div>
          <div className="text-[14px] font-light leading-6 sm:w-[432px] tracking-wider">
            For {years} years, King Life has delivered exceptional natural
            ingredients, combining advanced extraction techniques with a
            commitment to sustainability, quality, and innovation in every
            product
          </div>
        </div>
        <div className="text-right sm:mt-[225px] mt-[160px]  sm:px-[80px] px-4">
          <div className="text-[40px] font-medium">30+ Products</div>
          <div className="text-[14px] font-light leading-6 sm:w-[432px] tracking-wider">
            With 30+ premium products, including essential oils and oleoresins,
            King Life enhances flavor, color, and health benefits, delivering
            clean, natural ingredients for exceptional client creations.
          </div>
        </div>
      </div>
      <div className="sm:flex sm:mt-[48px] mt-[72px] px-6 sm:px-0">
        <div>
          <h1 className="text-[#070C3D] font-medium text-[32px] ">
            {" "}
            Our Mission
          </h1>
          <p className="text-[#4B4B4B] text-justify text-[16px] sm:leading-6 leading-7 font-light mt-[16px] sm:mt-[16px] ">
            At King Life LLC, our mission is to harness the power of nature to
            craft safe, effective, and sustainable solutions that meet global
            needs. We are dedicated to maintaining the highest standards in
            sourcing, production, and quality control, ensuring that every
            product exceeds customer expectations. Guided by innovation and
            environmental responsibility, we aim to inspire healthier, more
            sustainable living. Through long-term partnerships and
            customer-centric solutions, we strive to be a global leader in
            natural extracts and value-added ingredients.
          </p>
        </div>
        <div className="sm:ml-[182px] sm:mt-0 mt-[72px]">
          <h1 className="text-[#070C3D]  font-medium text-[32px]">Our Story</h1>
          <p className="text-[#4B4B4B] text-justify text-[16px] sm:leading-6 leading-7 font-light mt-[16px] sm:mt-[16px] ">
            King Life LLC was founded with a vision to unlock the extraordinary
            benefits of nature through botanicals, spices, and herbs. With 16
            years of expertise, we have become a trusted name in producing
            premium-quality extracts and natural ingredients. Our journey began
            with a simple mission: to transform nature’s bounty into innovative
            solutions that enrich lives. From essential oils to oleoresins, our
            diverse portfolio caters to industries such as food, beverages,
            cosmetics, and nutraceuticals. By blending traditional knowledge
            with cutting-edge technology, we ensure our products deliver
            exceptional quality, purity, and value to clients worldwide.
          </p>
        </div>
      </div>
    </div>
  );
}

export default AboutOurstory;
