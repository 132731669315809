import React from "react";
import NewsDetailsBanner1 from "../../../Assets/News Details Page/Rectangle 5540 (3).png";
import NewsDetailsBanner2 from "../../../Assets/News Details Page/Rectangle 5541.png";
import MoreNewsImg from "../../../Assets/News Details Page/Rectangle 389 (3).png";
import upArrow from "../../../Assets/arrow_insert.svg";
import Slider from "react-slick";
import "./NewsDetailPage.css";
import { useNavigate } from "react-router-dom";

const newses = [
  {
    name: "Millane L",
    company: "SVN Exports an Imports Pvt Ltd",
    text: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type",
    image: MoreNewsImg,
  },
  {
    name: "Abraham",
    company: "Marco Logistics",
    text: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type",
    image: MoreNewsImg,
  },
];

function NewsDetailPage() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const navigate = useNavigate();

  const goToContact = () => {
    navigate("/contact");
  };
  return (
    <div className="sm:px-[80px] px-4">
      <div className="pt-[144px]">
        <div className="flex gap-6">
          <div>
            <img src={NewsDetailsBanner1} className="max-w-full"></img>
          </div>
          <div className="hidden md:block">
            <img src={NewsDetailsBanner2} className="max-w-full"></img>
          </div>
        </div>
        <div className="mt-[40px]">
          <div className="text-[32px] leading-10 font-normal">
            Kinglife LLC Unveils New Essential Oil Collection
          </div>
          <div className="text-[16px] font-light leading-7 mt-8 text-[#4B4B4B] text-justify">
            Expanding Our Natural Flavor Portfolio As consumer demand for
            clean-label, natural ingredients continues to grow, Kinglife LLC is
            excited to introduce an expanded range of botanical flavors that
            bring authenticity and vibrant taste to food and beverage products.
            These flavors are expertly crafted to not only elevate the sensory
            experience but also meet today’s need for ingredients that are as
            natural as they are effective. Why Choose Natural Botanical Flavors?
            Natural botanical flavors are valued for their purity and the unique
            benefits they bring to culinary creations: Authentic Taste: Sourced
            from real botanicals, our flavors capture the essence of nature,
            offering true-to-source tastes that enhance the authenticity of food
            and drink products. Clean-Label Advantage: As consumers increasingly
            seek transparency, our flavors support clean-label initiatives with
            ingredients they can trust—free from synthetic additives, artificial
            colors, and preservatives. Rich, Vibrant Colors: Derived from
            natural sources, many of our botanical flavors also bring a splash
            of vibrant, eye-catching color to foods and beverages, adding visual
            appeal without artificial dyes. our discerning
            <br></br>
            <br></br>
            New Flavors to Transform Your Products Our expanded portfolio
            includes a range of exciting new flavors crafted to add depth and
            innovation to your product line: Citrus Bliss: A bright, refreshing
            blend of citrus notes that pairs well with beverages, baked goods,
            and confections. Herbal Fusion: Earthy, aromatic flavors inspired by
            herbs like basil and rosemary, ideal for savory snacks and culinary
            seasonings. Berry Burst: A naturally sweet profile that enhances
            smoothies, dairy products, and plant-based beverages with a hint of
            indulgence. Exotic Spices: Warm, comforting spice flavors, including
            cinnamon and cardamom, that add depth to teas, sauces, and dessert
            items. Our Commitment to Quality and Innovation Kinglife LLC
            combines traditional expertise with modern technology to ensure that
            every natural flavor is crafted to the highest standards. Using
            advanced extraction techniques like supercritical CO₂ and steam
            distillation, we capture the pure essence of each botanical,
            providing flavors that are as potent as they are true-to-source.
            Experience the Future of Natural Flavor Whether you're looking to
            develop a new product or enhance an existing one, our expanded
            natural flavor portfolio offers a versatile range of options to
            inspire innovation. Discover how our botanical flavors can bring
            authenticity, vibrancy, and health-conscious appeal to your food and
            beverage creations.
          </div>
        </div>
      </div>
      <div className="sm:mt-[120px] mt-[72px]">
        <div className="text-[20px] font-normal lg:px-8 px-3">
          More Latest News
        </div>
        <div className="newsdetailpage-carousel">
          <Slider {...settings} className="mt-0">
            {newses.map((news, index) => (
              <div key={index}>
                {" "}
                {/* Added padding for spacing */}
                <div className=" max-w-[411px] mx-auto">
                  <img
                    src={news.image}
                    className="sm:w-[411px] sm:h-[228px] object-cover rounded-xl "
                    alt="article"
                  />
                  <div className="text-[14px] font-normal text-[#4B4B4B] sm:mt-5 mt-4">
                    March 24 2024
                  </div>
                  <div className="text-[20px] font-medium mt-4">
                    Expanding Our Natural Flavor Portfolio
                  </div>
                  <div className="text-[14px] font-normal leading-6 text-[#4B4B4B] mt-2">
                    Kinglife LLC introduces new botanical flavors, enhancing
                    food and drinks with authentic taste, vibrant color, and
                    natural purity.
                  </div>
                  <div className="flex items-center gap-2 mt-4 cursor-pointer">
                    <div className="text-[16px] text-[#4B4B4B]">Read more</div>
                    <img
                      src={upArrow}
                      className="h-4 w-4 hover:translate-x-1 hover:duration-100"
                    ></img>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <div>
        <div className="sm:mt-[120px] mt-[72px] ">
          <h1 className="sm:text-[40px] text-[32px] sm:font-normal ">
            Need more help?
          </h1>
          <p className="sm:text-[16px] sm:font-light mt-4">
            Contact us for any additional questions or support regarding our
            products
          </p>
          <button
            onClick={goToContact}
            className="text-[16px] font-normal text-white px-8 py-4 mt-6 rounded-lg bg-[#31C0F2]"
          >
            Contact Us
          </button>
        </div>
      </div>
    </div>
  );
}

export default NewsDetailPage;
