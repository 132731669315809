import React, { useState } from "react";
import arrowUp from "../../../Assets/Faq/arrowUp.svg";
import arrowDown from "../../../Assets/Faq/arrowDown.svg";

function FaqComponent() {
  const [open, setOpen] = useState(null);

  const toggleAccordion = (index) => {
    setOpen(open === index ? null : index); // Toggle open/close
  };

  const faqs = [
    {
      question: "Where does it come from? ",
      answer:
        "At King Life LLC, we source our products from trusted farms and suppliers worldwide. Every ingredient undergoes rigorous quality checks to ensure freshness, purity, and adherence to global standards. Our team ensures the journey from farm to you is seamless, preserving the essence of natural excellence. ",
    },
    {
      question: "What are herbal extracts?",
      answer:
        "Herbal extracts are concentrated solutions derived from plants. They capture the active compounds, flavors, and aromas of herbs, offering natural benefits for health, wellness, and more. At King Life LLC, we craft herbal extracts using eco-friendly processes to maintain their potency and purity.",
    },
    {
      question: "What is the minimum order quantity?",
      answer:
        "The minimum order quantity depends on the product category. For most of our offerings, the minimum starts at [specify number, e.g., 500 units or kilograms]. This ensures we provide the best pricing and value for bulk purchases while meeting industry requirements.",
    },
    {
      question: "How do i place an order?",
      answer:
        "Placing an order with King Life LLC is simple! You can reach out to us via email, phone, or through our website. Share your product requirements, and our team will guide you through the process, ensuring prompt delivery and exceptional service.",
    },
  ];
  return (
    <div className="sm:px-[80px] px-6 sm:mt-[120px] mt-[72px]">
      <div className="text-[40px] hidden sm:block text-[#070C3D] font-normal text-center">
        Frequently Asked Questions
      </div>
      <div className="text-[32px] sm:hidden block text-[#070C3D] font-normal text-center ">
        FAQ
      </div>
      <div className="text-base text-[#4B4B4B] leading-6 font-light justify-center text-center sm:mt-[32px] mt-[24px] ">
        Explore everything you need to know about our products, processes, and services at King Life LLC </div>

      <div className="sm:mt-[40px] mt-[40px]">
        {faqs.map((faq, index) => (
          <div key={index} className="">
            <button
              onClick={() => toggleAccordion(index)}
              className="flex justify-between items-center w-full py-4 sm:py-[32px] text-left text-black sm:text-[20px]  text-[16px] font-light focus:outline-none"
            >
              <span>{faq.question}</span>
              <img
                src={open === index ? arrowUp : arrowDown}
                alt="Toggle Icon"
                className="w-4 h-4 mr-4"
              />
            </button>
            <div
              className={`overflow-hidden border-b  border-[#E6E6E6]  items-center transition-max-height duration-300 ${open === index ? "max-h-40" : "max-h-0"
                }`}
            >
              <p className="text-[#4B4B4B] font-light  text-justify text-[16px] sm:pb-8 sm:py-0  py-4">
                {faq.answer}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FaqComponent;
