import React, { useState } from 'react';
import BannerImage from "../../../Assets/BannerImage/BannerImg.png";
import Rectangle from "../../../Assets/BannerImage/Rectangle 12.svg";
import BtnArrow from "../../../Assets/BannerImage/call_made.svg";
import BtnArrowBlue from "../../../Assets/BannerImage/call_made-blue.svg";
import FiveYear from "../../../Assets/BannerImage/5years 1.png";
import "./Banner.css";
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

function Banner() {

  const [hoverExplore, setHoverExplore] = useState(false);


  return (
    <div
      className="bg-cover bg-center sm:bg-BannerImage bg-BannerImageMob w-full  sm:pt-[35px] "
    >

      <div className='sm:h-[229px] h-[149px]'></div>

      <div className='text-white bg-black bg-opacity-50 sm:px-20 sm:py-20 px-4 pb-[58px] pt-0 '>
        <img className='w-[84px] h-[84px] justify-center mx-auto pb-1 sm:hidden block ' src={FiveYear} alt="" />
        <div className='text-white flex sm:text-[20px] text-[14px] font-light leading-5 items-center'>
          <img className='sm:mr-4 mr-2 sm:w-[60px] w-[32px] h-[2px]' src={Rectangle} alt="" />The #1 trading agency for your all needs
          <img className='sm:w-[60px] sm:h-[60px] sm:mr-[11px] hidden sm:block ' src={FiveYear} alt="" />
        </div>
        <div className='sm:block hidden sm:text-[56px] uppercase sm:leading-[72px] text-white mt-2'>
          <div>
            <motion.h1
              className="font-semibold"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
            >
              We are committed to <br />
            </motion.h1>
            <motion.span
              className="sm:font-light"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1.5, delay: 0.5 }}
            >
              quality services & products
            </motion.span>
          </div>

        </div>
        <div className='sm:hidden block text-[32px] uppercase sm:leading-[72px] text-white mt-2'>
          <motion.h1
            className='font-semibold'

            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            We are committed to <br />
          </motion.h1>
          <motion.span className='font-light '
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.5, delay: 0.5 }}
          >quality services & <br />products</motion.span>

        </div>
        <div className='sm:block hidden sm:text-[16px] font-light sm:mt-10'>
          We bring success to our customers through innovative fragrances, outstanding service
        </div>
        <div className=' sm:hidden block text-[14px] font-light mt-8'>
          We bring success to our customers through innovative <br /> fragrances, outstanding service
        </div>
        <div className='flex sm:mt-8 mt-[27px]'>
          <Link to="productPage">
            <button
              className='btn border border-[#2DC3EF] bg-[#2DC3EF] text-white rounded-lg px-6 py-5'
              onMouseEnter={() => setHoverExplore(true)}
              onMouseLeave={() => setHoverExplore(false)}
            >
              <span className='sm:text-[16px] font-light'>Explore Our Products</span>
              <img className='Btnarrow ml-2' src={BtnArrow} alt="" />
            </button>
          </Link>
          <div className='sm:block hidden'>
            <Link to="services">
              <button
                className='btn  border border-[#2DC3EF] text-[#2DC3EF] rounded-lg px-6 py-5 sm:ml-4'

              >
                <span className='sm:text-[16px] font-light'>Our Quality Standards</span>
                <img className='Btnarrow ml-2' src={BtnArrowBlue} alt="" />
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Banner;
