import React, { useState, useEffect, useRef } from 'react';
import Logo from "../../../Assets/header/King Life 2.svg";
import Logo2 from "../../../Assets/header/King Life 2 (1).svg";
import DropDown from "../../../Assets/DropDown.svg";
import MenuIcon from "../../../Assets/header/MenuIcon.svg";
import CloseIcon from "../../../Assets/header/close.svg";
import { NavLink, useLocation } from 'react-router-dom';
import BlueDropDown from "../../../Assets/header/stat_minus_1.svg"


function Header() {
  const location = useLocation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('Eng');
  const [isScrolled, setIsScrolled] = useState(false);
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
    setIsDropdownOpen(false);
    console.log(`Language changed to: ${language}`);
  };

  const toggleSideMenu = () => {
    setIsSideMenuOpen(!isSideMenuOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Determine colors and icons based on route and scroll status
  const isBlogPage = location.pathname === '/blogPage';
  const isBlogDetailPage = location.pathname.startsWith('/BlogPage/blogdetails/');
  const isLatestDetailPage = location.pathname === '/newsdetails';
  const isTermsAndCondition = location.pathname === '/terms-and-conditions';
  const isPrivacyPolicy = location.pathname === '/privacy-policy';


  // Adjust navbar color, link text color, and dropdown icon based on the route and scroll

  const linkTextColor = (isBlogPage || isBlogDetailPage || isLatestDetailPage || isTermsAndCondition || isPrivacyPolicy) ? '#033746' : (isScrolled ? '#033746' : 'white');
  const dropDownIcon = (isBlogPage || isBlogDetailPage || isLatestDetailPage || isTermsAndCondition || isPrivacyPolicy) ? BlueDropDown : (isScrolled ? BlueDropDown : DropDown);
  const logoToDisplay = (isBlogPage || isBlogDetailPage || isLatestDetailPage || isTermsAndCondition || isPrivacyPolicy) ? Logo2 : (isScrolled ? Logo2 : Logo);


  useEffect(() => {
    console.log("Current path:", location.pathname);
    console.log("Is Blog Page:", isBlogPage);
    console.log("Link Text Color:", linkTextColor);
    console.log("Dropdown Icon:", dropDownIcon);
  }, [location.pathname, isBlogPage, linkTextColor, dropDownIcon]);

  return (
    <div
      className={`sm:px-[80px] z-50 sm:py-3 flex w-full items-center justify-between px-4 py-2 fixed ${isScrolled ? 'bg-white sm:bg-opacity-65 sm:backdrop-blur-[25px]' : ' bg-cover bg-center'}`}
    >
      <div className='sm:hidden block'>
        <img src={MenuIcon} alt="Menu Icon" onClick={toggleSideMenu} />
      </div>
      <div><NavLink to="/"><img className='sm:w-[184px] sm:h-[65px] w-[141px] h-[49px] ' src={logoToDisplay} alt="Company Logo" /></NavLink></div>
      <div className='sm:block hidden'>
        <ul className='flex gap-8'>
          <li className={`font-light hover:text-[#2DC3EF]`} style={{ color: linkTextColor }}>
            <NavLink
              to="/"
              className={({ isActive }) => (isActive ? 'font-medium text-[#0A96C3] ' : 'font-light')}
            >
              Home
            </NavLink>
          </li>
          <li className={`font-light hover:text-[#2DC3EF]`} style={{ color: linkTextColor }}>
            <NavLink
              to="/about"
              className={({ isActive }) => (isActive ? 'font-medium text-[#0A96C3]' : 'font-light')}
            >
              About Us
            </NavLink>
          </li>
          <li className={`font-light hover:text-[#2DC3EF]`} style={{ color: linkTextColor }}>
            <NavLink
              to="/productPage"
              className={({ isActive }) => (isActive ? 'font-medium text-[#0A96C3]' : 'font-light')}
            >
              Products
            </NavLink>
          </li>
          <li className={`font-light hover:text-[#2DC3EF]`} style={{ color: linkTextColor }}>
            <NavLink
              to="/services"
              className={({ isActive }) => (isActive ? 'font-medium text-[#0A96C3]' : 'font-light')}
            >
              Services
            </NavLink>
          </li>
          <li className={`font-light hover:text-[#2DC3EF]`} style={{ color: linkTextColor }}>
            <NavLink
              to="/blogPage"
              className={({ isActive }) => (isActive ? 'font-medium text-[#0A96C3]' : 'font-light')}
            >
              Blog
            </NavLink>
          </li>
          <li className={`font-light hover:text-[#2DC3EF]`} style={{ color: linkTextColor }}>
            <NavLink
              to="/careers"
              className={({ isActive }) => (isActive ? 'font-medium text-[#0A96C3]' : 'font-light')}
            >
              Careers
            </NavLink>
          </li>
          <li className={`font-light hover:text-[#2DC3EF]`} style={{ color: linkTextColor }}>
            <NavLink
              to="/contact"
              className={({ isActive }) => (isActive ? 'font-medium text-[#0A96C3]' : 'font-light')}
            >
              Contact
            </NavLink>
          </li>
        </ul>
      </div>

      {/* Language dropdown */}
      <div className='sm:block hidden'>
        <div
          className={`relative flex justify-center items-center cursor-pointer`}
          style={{ color: linkTextColor }}
          onClick={toggleDropdown}
          ref={dropdownRef}
        >
          {selectedLanguage}
          <img src={dropDownIcon} alt="Dropdown Icon" />

          {isDropdownOpen && (
            <div className='absolute top-full mt-2 right-0 bg-white font-normal text-[14px] text-black py-6 pl-6 pr-[111px] rounded shadow-lg'>
              <div className='cursor-pointer border-b pb-2' onClick={() => handleLanguageChange('Eng')}>English</div>
              <div className='cursor-pointer mt-2' onClick={() => handleLanguageChange('عرب')}>عرب</div>
            </div>
          )}
        </div>
      </div>


      {/* Side Menu for mobile view */}
      {isSideMenuOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-10">
          <div className="fixed left-0 top-0 w-full h-full bg-white p-5 shadow-lg z-20 px-10 ">
            <div className="flex justify-end items-center mb-4">
              <img
                src={CloseIcon}
                alt="Close Icon"
                onClick={toggleSideMenu}
                className="cursor-pointer"
              />
            </div>
            <ul className="flex flex-col gap-6 pt-4">
              <li className="text-black hover:text-[#2DC3EF]" onClick={toggleSideMenu}>
                <NavLink to="/">Home</NavLink>
              </li>
              <li className="text-black hover:text-[#2DC3EF]" onClick={toggleSideMenu}>
                <NavLink to="about">About Us</NavLink>
              </li>
              <li className="text-black hover:text-[#2DC3EF]" onClick={toggleSideMenu}>
                <NavLink to="productPage">Products</NavLink>
              </li>
              <li className="text-black hover:text-[#2DC3EF]" onClick={toggleSideMenu}>
                <NavLink to="services">Services</NavLink>
              </li>
              <li className="text-black hover:text-[#2DC3EF]" onClick={toggleSideMenu}>
                <NavLink to="blogPage">Blog</NavLink>
              </li>
              <li className="text-black hover:text-[#2DC3EF]" onClick={toggleSideMenu}>
                <NavLink to="careers">Careers</NavLink>
              </li>
              <li className="text-black hover:text-[#2DC3EF]" onClick={toggleSideMenu}>
                <NavLink to="contact">Contact</NavLink>
              </li>
            </ul>
          </div>
        </div>
      )}


    </div>
  );
}

export default Header;
