import React from 'react';
import { Route, Routes } from 'react-router-dom';

import UserLayout from './User/UserLayout';
import HomePage from './User/Pages/HomePage/HomePage';
import AboutUsDetail from './User/Pages/HomePage/AboutUsDetail';
import ProductPage from './User/Pages/ProductPage/ProductPage';
import ServicePage from './User/Pages/ServicesPage/ServicePage';
import ProductDetailedPage from './User/Pages/ProductDetailedPage/ProductDetailedPage';
import BlogPage from './User/Pages/BlogPage/BlogPage';
import ContactPage from './User/Pages/ContactPage/ContactPage';
import BlogDetailPage from './User/Pages/BlogDetailPage/BlogDetailPage';
import NewsDetailPage from './User/Pages/NewsDetailPage/NewsDetailPage';
import CareerPage from './User/Pages/CareerPage/CareerPage';
import CareerApply from './User/Component/CareerPageForm/CareerApply';
import AdminLayout from './Admin/AdminLayout';
import AdminHomePage from './Admin/Pages/AdminHomePage/AdminHomePage';
import AdminProductPage from './Admin/Pages/AdminProductPage/AdminProductPage';
import AdminServicePage from './Admin/Pages/AdminServicePage/AdminServicePage';
import AdminCareerPage from './Admin/Pages/AdminCareerPage/AdminCareerPage';
import AdminEnquiryPage from './Admin/Pages/AdminEnquiryPage/AdminEnquiryPage';
import AdminGeneralPage from './Admin/Pages/AdminGeneralPage/AdminGeneralPage';
import AdminBlogPage from './Admin/Pages/AdminBlogPage/AdminBlogPage';
import ScrollToTop from './User/ScrollToTop';
import TermsAndCondition from './User/Pages/TermsAndConditionPage/TermsAndCondition';
import PrivacyPolicyPage from './User/Pages/PrivacyPolicy/PrivacyPolicyPage';

function App() {
  return (
    <div className="App font-Kanit">
      <ScrollToTop /> {/* Add ScrollToTop component here */}
      <Routes>
        <Route path="/" element={<UserLayout />}>
          <Route index element={<HomePage />} />
          <Route path="about" element={<AboutUsDetail />} />
          <Route path="productPage" element={<ProductPage />} />
          <Route path="services" element={<ServicePage />} />
          <Route path="/productPage/ProductDetailedPage/:productId" element={<ProductDetailedPage />} />
          <Route path="BlogPage" element={<BlogPage />} />
          <Route path="contact" element={<ContactPage />} />
          <Route path="/BlogPage/blogdetails/:id" element={<BlogDetailPage />} />

          <Route path="newsdetails" element={<NewsDetailPage />} />
          <Route path="careers" element={<CareerPage />} />
          <Route path="careers/apply" element={<CareerApply />} />
          <Route path="terms-and-conditions" element={<TermsAndCondition />} />
          <Route path="privacy-policy" element={<PrivacyPolicyPage />} />


        </Route>

        <Route path="/admin" element={<AdminLayout />}>
          <Route index element={<AdminHomePage />} />
          <Route path="home" index element={<AdminHomePage />} />
          <Route path="products" element={<AdminProductPage />} />
          <Route path="services" element={<AdminServicePage />} />
          <Route path="career" element={<AdminCareerPage />} />
          <Route path="enquiry" element={<AdminEnquiryPage />} />
          <Route path="general" element={<AdminGeneralPage />} />
          <Route path="blogs" element={<AdminBlogPage />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
