import React, { useState, useRef, useEffect } from 'react';
import ArrowUp from "../../../Assets/ProductPage/ExportArroiwUp.svg";
import ArrowRight from "../../../Assets/ProductPage/ExportArroiwRight.svg";
import CertificationAndAffiliation from '../../Component/CertificationAndAffiliation/CertificationAndAffiliation';
import Olesis from "../../../Assets/ProductPage/olesis.png";
import Baking from "../../../Assets/ProductPage/baking.png";
import Img3 from "../../../Assets/OurPremiumProduct/img3.png";
import MuringaPowder from "../../../Assets/ProductPage/Moringa Powder 2.jpeg";
import Aswagandha from "../../../Assets/ProductPage/Ashwagandha Powder 4.jpg";
import Spices from "../../../Assets/ProductPage/Spice powder 1.jpg";
import FrozenFoodAndVegitable from "../../../Assets/ProductPage/Frozon Food 1.jpg";
import Perfume from "../../../Assets/ProductPage/Perfume.jpg";
import Capsules from "../../../Assets/ProductPage/Capsules 6.jpg";
import Sesbania from "../../../Assets/ProductPage/E7i9nJ5I4p8WvivgMmCy--1--zt0zz.jpg";
import Lagerstromia from "../../../Assets/ProductPage/Lagerstroemia_speciosa_1 (1).jpg";
import Glycyrrhiza  from "../../../Assets/ProductPage/shutterstock_1955104771-scaled.webp";
import Ocimum  from "../../../Assets/ProductPage/ocimum-tenuiflorum-green-leaf-ocimum-sanctum-geniosporum-ten-20620.webp";
import Lavender  from "../../../Assets/ProductPage/Fragrance oil Lavender.jpg";
import Vanila  from "../../../Assets/ProductPage/profumo-vaniglia-sandalo.jpg";
import Sandal  from "../../../Assets/ProductPage/snadal.jpeg";
import Dawana  from "../../../Assets/ProductPage/Fragrance Oils 3.webp";
import Lemon  from "../../../Assets/ProductPage/lemonGrass.jpg";
import NeedMoreHelp from '../../Component/NeedMoreHelp/NeedMoreHelp';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

function ProductPage() {
  const [selectedItem, setSelectedItem] = useState("Herbal Extracts");
  const [expandedItems, setExpandedItems] = useState({ "Herbal Extracts": true });
  const containerRef = useRef(null);

  const items = [
    {
      name: "Herbal Extracts",
      subItems: [
        { id: 1, name: "Sesbania Extract", image: Sesbania, details: "A Natural Powerhouse for Wellness" },
        { id: 2, name: "Lagerstroemia Speciosa", image: Lagerstromia, details: "A Natural Boost for Health and Vitality" },
        { id: 3, name: "Glycyrrhiza Glabra (Mulethi)", image: Glycyrrhiza, details: "Nature's Remedy for Health and Wellness" },
        { id: 4, name: "Ocimum Sanctum", image: Ocimum, details: "The Queen of Herbs for Holistic Wellness" },
      ]
    },
    {
      name: "Fragrance Oils",
      subItems: [
        { id: 5, name: "Vanila", image: Vanila, details: "A Timeless Essence in Fragrance Oils." },
        { id: 6, name: "Lavender", image: Lavender, details: "A Soothing Essence in Fragrance Oils." },
        { id: 7, name: "Sandal", image: Sandal, details: "The Timeless Elegance of Fragrance Oils" },
        { id: 8, name: "Dawana", image: Dawana, details: "A Unique and Exotic Fragrance Experience" },
        { id: 9, name: "Lemon Grass", image: Lemon, details: "A Fresh and Revitalizing Fragrance" },
      ]
    },
    {
      name: "Natural Powders",
      subItems: [
        { id: 10, name: "Aswagandha powder", image: Aswagandha, details: "King Life LLC is a trusted exporter of..." },
        { id: 11, name: "Moringa powder", image: MuringaPowder, details: "King Life LLC is a trusted exporter of..." },
        { id: 12, name: "Spice powder", image: Spices, details: "King Life LLC is a trusted exporter of..." },
      ]
    },
    {
      name: "Oleoresins",
      subItems: [
        { id: 13, name: "Oleoresins in the Beverage Industry", image: Olesis, details: "Oleoresins are widely utilized in the..." },
        { id: 14, name: "Oleoresins in the Baking Industry", image: Baking, details: "Among the various types of oleoresins, van..." },
        { id: 15, name: "Other Oleoresins", image: Img3, details: "Herbal extract capsules for..." },
      ]
    },
    {
      name: "Frozen Fruits and Vegetables",
      subItems: [
        { id: 16, name: "Frozen Fruits and Vegetables", image: FrozenFoodAndVegitable, details: "Specializing in high-quality..." },
      ]
    },
    {
      name: "Perfume",
      subItems: [
        { id: 17, name: "Perfume", image: Perfume, details: "King Life LLC stands as a premier exporter..." },
      ]
    },
    {
      name: "Food Supplements",
      subItems: [
        { id: 18, name: "Capsules", image: Capsules, details: "King Life LLC stands as a premier exporter..." },
      ]
    }
  ];
  

  const handleToggle = (itemName) => {
    setExpandedItems({ [itemName]: true });
    setSelectedItem(itemName);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setExpandedItems({ [selectedItem]: true });
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [selectedItem]);

  
  return (
    <div>
      <div className="bg-cover bg-center sm:bg-AboutBanner bg-AboutBannerRes rounded-b-[16px] w-full">
        <div className='sm:h-[477px] h-[343px] flex justify-between sm:pr-[128px]'>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            className='text-white sm:text-[32px] text-[24px] font-medium sm:ml-[80px] ml-6 sm:mt-[411px] mt-[288px]'>
            Our Products
          </motion.div>
        </div>
      </div>

      <div className='sm:px-20 mt-14 px-4'>
        <div className='text-[16px] leading-7 font-light text-justify'>
          <p>King Life LLC stands as a premier exporter in the realm of natural products, specializing in a diverse array of offerings that include herbal extracts, fragrance oils, spice powders, and oleoresins. Our commitment to quality is unwavering, as we meticulously source and process each product to ensure that it meets the highest standards of purity and efficacy.</p>
        </div>

        <div className='mt-14 sm:flex grid gap-6   w-fit' ref={containerRef}>
          <div className='sm:w-[302px]'>
            <ul className='space-y-2'>
              {items.map((item) => (
                <li key={item.name}>

                  <div
                    className={`flex items-center justify-start text-[16px] font-normal leading-6 ${selectedItem === item.name ? 'text-[#036B8F] font-semibold' : ''}`}
                    onClick={() => handleToggle(item.name)}
                    style={{ cursor: 'pointer' }}
                  >

                    <img className='mr-4' src={expandedItems[item.name] ? ArrowUp : ArrowRight} alt="Toggle Arrow" />
                    {item.name}
                  </div>

                  {expandedItems[item.name] && (
                    <ul className='ml-8 space-y-2'>
                      {item.subItems.map((subItem) => (

                        <li key={subItem.name} className='text-[16px] text-[#036B8F] font-light mt-2 leading-6'>{subItem.name}</li>

                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </div>

          <div className='sm:grid grid-cols-3 sm:space-y-0 space-y-4  sm:gap-4 text-center sm:mt-0 mt-6'>
            {items
              .find((item) => item.name === selectedItem)
              ?.subItems.map((subItem) => (
                <Link to={`/productPage/ProductDetailedPage/${subItem.id}`}>
                <div key={subItem.name} className='relative group hover:rounded-3xl overflow-hidden mb-4 sm:mb-0 duration-300'>
                    <img src={subItem.image} alt={subItem.name} className="sm:w-full sm:h-[320px] w-[361px] h-[128px] object-cover" />
                    <div className="absolute bottom-0 w-full h-[50px] sm:h-auto bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex flex-col items-start text-start justify-center text-white px-4 py-2">
                      <div className="text-[20px] sm:font-normal font-light">{subItem.name}</div>
                      <p className="text-[14px] font-light hidden sm:block">{subItem.details}</p>
                    </div>
                  </div>
                </Link>
              ))}
          </div>
        </div>
      </div>
      <CertificationAndAffiliation />
      <NeedMoreHelp />
    </div>
  );
}

export default ProductPage;
