import React, { useState, useEffect } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import UploadIcon from '../../../Assets/backup.svg';
import Swal from 'sweetalert2'; // Import SweetAlert2
import '../../Component/CareerPageForm/careerApply.css'

const CareerApply = () => {
  const { id } = useParams(); // Retrieve the job ID from the URL

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    dob: '',
    qualification: '',
    resume: null,
    consent: false,
    file: null,
  });

  const [errors, setErrors] = useState({}); // Error state for validation feedback
  const [isFormValid, setIsFormValid] = useState(false); // State to track form validity
  const [isTouched, setIsTouched] = useState(false); // Track if user has interacted with the form

  const onDrop = (acceptedFiles) => {
    setFormData({
      ...formData,
      file: acceptedFiles[0],
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'application/pdf, application/msword, .doc, .docx',
    multiple: false,
  });

  // Set maximum date for date of birth to be 18 years ago
  const getMaxDate = () => {
    const today = new Date();
    today.setFullYear(today.getFullYear() - 18);
    return today.toISOString().split('T')[0];
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    // Restrict phone number to digits only using regex
    if (name === 'phoneNumber' && !/^\d*$/.test(value)) return;

    // Restrict first and last name to letters only (no numbers)
    if ((name === 'firstName' || name === 'lastName') && /[^a-zA-Z ]/.test(value)) {
      return;
    }

    setFormData((prevState) => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    }));
    setIsTouched(true); // Mark the form as touched when the user changes a field
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.firstName) newErrors.firstName = 'First Name is required';
    if (!formData.lastName) newErrors.lastName = 'Last Name is required';
    if (!formData.email) newErrors.email = 'Email is required';
    if (!formData.phoneNumber) newErrors.phoneNumber = 'Phone Number is required';
    if (!formData.dob) newErrors.dob = 'Date of Birth is required';
    if (!formData.qualification) newErrors.qualification = 'Qualification is required';
    if (!formData.file) newErrors.file = 'Resume is required';
    else if (!/\.(pdf|docx)$/i.test(formData.file.name)) newErrors.file = 'Resume must be a PDF or DOCX file';
    if (!formData.consent) newErrors.consent = 'You must accept the privacy policy';

    setErrors(newErrors);

    // Check if there are any errors
    setIsFormValid(Object.keys(newErrors).length === 0);
  };

  // Validate form on every change
  useEffect(() => {
    if (isTouched) {
      validateForm();
    }
  }, [formData, isTouched]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Only submit if form is valid
    if (!isFormValid) return;

    // Create FormData to handle file and other data
    const formDataToSubmit = new FormData();
    formDataToSubmit.append('firstName', formData.firstName);
    formDataToSubmit.append('lastName', formData.lastName);
    formDataToSubmit.append('email', formData.email);
    formDataToSubmit.append('phoneNumber', formData.phoneNumber);
    formDataToSubmit.append('dob', formData.dob);
    formDataToSubmit.append('qualification', formData.qualification);
    formDataToSubmit.append('resume', formData.file);
    formDataToSubmit.append('consent', formData.consent);
    formDataToSubmit.append('jobId', id); // Append the job ID to the form data

    // Submit form data here (API call)

    // Success Alert using SweetAlert2
    Swal.fire({
      title: 'Success!',
      text: 'Your application has been submitted successfully.',
      icon: 'success',
      confirmButtonText: 'OK',
    });

    // Reset form after successful submission
    setFormData({
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      dob: '',
      qualification: '',
      resume: null,
      consent: false,
      file: null,
    });

    // Reset the errors and touched states to restore the form to its initial state
    setErrors({});
    setIsTouched(false);
    setIsFormValid(false); // Reset form validity
  };

  const formFields = [
    { label: 'First Name', type: 'text', name: 'firstName', placeholder: 'Enter first name', required: true },
    { label: 'Last Name', type: 'text', name: 'lastName', placeholder: 'Enter second name', required: true },
    { label: 'Email', type: 'email', name: 'email', placeholder: 'Enter email', required: true },
    { label: 'Phone Number', type: 'tel', name: 'phoneNumber', placeholder: 'Enter phone number', required: true },
    { label: 'Date of Birth', type: 'date', name: 'dob', placeholder: '', required: true },
    { label: 'Highest Qualification', type: 'text', name: 'qualification', placeholder: 'Enter qualification', required: true }
  ];
  return (
    <div>
      <div className='sm:bg-CareerBanner bg-CareerBannerMob sm:h-[572px] h-[603px] bg-cover bg-center sm:text-[56px] text-[32px] font-extralight text-white text-center justify-center sm:pt-[239px] pt-[310px] '>
        Join Our Team
      </div>
      <div className="flex justify-center">
        <div className="bg-white sm:mt-[120px] mt-[72px] sm:mx-[149px] sm:px-[161px] sm:pt-[48px] sm:pb-[72px] px-[24px] mx-[16px] py-[40px] rounded-md w-full custom-shadow-box">
          <div className="flex flex-col items-center">
            <div className="text-2xl font-light text-center">Job Application Form</div>
            <div className="border-b border-[#C8C8C8] w-[133px] mt-[8px] sm:mt-[18px]"></div>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 sm:mt-[89px] mt-[39px] md:grid-cols-2 gap-10">
              {formFields.map((field, index) => (
                <div key={index} className="flex flex-col justify-start">
                  <label className="flex justify-start text-base font-light h-6">
                    {field.label} {field.required && <span className="text-red-500 text-sm font-light">*</span>}
                  </label>
                  <input
                    type={field.type}
                    name={field.name}
                    value={formData[field.name]}
                    onChange={handleChange}
                    placeholder={field.placeholder}
                    max={field.name === 'dob' ? getMaxDate() : undefined} // Set max date for dob
                    className={`mt-1 w-full border-b focus:outline-none text-sm text-[#4C4C4C] font-light border-[#AAAAAA] p-2 ${errors[field.name] ? 'border-red-500' : ''}`}
                    required={field.required}
                  />
                  {errors[field.name] && <span className="text-red-500 font-light text-sm">{errors[field.name]}</span>}
                </div>
              ))}
            </div>

            <div className="mt-12">
              <label className="flex justify-start text-base font-light">Upload Resume <span className="text-red-500 font-light text-sm">*</span></label>
              <div
                {...getRootProps()}
                className={`p-6 mt-4 w-full h-[128px] rounded-lg text-center ${isDragActive ? 'bg-[#F8F8F8]' : 'bg-[#F8F8F8]'}`}
              >
                <input {...getInputProps()} />
                {formData.file ? (
                  <p>{formData.file.name}</p>
                ) : (
                  <div>
                    <img className="mx-auto" src={UploadIcon} alt="Upload" />
                    <p className="mt-2 font-light text-[16px]">Drag & drop your resume here</p>
                    <p className="text-[#B7B7B7] font-light text-[16px]">or click to select a file</p>
                  </div>
                )}
              </div>
              {errors.file && <span className="text-red-500 font-light text-sm">{errors.file}</span>}
            </div>

            <div className="mt-12">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  name="consent"
                  checked={formData.consent}
                  onChange={handleChange}
                  className="mr-2"
                />

                <label htmlFor="consent" className="text-[14px] font-light">
                I accept the electronic saving of my data according to the <NavLink to='/privacy-policy'><span className="text-blue-500 font-normal hover:underline">Privacy Policy </span></NavLink>
                </label>
              </div>
              {errors.consent && <span className="text-red-500 font-light  text-sm">{errors.consent}</span>}
            </div>

            <div className="flex justify-center mt-12">
              <button
                type="submit"
                disabled={!isFormValid}
                className={`w-[169px] h-[48px] bg-[#003566] text-white text-[16px] font-light rounded-md flex justify-center items-center ${!isFormValid ? 'bg-[#7f7f7f]' : 'bg-[#003566]'}`}
              >
                Apply
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CareerApply;
