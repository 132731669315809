import React from "react";
import downloadIcon from "../../../Assets/Footer/downloadIcon.svg";
import twitterIcon from "../../../Assets/Footer/twitter.svg";
import fbIcon from "../../../Assets/Footer/fb.svg";
import igIcon from "../../../Assets/Footer/ig.svg";
import { NavLink } from "react-router-dom";

function Footer() {
  return (
    <div className="bg-[#FAFAFB] mt-[72px] sm:mt-[120px] sm:h-[574px] h-[840px]">
      <footer className=" sm:w-full w-full mt-8 px-4 pt-8 sm:mt-[56px] sm:px-[80px] sm:pt-[56px]">
        <div className="">
          {/* Header Section */}

          <div className="sm:flex  sm:flex-row justify-between items-center ">
            <h2 className="text-[#18181B] sm:text-[32px] text-[24px] font-normal leading-[42px] text-left ">
              Sustainable Ingredients,
              <br /> Global Impact
            </h2>
            {/* <div className="">
              <button className="bg-[#31C0F2] h-[48px] w-[232px] hover:bg-[#23ABDA] sm:w-[193px] mt-6 text-sm font-normal flex justify-center items-center   text-white px-4 py-2 rounded-md">
                <img src={downloadIcon} className="mr-2" alt="" /> Download
                Brochure
              </button>
            </div> */}
          </div>
          <div className="border hidden sm:block border-[#D7D7D7] w-full  items-center sm:mt-[56px]"></div>

          {/* Footer Content */}
          <div className="flex flex-col mt-[40px] sm:mt-[56px] sm:flex-row justify-between text-left ">
            {/* About Section */}
            <div className="sm:w-1/3">
              <h3 className="text-[#18181B] text-base font-medium text-left">
                About King Life
              </h3>
              <p className="text-[#52525B] text-sm font-normal text-justify sm:leading-[22px] mt-[24px] sm:mt-6">
                King Life LLC excels in premium natural extracts from spices,
                herbs, and botanicals, offering pure oils, oleoresins, and
                extracts to elevate food, cosmetics, and wellness products
                globally.
              </p>
              <div className=" sm:flex  hidden   sm:mt-[40px] sm:justify-start space-x-[34px]">
                <i className=" cursor-pointer">
                  {" "}
                  <img src={twitterIcon} alt="" />
                </i>
                <i className="cursor-pointer">
                  <a
                    href="https://www.facebook.com/profile.php?id=61569830904984"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={fbIcon} alt="Facebook" />
                  </a>
                </i>
                <i className="cursor-pointer">
                  <a
                    href="https://www.instagram.com/kinglife_llc/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={igIcon} alt="Instagram" />
                  </a>
                </i>
              </div>
            </div>

            <div className="flex sm:mt-0 mt-[40px]">
              {/* Company Links */}
              <div className="">
                <h3 className="text-[#18181B] text-base font-medium text-left">
                  Company
                </h3>
                <ul className=" sm:mt-6 mt-6 space-y-2  ">
                  <li className="text-[#52525B] cursor-pointer hover:underline  sm:text-sm font-normal">
                    {" "}
                    <NavLink to="about"> About </NavLink>
                  </li>
                  <li className="text-[#52525B] cursor-pointer hover:underline sm:text-sm font-normal">
                    {" "}
                    <NavLink to="services"> Services </NavLink>
                  </li>
                  <li className="text-[#52525B] cursor-pointer hover:underline sm:text-sm font-normal">
                    {" "}
                    <NavLink to="productPage"> Products </NavLink>
                  </li>
                  <li className="text-[#52525B] cursor-pointer hover:underline sm:text-sm font-normal">
                    {" "}
                    <NavLink to="blogPage"> Blogs </NavLink>
                  </li>
                  <li className="text-[#52525B] cursor-pointer hover:underline sm:text-sm font-normal">
                    {" "}
                    <NavLink to="contact"> Contact </NavLink>
                  </li>
                </ul>
              </div>

              {/* Help Links */}
              <div className="ml-[119px] sm:ml-[256px]">
                <h3 className="text-[#18181B] text-base font-medium text-left">
                  Help
                </h3>
                <ul className=" sm:mt-6 mt-6 space-y-2">
                  <li className="text-[#52525B] cursor-pointer hover:underline sm:text-sm font-normal">
                    <NavLink to="/terms-and-conditions">
                      {" "}
                      Terms and Conditions
                    </NavLink>
                  </li>

                  <li className="text-[#52525B] cursor-pointer hover:underline sm:text-sm font-normal">
                    <NavLink to="/privacy-policy"> Privacy Policy</NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="flex sm:hidden justify-center mt-[44px]  space-x-4">
            <i className=" cursor-pointer">
              {" "}
              <img src={twitterIcon} alt="" />
            </i>
            <i className="cursor-pointer ml-[35px]">
              <a
                href="https://www.facebook.com/profile.php?id=61569830904984"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={fbIcon} alt="Facebook" />
              </a>
            </i>
            <i className="cursor-pointer ml-[35px]">
              <a
                href="https://www.instagram.com/kinglife_llc/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={igIcon} alt="Instagram" />
              </a>
            </i>
          </div>

          {/* Copyright */}
          <div className="text-center text-[#3C3C3C] text-base font-normal mt-[80px] sm:mt-[48px]">
            © {new Date().getFullYear()} All rights reserved.
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
