import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// Assets imports
import img1 from "../../../Assets/BlogPage/Rectangle 5540.png";
import img2 from "../../../Assets/BlogPage/frozenfood/Frozon Food 2.jpg";
import img3 from "../../../Assets/BlogPage/frozenfood/Frozon Food 4.jpg";
import img4 from "../../../Assets/BlogPage/frozenfood/1733120919036-58233254965280377784101365659726374516.png";
import img5 from "../../../Assets/BlogPage/frozenfood/1733120919052-273944072799003953829675126857030315118.png";
import img6 from "../../../Assets/BlogPage/frozenfood/benefits-of-freezing-food-hero.jpg";
import Perfume5 from "../../../Assets/BlogPage/Perfume/Perfumes 5.jpg";
import Perfume1 from "../../../Assets/BlogPage/Perfume/Perfumes 9.jpg";
import Perfume2 from "../../../Assets/BlogPage/Perfume/Perfumes 10.jpg";
import Perfume3 from "../../../Assets/BlogPage/Perfume/product images and d (1).jpg";
import FrozenFoodAndVegitable from "../../../Assets/ProductPage/Frozon Food 1.jpg";
import CattleFeed from "../../../Assets/BlogPage/CattleFeed/Cattle Feeds 2.jpg";
import CattleFeed2 from "../../../Assets/BlogPage/CattleFeed/Cattle Feeds 3.jpg";
import CattleFeed3 from "../../../Assets/BlogPage/CattleFeed/Cattle Feeds 4.jpg";
import CattleFeed4 from "../../../Assets/BlogPage/CattleFeed/Cattle Feeds 5.jpg";
import CattleFeed5 from "../../../Assets/BlogPage/CattleFeed/cattle Feeds 6.jpg";
import Capsule1 from "../../../Assets/BlogPage/capsule/Capsules 5.jpg";
import Capsule2 from "../../../Assets/BlogPage/capsule/Capsules 6.jpg";
import Capsule3 from "../../../Assets/BlogPage/capsule/pexels-n-voitkevich-7615571.jpg";
import Capsule4 from "../../../Assets/BlogPage/capsule/pexels-pixabay-161688.jpg";
import Line from "../../../Assets/BlogPage/Line 4.svg";

// JSON data (same as provided)
const blogData = {
  "Frozen Fruits and Vegetables": [
    {
      id: 1,
      image: FrozenFoodAndVegitable,
      title:
        "The Benefits of Freezing Fresh: How Frozen Fruits and Vegetables Preserve Nutrients",
      description:
        "Freezing fresh fruits and vegetables is one of the best ways to lock in their nutrients and flavors...",
    },
    {
      id: 2,
      image: img2,
      title: "5 Ways to Use Frozen Fruits and Vegetables in Everyday Cooking",
      description:
        "Frozen fruits and vegetables are versatile ingredients that make everyday cooking easier and healthier...",
    },
    {
      id: 3,
      image: img3,
      title:
        "A Sustainable Choice: Why Frozen Fruits and Vegetables Reduce Food Waste",
      description:
        "Frozen fruits and vegetables are a sustainable choice that significantly helps reduce food waste...",
    },
    {
      id: 4,
      image: img4,
      title: (
        <span>
          Frozen vs. Fresh: Debunking Myths About <br /> Nutritional Value
        </span>
      ),
      description:
        "Frozen fruits and vegetables are versatile ingredients that make everyday cooking easier and healthier...",
    },
    {
      id: 5,
      image: img5,
      title: (
        <span>
          Sustainable Packaging: King Life LLC’s Commitment to the Environment
        </span>
      ),
      description:
        "Many believe fresh produce is always more nutritious than frozen, but this is a common misconception...",
    },
    {
      id: 6,
      image: img6,
      title: "The Growing Demand for Botanical Extracts in Wellness Products ",
      description:
        "Freezing fresh fruits and vegetables is one of the best ways to lock in their nutrients and flavors...",
    },
  ],
  "Perfumes ": [
    {
      id: 7,
      image: Perfume5,
      title: (
        <span>
          {" "}
          The Art of Perfumery: How King Life Creates <br /> Unique Fragrances{" "}
        </span>
      ),
      description:
        "Creating a signature fragrance at King Life LLC begins with sourcing the finest raw materials, including natural essential oils and aromatic botanicals...",
    },
    {
      id: 8,
      image: Perfume1,
      title: "Fragrance Families Explained: Choosing the Right Scent for You",
      description:
        "Fragrance families categorize perfumes based on their dominant scent characteristics, helping you discover your ideal match...",
    },
    {
      id: 9,
      image: Perfume2,
      title: "The Benefits of Using Natural Perfume Ingredients",
      description:
        "Fragrance families categorize perfumes based on their dominant scent characteristics, helping you discover your ideal match...",
    },
    {
      id: 10,
      image: Perfume3,
      title: (
        <span>
          {" "}
          The Power of Scent: How Perfumes Impact <br /> Emotions and Mood{" "}
        </span>
      ),
      description:
        "Fragrance families categorize perfumes based on their dominant scent characteristics, helping you discover your ideal match...",
    },
  ],
  "Wellness Capsules ": [
    {
      id: 11,
      image: Capsule1,
      title: (
        <span>
          {" "}
          Health on the Go: The Benefits of Herbal <br /> Supplement Capsules
        </span>
      ),
      description:
        "Learn about the different fragrances that can enhance your mood and style for various occasions...",
    },
    {
      id: 12,
      image: Capsule2,
      title: "King Life's Guide to Choosing the Right Supplement Capsules",
      description:
        "Learn about the different fragrances that can enhance your mood and style for various occasions...",
    },
    {
      id: 13,
      image: Capsule3,
      title:
        "Why Quality Matters: King Life’s Commitment to Safe, Effective Capsules",
      description:
        "Learn about the different fragrances that can enhance your mood and style for various occasions...",
    },
    {
      id: 14,
      image: Capsule4,
      title: (
        <span>
          {" "}
          Capsules vs. Tablets: Which Supplement Form Is <br /> Right for You?{" "}
        </span>
      ),
      description:
        "Learn about the different fragrances that can enhance your mood and style for various occasions...",
    },
  ],
  "Cattle Feeds  ": [
    {
      id: 15,
      image: CattleFeed,
      title: (
        <span>
          {" "}
          The Role of Natural Ingredients in High-Quality <br /> Cattle Feed{" "}
        </span>
      ),
      description:
        "Learn about the different fragrances that can enhance your mood and style for various occasions...",
    },
    {
      id: 16,
      image: CattleFeed2,
      title:
        "How King Life Ensures Quality and Safety in Cattle Feed Production",
      description:
        "Learn about the different fragrances that can enhance your mood and style for various occasions...",
    },
    {
      id: 17,
      image: CattleFeed3,
      title:
        "Boosting Productivity: The Importance of Balanced Nutrition in Cattle Feed",
      description:
        "Learn about the different fragrances that can enhance your mood and style for various occasions...",
    },
    {
      id: 18,
      image: CattleFeed4,
      title: (
        <span>
          {" "}
          Sustainable Cattle Feeding: Eco-Friendly <br /> Practices at King Life{" "}
        </span>
      ),
      description:
        "Learn about the different fragrances that can enhance your mood and style for various occasions...",
    },
    {
      id: 19,
      image: CattleFeed5,
      title: (
        <span>
          {" "}
          Eco Friendly Cattle Feed <br /> Practices at King Life{" "}
        </span>
      ),
      description:
        "Learn about the different fragrances that can enhance your mood and style for various occasions...",
    },
  ],
};


const categoryImages = {
  "Frozen Fruits and Vegetables": {
    icon: FrozenFoodAndVegitable,

    image: FrozenFoodAndVegitable,
  },

  Perfumes: {
    icon: Perfume5,

    image: Perfume5,
  },

  "Wellness Capsules": {
    icon: Capsule1,

    image: Capsule1,
  },

  "Cattle Feeds": {
    icon: CattleFeed2,

    image: CattleFeed2,
  },
};

const preloadImage = (src) => {
  const img = new Image();
  img.src = src;
};

function BlogPage() {
  const [selectedCategory, setSelectedCategory] = useState(
    "Frozen Fruits and Vegetables"
  );

  const currentCategoryData = blogData[selectedCategory] || [];
  const categoryImage = categoryImages[selectedCategory.trim()];
  const remainingPosts = currentCategoryData.slice(4); // Posts beyond the first four

  useEffect(() => {
    // Preload images for the selected category
    if (selectedCategory) {
      const imagesToPreload = blogData[selectedCategory].map((post) => post.image);
      imagesToPreload.forEach(preloadImage);
    }
  }, [selectedCategory]);

  useEffect(() => {
    // Preload category icons
    const categoryIcons = Object.values(categoryImages).map((category) => category.icon);
    categoryIcons.forEach(preloadImage);
  }, []);

  return (
    <div>
      <div className="h-[104px]"></div>
      <div className="md:text-[38px] text-[32px] md:leading-[55px] mt-10 leading-10 text-center">
        Sustainability Meets Purity <br className="md:block hidden" /> The Core
        of King Life LLC’s Vision.
      </div>

      {/* Responsive categories list */}
      <div className="md:hidden block mt-[72px] px-4">
        <div className="flex items-center justify-center text-[24px] font-normal">
          Categories <img className="ml-6 w-[180px]" src={Line} alt="" />
        </div>
        <ul className="flex overflow-x-auto whitespace-nowrap mt-8 gap-6">
          {Object.keys(blogData).map((category) => (
            <li
              key={category}
              className={`flex items-center border-b pb-[14px] cursor-pointer mr-6 ${
                selectedCategory === category ? "font-bold" : ""
              }`}
              onClick={() => setSelectedCategory(category)}
            >
              <div className="flex items-center gap-2">
                <img
                  className="w-14 h-14 rounded-full object-cover mb-2"
                  src={categoryImages[category.trim()]?.icon}
                  alt={`${category} icon`}
                />
                <div className="mr-6">
                  <h1
                    className="text-[16px] font-normal"
                    style={{
                      color:
                        selectedCategory === category ? "#067BA4" : "inherit",
                    }}
                  >
                    {category}
                  </h1>
                  <p
                    className="text-[14px] font-normal"
                    style={{
                      color:
                        selectedCategory === category ? "#5CB4D4" : "#808080",
                    }}
                  >
                    {blogData[category].length} Posts
                  </p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>

      {/* Main content */}
      <div className="columns-2 flex items-start justify-start gap-14 md:mt-[56px] mt-10 md:px-20 px-4">
        <div className="space-y-4">
          <div className="md:flex gap-[18px] md:space-y-0 space-y-4">
            {currentCategoryData.slice(0, 2).map((post, index) => (
              <div
                key={index}
                className={`relative ${
                  index === 0 ? "md:w-[486px]" : "md:w-[341px]"
                } md:h-[358px]`}
              >
                <img
                  className="w-full h-full object-cover rounded-xl"
                  src={post.image}
                  alt={post.title}
                />
                <div className="absolute rounded-xl bottom-0 w-full bg-gradient-to-t from-black to-transparent p-4 text-white">
                  <Link key={post.id} to={`/BlogPage/blogdetails/${post.id}`}>
                    <h3 className="text-[16px] font-normal">{post.title}</h3>
                  </Link>
                </div>
              </div>
            ))}
          </div>

          <div className="md:flex gap-[18px] md:space-y-0 space-y-4">
            {currentCategoryData.slice(2, 4).map((post, index) => (

             
              
              <div
                key={index}
                className={`relative ${index === 0 ? "md:w-[341px]" : "md:w-[486px]"} md:h-[358px]`}
              >
                <img
                  className="w-full h-full object-cover rounded-xl"
                  src={post.image}
                  alt={post.title}
                />
                <div className="absolute rounded-xl bottom-0 w-full bg-gradient-to-t from-black to-transparent p-4 text-white">
                <Link key={post.id} to={`/BlogPage/blogdetails/${post.id}`}>
                    <h3 className="text-[16px] font-normal">{post.title}</h3>
                    </Link>
                </div>
              </div>
            

            ))}
          </div>
        </div>

        {/* Desktop view: Sidebar categories */}
        <div className="hidden md:block">
          <div className="flex items-center justify-center text-[24px] font-normal">
            Categories <img className="ml-6" src={Line} alt="" />
          </div>
          <ul className="md:ml-8 md:mt-8 space-y-4">
            {Object.keys(blogData).map((category) => (
              <li
                key={category}
                className={`flex items-center border-b pb-[14px] cursor-pointer ${
                  selectedCategory === category ? "font-bold" : ""
                }`}
                onClick={() => setSelectedCategory(category)}
              >
                <div className="flex items-center gap-2">
                  <img
                    className="w-14 h-14 rounded-full object-cover mb-2"
                    src={
                      categoryImages[category.trim()]?.icon ||
                      "path/to/fallback-image.jpg"
                    }
                    alt={`${category} icon`}
                  />
                  <div>
                    <h1
                      className="text-[16px] font-medium"
                      style={{
                        color:
                          selectedCategory === category ? "#067BA4" : "inherit",
                      }}
                    >
                      {category}
                    </h1>
                    <p
                      className="text-[14px] font-normal text-[#808080]"
                      style={{
                        color:
                          selectedCategory === category ? "#5CB4D4" : "#808080",
                      }}
                    >
                      {blogData[category].length} Posts
                    </p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {/* Remaining posts */}
      {remainingPosts.length > 0 && (
        <div className="postContainer md:columns-3 md:px-20 px-4 mt-[72px] gap-6 md:space-y-14 space-y-4">
          {remainingPosts.map((post) => (
            <Link key={post.id} to={`/BlogPage/blogdetails/${post.id}`}>
              <div className="flex items-start gap-4 space-y-4 mt-4 sm:mt-0">
                <img
                  className="w-[110px] h-[110px] object-cover rounded-md"
                  src={post.image}
                  alt={post.title}
                />
                <div>
                  <button className="border rounded-full px-4 py-[3px] text-[14px] font-medium">
                    Latest
                  </button>
                  <p className="text-[16px] font-light md:mt-4">{post.title}</p>
                </div>
              </div>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
}

export default BlogPage;
