import React, { useEffect, useState } from "react";
import arrowUp from "../../../Assets/Contact/arrowUp.svg";
import arrowDown from "../../../Assets/Contact/arrowDown.svg";
import callIcon from "../../../Assets/Contact/callIcon.svg";
import mailIcon from "../../../Assets/Contact/mailIcon.svg";
import downloadIcon from "../../../Assets/Footer/downloadIcon.svg";
import twitterIcon from "../../../Assets/Footer/twitter.svg";
import fbIcon from "../../../Assets/Footer/fb.svg";
import igIcon from "../../../Assets/Footer/ig.svg";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";

function ContactPage() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    service: "",
    message: "",
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isButtonActive, setIsButtonActive] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [emailTouched, setEmailTouched] = useState(false);

  useEffect(() => {
    // Check if all fields have values and if the email is valid
    const allFieldsFilled = Object.values(formData).every(
      (field) => field.trim() !== ""
    );
    const emailIsValid = validateEmail(formData.email);
    setIsButtonActive(allFieldsFilled && emailIsValid);
  }, [formData]);

  const validateEmail = (email) => {
    // Basic email regex pattern
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      setEmailError("Please enter a valid email address");
      return false;
    }
    setEmailError("");
    return true;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));

    if (name === "email") {
      if (!emailTouched) setEmailTouched(true); // Mark email as touched when typing starts
      validateEmail(value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isButtonActive) {
      setIsSubmitted(true);
      // Here, you can add logic to handle the actual form submission, such as an API call.
    }
  };

  return (
    <div>
      <div className="relative w-full">
        {/* Background with overlay */}
        <div className="bg-cover bg-center sm:bg-contactBg bg-contactMob w-full h-[1169px] sm:h-[869px]">
          {/* Contact Section */}
          <div className="relative z-10 flex flex-col sm:flex-row items-center sm:justify-between p-4 sm:pl-[80px] sm:pr-[147px]">
            {/* Contact Information */}
            <div className="text-white mt-[138px] sm:mt-0">
              <motion.h1
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
                className="sm:text-[48px] text-[32px] leading-[32px] sm:leading-[48px] font-medium"
              >
                Get in Touch
              </motion.h1>
              <motion.p
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1, delay: 0.2 }}
                className="text-start text-[16px] font-light leading-[28px] mt-[16px]"
              >
                Have some big idea or brand to develop and need help? Then reach
                out; <br /> we’d love to hear about your project and provide
                help.
              </motion.p>
              <div className="sm:flex sm:mt-[32px] mt-[24px]">
                <div className="flex items-center">
                  <img
                    src={callIcon}
                    alt="Call Icon"
                    className="w-8 h-8 mr-2"
                  />
                  <span>+97143445487 </span>
                </div>
                <div className="flex items-center sm:ml-[40px] mt-[24px] sm:mt-0">
                  <img
                    src={mailIcon}
                    alt="Mail Icon"
                    className="w-8 h-8 mr-2"
                  />
                  <span>info@kinglifetrading.com</span>
                </div>
              </div>

              {/* Social Media Links - Desktop View */}
              <div className="sm:mt-[48px] hidden sm:block">
                <div
                  className="text-[16px] font-light mt-[16px] hover:underline cursor-pointer"
                  onClick={() => window.open("https://linkedin.com", "_blank")}
                >
                  LinkedIn
                </div>
                <div
                  className="text-[16px] font-light mt-[16px] hover:underline cursor-pointer"
                  onClick={() =>
                    window.open(
                      "https://www.facebook.com/profile.php?id=61569830904984",
                      "_blank"
                    )
                  }
                >
                  Facebook
                </div>
                <div
                  className="text-[16px] font-light mt-[16px] hover:underline cursor-pointer"
                  onClick={() =>
                    window.open(
                      "https://www.instagram.com/kinglife_llc/",
                      "_blank"
                    )
                  }
                >
                  Instagram
                </div>
              </div>
            </div>

            {/* Contact Form */}
            <div className="w-full sm:w-[556px] mx-auto sm:mx-0 sm:ml-[92px] sm:mt-[168px] mt-[40px] bg-white rounded-lg sm:p-[56px] p-[16px]">
              <form className="space-y-4" onSubmit={handleSubmit}>
                <div>
                  <label className="block text-sm font-light">Name</label>
                  <input
                    type="text"
                    name="name"
                    className="w-full text-gray-600 text-sm font-light bg-gray-100 h-[48px] px-4 py-2 focus:outline-none rounded-lg"
                    placeholder="Your Name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label className="block text-sm font-light">Email</label>
                  <input
                    type="email"
                    name="email"
                    className="w-full text-gray-600 text-sm font-light bg-gray-100 h-[48px] px-4 py-2 focus:outline-none rounded-lg"
                    placeholder="Your Email"
                    value={formData.email}
                    onChange={handleChange}
                    onBlur={() => setEmailTouched(true)}
                  />
                  {emailTouched && emailError && (
                    <p className="text-red-500 font-light text-xs mt-1">
                      {emailError}
                    </p>
                  )}
                </div>
                <div>
                  <label className="block text-sm font-light">
                    Select Service
                  </label>
                  <div className="relative">
                    <select
                      name="service"
                      className="w-full text-gray-600 text-sm font-light bg-gray-100 h-[48px] px-4 py-2 focus:outline-none rounded-lg appearance-none"
                      value={formData.service}
                      onChange={handleChange}
                      onClick={() => setIsOpen(!isOpen)}
                      onBlur={() => setIsOpen(false)}
                    >
                      <option value="">Select Service</option>
                      <option value="Exporting">Exporting</option>
                      <option value="Private labeling">Private labeling</option>
                      <option value="Purchase">Purchase</option>
                    </select>
                    <div className="absolute right-4 top-1/2 transform -translate-y-1/2 pointer-events-none">
                      <img
                        src={isOpen ? arrowUp : arrowDown}
                        alt="Dropdown Arrow"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <label className="block text-sm font-light">Message</label>
                  <textarea
                    name="message"
                    className="w-full text-gray-600 text-sm font-light bg-gray-100 h-[104px] resize-none px-4 py-2 focus:outline-none rounded-lg"
                    placeholder="Your Message"
                    rows="4"
                    value={formData.message}
                    onChange={handleChange}
                  ></textarea>
                </div>
                <button
                  type="submit"
                  className={`w-full py-3 mt-6 sm:h-[56px] h-[48px] rounded-full ${
                    isButtonActive
                      ? "bg-[#28C4F0] hover:bg-[#31C0F2] text-white"
                      : "bg-[#28C4F0] text-white opacity-50 cursor-not-allowed"
                  }`}
                  disabled={!isButtonActive}
                >
                  {isSubmitted ? "Submitted" : "Submit"}
                </button>
              </form>
            </div>
          </div>

          {/* Social Media Links - Mobile View */}
          <div className="text-white mt-[40px] text-start px-6 sm:hidden">
            <div
              className="text-[16px] font-light mt-[16px] hover:underline cursor-pointer"
              onClick={() => window.open("https://linkedin.com", "_blank")}
            >
              LinkedIn
            </div>
            <div
              className="text-[16px] font-light mt-[16px] hover:underline cursor-pointer"
              onClick={() =>
                window.open(
                  "https://www.facebook.com/profile.php?id=61569830904984",
                  "_blank"
                )
              }
            >
              Facebook
            </div>
            <div
              className="text-[16px] font-light mt-[16px] hover:underline cursor-pointer"
              onClick={() =>
                window.open("https://www.instagram.com/kinglife_llc/", "_blank")
              }
            >
              Instagram
            </div>
          </div>
        </div>
      </div>

      {/* footer */}

      <div className="bg-[#FAFAFB]   sm:h-[574px] h-[840px]">
        <footer className=" sm:w-full w-full  px-4 pt-8  sm:px-[80px] sm:pt-[56px]">
          <div className="">
            {/* Header Section */}

            <div className="sm:flex  sm:flex-row justify-between items-center ">
              <h2 className="text-[#18181B] sm:text-[32px] text-[24px] font-normal leading-[42px] text-left ">
                Sustainable Ingredients,
                <br /> Global Impact
              </h2>
              {/* <div className="">
                <button className="bg-[#31C0F2] hover:bg-[#23ABDA] h-[48px] w-[232px]  sm:w-[193px] mt-6 text-sm font-normal flex justify-center items-center   text-white px-4 py-2 rounded-md">
                  <img src={downloadIcon} className="mr-2" alt="" /> Download
                  Brochure
                </button>
              </div> */}
            </div>
            <div className="border hidden sm:block border-[#D7D7D7] w-full  items-center sm:mt-[56px]"></div>

            {/* Footer Content */}
            <div className="flex flex-col mt-[40px] sm:mt-[56px] sm:flex-row justify-between text-left ">
              {/* About Section */}
              <div className="sm:w-1/3">
                <h3 className="text-[#18181B] text-base font-medium text-left">
                  About King Life
                </h3>
                <p className="text-[#52525B] text-sm font-normal text-justify sm:leading-[22px] mt-[24px] sm:mt-6">
                  King Life LLC excels in premium natural extracts from spices,
                  herbs, and botanicals, offering pure oils, oleoresins, and
                  extracts to elevate food, cosmetics, and wellness products
                  globally.
                </p>
                <div className=" sm:flex  hidden   sm:mt-[40px] sm:justify-start space-x-[34px]">
                  <i className=" cursor-pointer">
                    {" "}
                    <img src={twitterIcon} alt="" />
                  </i>
                  <i className="cursor-pointer">
                    <a
                      href="https://www.facebook.com/profile.php?id=61569830904984"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={fbIcon} alt="Facebook" />
                    </a>
                  </i>
                  <i className="cursor-pointer">
                    <a
                      href="https://www.instagram.com/kinglife_llc/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={igIcon} alt="Instagram" />
                    </a>
                  </i>
                </div>
              </div>

              <div className="flex sm:mt-0 mt-[40px]">
                {/* Company Links */}
                <div className="">
                  <h3 className="text-[#18181B] text-base font-medium text-left">
                    Company
                  </h3>
                  <ul className=" sm:mt-6 mt-6 space-y-2  ">
                    <li className="text-[#52525B] cursor-pointer hover:underline  sm:text-sm font-normal">
                      {" "}
                      <NavLink to="/about"> About </NavLink>
                    </li>
                    <li className="text-[#52525B] cursor-pointer hover:underline sm:text-sm font-normal">
                      {" "}
                      <NavLink to="/services"> Services </NavLink>
                    </li>
                    <li className="text-[#52525B] cursor-pointer hover:underline sm:text-sm font-normal">
                      {" "}
                      <NavLink to="/productPage"> Products </NavLink>
                    </li>
                    <li className="text-[#52525B] cursor-pointer hover:underline sm:text-sm font-normal">
                      {" "}
                      <NavLink to="/blogPage"> Blogs </NavLink>
                    </li>
                    <li className="text-[#52525B] cursor-pointer hover:underline sm:text-sm font-normal">
                      {" "}
                      <NavLink to="/contact"> Contact </NavLink>
                    </li>
                  </ul>
                </div>

                {/* Help Links */}
                <div className="ml-[119px] sm:ml-[256px]">
                  <h3 className="text-[#18181B] text-base font-medium text-left">
                    Help
                  </h3>
                  <ul className=" sm:mt-6 mt-6 space-y-2">
                    <li className="text-[#52525B] cursor-pointer hover:underline sm:text-sm font-normal">
                      <NavLink to="/terms-and-conditions">
                        {" "}
                        Terms and Conditions
                      </NavLink>
                    </li>
                    <li className="text-[#52525B] cursor-pointer hover:underline sm:text-sm font-normal">
                      <NavLink to="/privacy-policy"> Privacy Policy</NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="flex sm:hidden justify-center mt-[44px]  space-x-4">
              <i className=" cursor-pointer">
                {" "}
                <img src={twitterIcon} alt="" />
              </i>
              <i className="cursor-pointer ml-[35px]">
                <a
                  href="https://www.facebook.com/profile.php?id=61569830904984"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={fbIcon} alt="Facebook" />
                </a>
              </i>
              <i className="cursor-pointer ml-[35px]">
                <a
                  href="https://www.instagram.com/kinglife_llc/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={igIcon} alt="Instagram" />
                </a>
              </i>
            </div>

            {/* Copyright */}
            <div className="text-center text-[#3C3C3C] text-base font-normal mt-[80px] sm:mt-[48px]">
              © {new Date().getFullYear()} All rights reserved.
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default ContactPage;
