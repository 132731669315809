import React from 'react'
import AboutBanner from '../../Component/AboutPageComponent/aboutBanner/AboutBanner'
import AboutContent from '../../Component/AboutPageComponent/aboutContent/AboutContent'
import AboutOurstory from '../../Component/AboutPageComponent/aboutOurmission/AboutOurstory'
import AboutOurteam from '../../Component/AboutPageComponent/aboutOurteam/AboutOurteam'

function AboutUsDetail() {
  return (
    <div>
    <AboutBanner/>
    <AboutContent/>
    <AboutOurstory/>
    <AboutOurteam/>
    </div>
  )
}

export default AboutUsDetail