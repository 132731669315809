import React from "react";

function LocationMap() {
  return (
    <div className="Location sm:px-[80px]">
      <div className="map_container sm:mt-[120px] mt-[72px] flex flex-col lg:flex-row justify-center">
        <div className="w-full">
          <div className="text-center font-normal text-[32px] sm:text-[40px] text-[#070C3D]">
            Locate Us
          </div>
          <div className="sm:mt-8 mt-6 relative">
            {/* Google Map */}
            <iframe
              title="map"
              width="100%"
              className="sm:h-[577px] sm:mx-auto px-4 sm:px-0 sm:w-[898px] h-[269px] "
              style={{ border: 1 }}
              frameBorder="0"
              scrolling="no"
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3607.7348681834847!2d55.34192657538442!3d25.279502877658082!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjXCsDE2JzQ2LjIiTiA1NcKwMjAnNDAuMiJF!5e0!3m2!1sen!2sin!4v1731659688613!5m2!1sen!2sin"
            />
            {/* Address Overlay */}
            <div className="absolute sm:block hidden bottom-0 left-0 sm:bottom-5 sm:left-[260px] text-start text-base leading-6 text-black font-medium bg-white bg-opacity-90 p-2 rounded-md">
              Office no 308 Al Nayieli Building Near Abuhail Center <br />
              Hor Al Anz East, Deira, Dubai, UAE
              <br /> Tel +97143445487
            </div>
            <div className="absolute  sm:hidden bottom-2 left-6 text-start text-base leading-6 text-black font-medium bg-white bg-opacity-90 p-2 rounded-md">
              Office no 308 Al Nayieli Building Near <br /> Abuhail Center <br />
              Hor Al Anz East, Deira, Dubai, UAE
              <br /> Tel +97143445487
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LocationMap;
