import React from 'react'
import Slider from 'react-slick';
import FrozenFoodAndVegitable from "../../../Assets/ProductPage/Frozon Food 1.jpg";
import img3 from "../../../Assets/BlogPage/frozenfood/Frozon Food 4.jpg";
import img4 from "../../../Assets/BlogPage/frozenfood/1733120919036-58233254965280377784101365659726374516.png";
import img5 from "../../../Assets/BlogPage/frozenfood/1733120919052-273944072799003953829675126857030315118.png";
import './BlogDetails.css'
import NeedMoreHelp from '../../Component/NeedMoreHelp/NeedMoreHelp';
import { useParams } from 'react-router-dom';
import blogs from "../../../BlogJson/Blog.json"

const blogs2 = [
    {
        name: "Millane L",
        company: "SVN Exports an Imports Pvt Ltd",
        text: "King Life LLC excels in premium natural extracts from spices, herbs, and botanicals, offering pure oils, oleoresins, and extracts to elevate food.",
        image: img3,
    },
    {
        name: "Abraham",
        company: "Marco Logistics",
        text: "King Life LLC excels in premium natural extracts from spices, herbs, and botanicals, offering pure oils, oleoresins, and extracts to elevate food.",
        image: img4,
    },
    {
        name: "Michel S",
        company: "SVN Exports an Imports Pvt Ltd",
        text: "King Life LLC excels in premium natural extracts from spices, herbs, and botanicals, offering pure oils, oleoresins, and extracts to elevate food.",
        image: img5,
    },
];

function BlogDetailPage() {

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };
    const { id } = useParams(); 
    const blog = blogs.find((item) => item.id === parseInt(id)); 

    if (!blog) {
        return <div>Blog not found</div>; 
    }

    return (
        <div>
            <div className='sm:px-[80px] px-4'>
                <div className='sm:pt-[155px] pt-[120px]'>
                    <img src={FrozenFoodAndVegitable} className='w-full h-[372px] object-cover hidden sm:block'></img>
                    <img src={FrozenFoodAndVegitable} className='sm:hidden'></img>
                </div>
                <div className='mt-[40px]'>
                    <div className='text-[32px] leading-[40px]'>{blog.name}</div>
                    <div className='text-[16px] font-light text-[#4B4B4B] leading-7 mt-8 text-justify'>King Life LLC stands as a premier exporter in the realm of natural products,  specializing in a diverse array of offerings that include herbal extracts, fragrance  oils, spice powders, and oleoresins. Our commitment to quality is unwavering, as  wemeticulously source and process each product to ensure that it meets the  highest standards of purity and efficacy. By leveraging advanced extraction  techniques and adhering to stringent quality control measures, we guarantee that  our products not only fulfill but exceed the expectations of our discerning
                        <br></br>
                        <br></br>
                        King Life LLC stands as a premier exporter in the realm of natural products,  specializing in a diverse array of offerings that include herbal extracts, fragrance  oils, spice powders, and oleoresins. Our commitment to quality is unwavering, as  wemeticulously source and process each product to ensure that it meets the  highest standards of purity and efficacy. By leveraging advanced extraction  techniques and adhering to stringent quality control measures, we guarantee that  our products not only fulfill but exceed the expectations of our discerning  King Life LLC stands as a premier exporter in the realm of natural products,  specializing in a diverse array of offerings that include herbal extracts, fragrance  oils, spice powders, and oleoresins. Our commitment to quality is unwavering, as  wemeticulously source and process each product to ensure that it meets the  highest standards of purity and efficacy. By leveraging advanced extraction  techniques and adhering to stringent quality control measures, we guarantee that  our products not only fulfill but exceed the expectations of our discerning  </div>
                </div>
                <div className='sm:mt-[120px] mt-[72px] '>
                    <div className='text-[20px] font-normal '>More Insights</div>
                    <Slider {...settings} className=''>
                        {blogs2.map((blog, index) => (
                            <div key={index} className='px-4 sm:px-0'>
                                <div className='relative sm:max-w-[411px] max-w-[361px] mx-auto sm:justify-between'>
                                    <img src={blog.image} className='sm:w-[411px] sm:h-[358px] w-[361px] h-[358px] object-cover rounded-xl ' alt="article" />
                                    <div className="absolute rounded-xl bottom-0 w-full bg-gradient-to-t from-black to-transparent p-4 text-white">
                                        <div className="text-[16px] font-normal">{blog.text}</div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
            <NeedMoreHelp />

        </div>
    )
}

export default BlogDetailPage
