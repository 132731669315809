import React from 'react';
import './MarquePanel.css'

function MarquePanel() {
    return (
        <div className="bg-[#F2F2F2] h-[88px] overflow-hidden">
            <div className="marquee pt-[33px] whitespace-nowrap">
                <div className="marquee-content flex gap-[80px] text-[16px] font-light text-[#0081A7]">
                    <div>HERBAL EXTRACTS</div>
                    <div>FRAGRANCE OILS</div>
                    <div>SPICE POWDERS</div>
                    <div>OLEORESINS</div>
                    <div>ASHWAGANDHA POWDERS</div>
                    <div>MORINGA POWDER</div>
                    <div>NATURAL POWDERS</div>
                    {/* Duplicate the items for seamless scrolling */}
                    <div>HERBAL EXTRACTS</div>
                    <div>FRAGRANCE OILS</div>
                    <div>SPICE POWDERS</div>
                    <div>OLEORESINS</div>
                    <div>ASHWAGANDHA POWDERS</div>
                    <div>MORINGA POWDER</div>
                    <div>NATURAL POWDERS</div>
                </div>
            </div>
        </div>
    );
}

export default MarquePanel;
