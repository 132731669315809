import React from 'react'
import Banner from '../../Component/Banner/Banner'
import LatestInsights from '../../Component/LatestInsights/LatestInsights'
import LatestNewsAndEvents from '../../Component/LatestNewsAndEvents/LatestNewsAndEvents'

import AboustUsHome from '../../Component/AboutUSHome/AboustUsHome'
import LocationMap from '../../Component/Map/LocationMap'
import FaqComponent from '../../Component/FAQ/FaqComponent'

import OurPremiumProduct from '../../Component/OurPremiumProduct/OurPremiumProduct'
import OurStoryMission from '../../Component/OurStoryMission/OurStoryMission'
import OurProductionAndExportProcess from '../../Component/OurProductionAndExportProcess/OurProductionAndExportProcess'
import OurClientSay from '../../Component/OurClientSay/OurClientSay'
import CertificationAndAffiliation from '../../Component/CertificationAndAffiliation/CertificationAndAffiliation'
import MarquePanel from '../../Component/MarquePanel/MarquePanel'
import NeedMoreHelp from '../../Component/NeedMoreHelp/NeedMoreHelp'



function HomePage() {
  return (
    <div className=''>
    <Banner/>
    <MarquePanel/>
    <AboustUsHome/>
    <OurPremiumProduct/>
    <OurStoryMission/>
    <CertificationAndAffiliation/>
    <OurProductionAndExportProcess/>
    <OurClientSay/>
    <LatestInsights/>
    <LatestNewsAndEvents/> 
    <FaqComponent/>
    <LocationMap/>
    <NeedMoreHelp/>
    </div>
  )
}

export default HomePage
