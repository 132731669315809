import React from "react";
import { motion } from "framer-motion";
import quality from "../../../Assets/Services/quality.png";
import Export from "../../../Assets/Services/export.png";
import label from "../../../Assets/Services/label.png";

const services = [
  {
    title: "Exporting Services",
    description: [
      "King Life LLC takes pride in providing exceptional exporting services, ensuring the seamless delivery of high-quality natural ingredients to clients across the globe. We prioritize efficiency, safety, and freshness, employing meticulous packaging techniques and adhering to international shipping standards to preserve product integrity throughout the journey. Our experienced team manages every aspect of the exporting process, from regulatory compliance and accurate documentation to real-time tracking and customer support, guaranteeing a hassle-free experience for our clients. With King Life LLC, your products are in trusted hands, arriving on time and in perfect condition.",
      "Our commitment to excellence extends beyond the products we export. By leveraging advanced logistics solutions and maintaining strict quality control, we ensure that our exporting services meet the highest global standards. Partner with King Life LLC for reliable, professional exporting services tailored to your needs.",
    ],
    image: Export,
  },
  {
    title: "Green Spice Extracts",
    description: [
      "At King Life LLC, our Green Spice Extracts embody our commitment to providing sustainable and natural solutions. Sourced from premium herbs and spices, these extracts are produced using eco-friendly techniques such as supercritical CO₂ extraction and steam distillation. These advanced methods eliminate the use of harsh chemicals while preserving the potency, purity, and active compounds of each ingredient. Our green spice extracts are crafted to meet the needs of industries such as food, beverages, cosmetics, and nutraceuticals, delivering clean, high-quality ingredients that enhance flavor, aroma, and nutritional value.",
      "Our Green Spice Extracts prioritize both quality and environmental responsibility. Free from synthetic additives, they retain the authentic essence of each spice while being produced in an eco-conscious manner. With versatile applications and a focus on sustainability, King Life LLC ensures your ingredients meet the highest standards of excellence and care.",
    ],
    image: label,
  },
  {
    title: "Quality Assurance",
    description: [
      "Quality is the cornerstone of King Life LLC’s operations, driving our commitment to excellence in every product we offer. Our dedicated Quality Assurance team conducts rigorous testing on each batch, ensuring it meets the highest standards for purity, safety, and efficacy. By implementing advanced testing protocols and adhering to stringent international quality regulations, we guarantee consistent and reliable products that exceed industry expectations. From raw material sourcing to the final product, our meticulous approach ensures that every ingredient we deliver is free from contaminants and crafted to provide maximum value and trust.",
      "At King Life LLC, we go beyond compliance to deliver unmatched quality assurance. Our continuous monitoring, testing, and improvements ensure that you receive only the finest natural ingredients for your business. Partner with us for products that you can rely on with confidence.",
    ],
    image: quality,
  },
];

function ServicePage() {
  return (
    <div>
      <div className="relative w-full">
        {/* Background with overlay */}
        <div
        
          className="bg-cover bg-center sm:bg-ServicesBanner bg-ServicesMob sm:rounded-b-[16px] w-full h-[501px] sm:h-[606px]"
        >
          {/* Gradient Overlay */}
          <div className="absolute inset-0 bg-gradient-to-b from-[#FFFFFF00] via-[#00000036] to-[#00000090] rounded-b-[16px]"></div>
        </div>

        {/* Text content */}
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1.2, ease: "easeIn" }}
          className="absolute sm:top-[392px] top-[301px] sm:left-[80px] left-[16px]"
        >
          <h1 className="text-white font-normal text-[32px] sm:text-[40px] sm:leading-[56px] leading-[56px]">
            Our Production and{" "}
          </h1>
          <h2 className="text-white font-light text-[32px] sm:text-[40px] sm:leading-[40px] leading-[40px]">
            Export Process
          </h2>
          <p className="text-[#CCCCCC] text-[16px] font-normal sm:mt-[16px] mt-[16px] text-left ">
            From sourcing to export, we guarantee purity and consistency in
            every step.
          </p>
        </motion.div>
      </div>

      <div className="sm:px-[80px] px-[16px] sm:mt-[120px] mt-[40px]">
        <div className="sm:space-y-[120px] space-y-[72px]">
          {services.map((service, index) => (
            <div
              key={index}
             
              className={`flex flex-col gap-6 items-center sm:items-start ${
                index % 2 === 0 ? "sm:flex-row" : "sm:flex-row-reverse"
              }`}
            >
              {/* Title */}
              <h2 className="text-[32px] leading-[48px] font-medium sm:text-[40px] sm:hidden">
                {service.title}
              </h2>

              {/* Image */}
              <div className="sm:w-1/3 group overflow-hidden rounded-xl">
                <img
                 
                  src={service.image}
                  alt={service.title}
                  loading="lazy"  // Lazy loading added here
                  className="sm:rounded-[16px] transition-transform duration-300 transform group-hover:scale-110 sm:w-[519px] sm:h-[337px] w-[361px] h-[221px]"
                />
              </div>

              {/* Text Content */}
              <div className="sm:w-2/3">
                {/* Title (visible only on larger screens) */}
                <h2 className="hidden sm:block sm:text-[40px] font-medium text-[32px] leading-[48px]">
                  {service.title}
                </h2>

                {/* Description */}
                {service.description.map((para, idx) => (
                  <p
                    key={idx}
                  
                    className={`text-[#4B4B4B] text-[16px] font-light leading-6 text-justify sm:mt-[32px] ${
                      idx === 1 ? "sm:mt-[24px]" : ""
                    }`}
                  >
                    {para}
                  </p>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ServicePage;
