import React, { useState } from 'react'
import dummyImg from '../../../Assets/AdminComponents/Home/Frame 1000001917.png'
import newsImg from '../../../Assets/AdminComponents/Home/Rectangle 44 (1).png'

const latestEvents = [
    {
        name: "Kinglife LLC Unveils New Essential Oil Collection",
        text: "King Life LLC excels in premium natural extracts from spices, herbs, and botanicals, offering pure oils, oleoresins, and extracts to elevate food, cosmetics, and wellness products globally.",
        date: "March 24, 2024",
        isPublished: true
    },
    {
        name: "Expanding Our Natural Flavor Portfolio",
        text: "Kinglife LLC introduces new botanical flavors, enhancing food and drinks with authentic taste, vibrant color, and natural purity.",
        date: "March 24, 2024",
        isPublished: false
    },
    {
        name: "Sustainability Initiatives: Reducing Our Carbon Footprint",
        text: "At Kinglife LLC, we are committed to sustainable practices. Recently, we introduced supercritical CO₂ extraction to minimize waste and reduce our carbon footprint. This eco-friendly process ensures that our products meet the highest standards of purity without compromising the environment.",
        date: "March 24, 2024",
        isPublished: true
    }
]

function AdminHomePage() {

    const [bannerImage, setBannerImage] = useState(null);
    const [preview, setPreview] = useState(dummyImg);
    const [showModal, setShowModal] = useState(false);
    const [title, setTitle] = useState('')
    const [caption, setCaption] = useState('')
    const [description, setDescription] = useState('')
    const [newsImage, setNewsImage] = useState(null)
    const [newsImageName, setNewsImageName] = useState('')
    const [showPreviewModal, setShowPreviewModal] = useState(false);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (!file) {
            setPreview(dummyImg);
            setBannerImage(null);
            return;
        }
        setBannerImage(file);
        const reader = new FileReader();
        reader.onloadend = () => setPreview(reader.result);
        reader.readAsDataURL(file);
    };

    const getTruncatedFileName = (name) => {
        return name.length > 20 ? `${name.slice(0, 20)}...` : name;
    }

    const handleCancelModal = () => {
        setShowModal(false);
        setTitle('');
        setCaption('');
        setDescription('');
        setNewsImage(null);
        setNewsImageName('')
    }

    const handleNewsImageChange = (event) => {
        const file = event.target.files[0];
        setNewsImage(file);
        setNewsImageName(file ? file.name : "");
    }

    const handleCancelPreviewModal = () => {
        setShowPreviewModal(false);
    }

    const isSaveButtonDisabled = !(
        title.trim() &&
        caption.trim() &&
        description.trim() &&
        newsImage
    );

    const isBannerSaveButtonDisabled = !(
        bannerImage
    )

    return (
        <div className='w-full p-8'>
            <div className=' bg-[#FFFFFF] p-6'>
                <div className='text-[#3C3C3C] text-[16px]'>Banner Image</div>
                <div className='flex items-center gap-4 mt-3'>
                    {/* <div>
                        {preview && (
                            <img src={preview} alt='Preview' className='sm:w-[204px] sm:h-[100px] rounded-md' />
                        )}
                    </div> */}
                    <div className='w-full h-auto'>
                        {/* <div className='text-[16px] font-light'>Please upload image</div> */}
                        <div className='flex w-full items-center gap-6'>
                            <div className='flex items-center justify-between bg-[#F8FCFF] w-full h-[60px] px-4'>
                                <div className='flex items-center gap-4'>
                                    <label htmlFor='imageUpload' className='text-[#707FDD] text-[14px] font-normal border-2 border-[#707FDD] px-4 py-1 rounded cursor-pointer'>{preview && bannerImage ? 'Change Banner' : 'Add Banner'}</label>
                                    <input type='file' id='imageUpload' name='image' accept='image/*' className='hidden' onChange={handleImageChange} />
                                    <div className='text-[14px] text-[#5F5F5F] font-light'>{bannerImage ? getTruncatedFileName(bannerImage.name) : ''}</div>
                                </div>
                                <div className='text-[12px] font-normal text-[#909090]'>Please upload image of size 1440 x 825 px</div>
                            </div>
                            <button className={`bg-[#707FDD] px-8 py-4 h-fit w-fit text-[#FFFFFF] rounded-lg  ${bannerImage ? 'cursor-pointer hover:bg-[#4d59a2]' : 'bg-[#707FDD] opacity-50 cursor-not-allowed'}`} onClick={() => setShowPreviewModal(true)} disabled={isBannerSaveButtonDisabled}>Preview</button>
                        </div>
                    </div>

                </div>
            </div>
            <div className='flex justify-between items-center mt-6 bg-[#FFFFFF] px-6 py-3'>
                <div className='text-[16px]'>News and Events</div>
                <div className='text-[16px] text-[#707FDD] font-normal cursor-pointer border-2 border-[#707FDD] px-3 py-1 rounded-md hover:bg-[#707FDD] hover:text-white' onClick={() => setShowModal(true)}>Create New</div>
            </div>

            {latestEvents.map((latestEvent, index) => (
                <div className='flex bg-[#FFFFFF] mt-2 p-4 gap-4'>
                    <img src={newsImg} className='sm:w-[113px] sm:h-[111px]'></img>
                    <div className='w-full'>
                        <div className='flex items-center justify-between'>
                            <div className='text-[20px] font-medium'>{latestEvent.name}</div>
                            <div className='text-[14px] text-[#707FDD] font-light border border-[#707FDD] px-3 py-[2px] rounded-md cursor-pointer hover:text-white hover:bg-[#707FDD]'>{latestEvent.isPublished ? 'Un publish' : 'Publish'}</div>
                        </div>
                        <div className='text-[14px] font-light text-[#7B7B7B] mt-2 leading-6'>{latestEvent.text}</div>
                    </div>
                </div>
            ))}

            {showModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-10 border w-[807px] h-[666px] ">
                        <label className="block text-sm font-light">Add title</label>
                        <input
                            type="text"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            className="w-full py-[13px] px-[17px] bg-[#F7F7F7] focus:outline-none text-[#414141] font-light text-base leading-[22px] rounded-lg mt-2"
                        />
                        <label className="block text-sm font-light mt-6">Add Caption</label>
                        <input
                            type="text"
                            value={caption}
                            onChange={(e) => setCaption(e.target.value)}
                            className="w-full py-[13px] px-[17px] bg-[#F7F7F7] focus:outline-none text-[#414141] font-light text-base leading-[22px] rounded-lg mt-2"
                        />
                        <label className="block text-sm font-light mt-[24px]">Add Description</label>
                        <textarea
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            className="w-full h-[127px] resize-none py-[13px] px-[17px] bg-[#F7F7F7] focus:outline-none text-[#414141] font-light text-base leading-[22px] rounded-lg mt-2"
                            rows="4"
                        />
                        <label className="block text-sm font-light mt-[24px]">Add Image</label>
                        <div className='flex items-center gap-4 mt-2'>
                            <label
                                htmlFor='file-input'
                                className='cursor-pointer text-[#707FDD] border-2 border-[#707FDD] py-2 px-4 rounded text-[14px] font-normal'>
                                Choose File
                            </label>

                            <input
                                id='file-input'
                                type="file"
                                className="hidden   mt-2   text-[#5F5F5F] text-sm font-light"
                                onChange={handleNewsImageChange}
                            />
                            <span className='text-[14px] font-light text-[#5F5F5F]'>{newsImageName || "No file choosen"}</span>
                        </div>
                        <div className="flex justify-end mt-2 gap-4">
                            <button
                                // onClick={()=>setShowModal(false)}
                                onClick={handleCancelModal}
                                className="bg-white h-[48px] w-[97px] font-medium text-base  text-[#707FDD] border border-[#707FDD] px-4 py-2 rounded-lg"
                            >
                                Cancel
                            </button>
                            <button
                                disabled={isSaveButtonDisabled}
                                className={`h-[48px] w-[97px] font-medium text-base px-4 py-2 rounded-lg ${isSaveButtonDisabled ? 'bg-gray-400 cursor-not-allowed text-gray-200' : 'bg-[#707FDD] text-white hover:bg-[#4d59a2]'}`}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {showPreviewModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white border p-[60px]">
                        <div className='bg-[#F6F6F6] h-full w-full'>
                            {preview && (
                                <img src={preview} alt='Preview' className='w-[720px] h-[412px]'></img>
                            )}
                        </div>
                        <div className='flex ml-auto w-fit mt-12 gap-4'>
                            <div className='text-[16px] text-[#707FDD] px-6 py-3 font-medium border border-[#707FDD] rounded-lg cursor-pointer' onClick={handleCancelPreviewModal}>Cancel</div>
                            <div className='text-[16px] text-[#FFFFFF] px-6 py-3 font-medium border border-[#707FDD] bg-[#707FDD] rounded-lg cursor-pointer'>Publish</div>
                        </div>
                    </div>
                </div>
            )}

        </div>
    )
}

export default AdminHomePage
