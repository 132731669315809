import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

function NeedMoreHelp() {

  const navigate = useNavigate();

  const goToContact =()=> {
    navigate('/contact')
  }

  return (
    <div>
      <div className='sm:mt-[120px] mt-[72px] sm:px-20 px-4 '>
        <h1 className='sm:text-[40px] text-[32px] sm:font-normal '>Need more help?</h1>
        <p className='sm:text-[16px] sm:font-light mt-4'>Contact us  for any additional questions or support regarding our products</p>
        <button onClick={goToContact} className='text-[16px] font-normal text-white px-8 py-4 mt-6 rounded-lg bg-[#31C0F2] hover:bg-[#23ABDA]'>Contact Us</button>
      </div>
    </div>
  )
}

export default NeedMoreHelp
