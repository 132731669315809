import React, { useState, useEffect } from 'react';
import '../../Pages/AdminServicePage/service.css';
import deleteIcon from '../../../Assets/Admin/delete.svg';
import Swal from 'sweetalert2';
import closeIcon from '../../../Assets/Admin/close.svg'

function AdminServicePage() {
  const [showModal, setShowModal] = useState(false);
  const [serviceName, setServiceName] = useState('');
  const [serviceDescription, setServiceDescription] = useState('');
  const [image, setImage] = useState(null);
  const [services, setServices] = useState([
    { id: 1, name: 'Exporting Services', description: 'exporting service lorem ipsum', image: null, published: false },
    { id: 2, name: 'Private Labelling', description: 'private labelling hjdbcsnzkx', image: null, published: false },
    { id: 3, name: 'Quality Assurance', description: 'jdknfdsfdsfdsij', image: null, published: false },
  ]);
  const [editServiceId, setEditServiceId] = useState(null);
  const [showPreviewModal, setShowPreviewModal] = useState(false);

  useEffect(() => {
    if (showModal || showPreviewModal) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, [showModal, showPreviewModal]);

  const isFormValid = serviceName.trim() !== '' && serviceDescription.trim() !== '' && image;

  const handleAddOrEditService = () => {
    if (editServiceId !== null) {
      // Edit existing service
      setServices(services.map(service =>
        service.id === editServiceId
          ? { ...service, name: serviceName, description: serviceDescription, image }
          : service
      ));
    } else {
      // Add new service
      setServices([
        ...services,
        {
          id: services.length + 1,
          name: serviceName,
          description: serviceDescription,
          image,
          published: false,
        },
      ]);
    }

    resetModal();
  };

  const handleDeleteService = (id) => {
    setServices(services.filter(service => service.id !== id));
  };

  const handleEditService = (service) => {
    setEditServiceId(service.id);
    setServiceName(service.name);
    setServiceDescription(service.description);
    setImage(service.image || null);
    setShowModal(true);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file.type;
      if (fileType === 'image/jpeg' || fileType === 'image/png' || fileType === 'image/jpg') {
        setImage(file);
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Invalid File Type',
          text: 'Please upload a valid image file (JPEG, JPG, PNG).',
          confirmButtonColor: '#707FDD',
        });
        e.target.value = ''; // Reset the file input
      }
    }
  };

  const handlePublishService = (id) => {
    setServices(services.map(service =>
      service.id === id ? { ...service, published: !service.published } : service
    ));
  };
  

  const resetModal = () => {
    setServiceName('');
    setServiceDescription('');
    setImage(null);
    setEditServiceId(null);
    setShowModal(false);
    setShowPreviewModal(false);
  };
  

  return (
    <div className="mt-[32px] ml-[48px]">
      <div className="flex">
        <div className="text-[20px] font-normal text-[#3C3C3C]">Services</div>
        <div className="justify-end ml-[917px]">
          <button
            onClick={() => {
              resetModal();
              setShowModal(true);
            }}
            className="bg-[#707FDD] text-base font-normal text-center text-white h-[38px] px-6 rounded-lg"
          >
            Create New
          </button>
        </div>
      </div>
      <div className="space-y-2 mt-[32px]">
        {services.map(service => (
          <div
            key={service.id}
            onClick={() => handleEditService(service)}
            className="flex justify-between items-center  py-4 pl-[24px] pr-[38px] bg-white custom-box-shadow border-l-[12px] border-transparent hover:border-[#B7BDE3] transition-all duration-300"
          >
            <span className="text-[#4B5DD3] font-normal text-sm leading-[22px]">
              {service.name}
            </span>
            <div className="flex items-center space-x-4">
            <div
  onClick={(e) => {
    e.stopPropagation();
    handlePublishService(service.id);
  }}
  className={`text-[14px] font-light border px-4 py-2 rounded-md cursor-pointer transition-all duration-200 ${
    service.published ? 'text-white bg-[#707FDD]' : 'text-[#4B5DD3] border-[#707FDD]'
  }`}
>
  {service.published ? 'Unpublish' : 'Publish'}
</div>

{/* 
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteService(service.id);
                }}
                className="text-sm text-[#C30303] items-center flex leading-[20px] font-light"
              >
                Delete
                <img src={deleteIcon} className="ml-[4px]" alt="delete icon" />
              </button> */}
            </div>
          </div>
        ))}
      </div>

      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-10 border w-[807px] relative">
       
            <label className="block text-sm font-light">Add Service Name</label>
            <input
              type="text"
              value={serviceName}
              onChange={(e) => setServiceName(e.target.value)}
              className="w-full py-[13px] px-[17px] bg-[#F7F7F7] focus:outline-none text-[#414141] font-light text-base leading-[22px] rounded-lg mt-2"
            />
            <label className="block text-sm font-light mt-[24px]">Add Description</label>
            <textarea
              value={serviceDescription}
              onChange={(e) => setServiceDescription(e.target.value)}
              className="w-full h-[309px] resize-none py-[13px] px-[17px] bg-[#F7F7F7] focus:outline-none text-[#414141] font-light text-base leading-[22px] rounded-lg mt-2"
              rows="4"
            />
            <label className="block text-sm font-light mt-[24px]">Add Image</label>
            <div className=' flex'>
              <div className="mt-3 flex items-center bg-[#F8FCFF] h-[60px] p-4 w-fit">
                <label className="border-[2px] border-[#707FDD] h-[30px] rounded cursor-pointer flex items-center text-[#707FDD] font-normal px-4 p-2 text-[14px]">
                  Choose file
                  <input
                    type="file"
                    onChange={handleFileChange}
                    className="hidden"
                  />
                </label>
                <span className="ml-2 text-[#5F5F5F] font-light text-sm">
                  {image ? image.name : 'No file chosen'}
                </span>
              </div>
             <div className='item-center'>
                {image && (
                  <button
                    onClick={() => setShowPreviewModal(true)}
                    className="ml-2 mt-8 text-sm font-normal text-[#707FDD] underline hover:font-medium  "
                  >
                    Preview Image
                  </button>
                )}
             </div>
            </div>
            <div className="flex justify-end mt-2">
              <button
                onClick={resetModal}
                className="w-[97px] h-[48px] border border-[#707FDD] text-[#707FDD] rounded-lg text-[16px] font-medium"
              >
                Cancel
              </button>
              <button
                onClick={handleAddOrEditService}
                disabled={!isFormValid}
                className={`h-[48px] w-[97px] ml-4 font-medium text-base px-4 py-2 rounded-lg ${
                  isFormValid
                    ? 'bg-[#707FDD] text-white'
                    : 'bg-[#707FDD] opacity-75 text-white cursor-not-allowed'
                }`}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}

      {showPreviewModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-10 border w-[600px] relative">
            <button
              onClick={() => setShowPreviewModal(false)}
              className="absolute top-4 right-4 "
            >
              <img src={closeIcon} alt="" />
            </button>
            {image && <img src={URL.createObjectURL(image)} alt="Preview" className="w-full h-auto" />}
          </div>
        </div>
      )}
    </div>
  );
}

export default AdminServicePage;
