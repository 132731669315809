import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Home from "../../../Assets/SideBar/HomeIcom.svg";
import ProductsIcon from "../../../Assets/SideBar/ProductsIcon.svg";
import ServicesIcon from "../../../Assets/SideBar/ServicesIcon.svg";
import CareerICon from "../../../Assets/SideBar/CareerIcon.svg";
import BlogsIcon from "../../../Assets/SideBar/BlogsIcon.svg";
import EnquiryIcon from "../../../Assets/SideBar/EnquiryIcon.svg";
import GeneralIcon from "../../../Assets/SideBar/GeneralIcon.svg";

function Sidebar() {
  const location = useLocation();

  const menuItems = [
    { name: 'Home', path: '/admin/home', icon: Home },
    { name: 'Products', path: '/admin/products', icon: ProductsIcon },
    { name: 'Services', path: '/admin/services', icon: ServicesIcon },
    { name: 'Blogs', path: '/admin/blogs', icon: BlogsIcon },
    { name: 'Career', path: '/admin/career', icon: CareerICon },
    { name: 'Enquiry', path: '/admin/enquiry', icon: EnquiryIcon },
    { name: 'General', path: '/admin/general', icon: GeneralIcon },
  ];

  return (
    <div className="flex">
      <div className="bg-[#252840] w-[249px] h-screen fixed">
        <ul className="text-[14px] font-medium space-y-2 tracking-[1px] px-4 pt-6">
          {menuItems.map((item) => (
            <Link to={item.path} key={item.name}>
              <li
                className={`flex items-center py-[18px] pl-6 rounded-lg ${
                  location.pathname === item.path
                    ? 'bg-[#454969] text-[#F3F3F3]'
                    : 'text-[#F3F3F3] '
                }`}
              >
                <img
                  className="w-[22px] h-[22px] mr-4"
                  src={item.icon}
                  alt={item.name}
                />{' '}
                {item.name}
              </li>
            </Link>
          ))}
        </ul>
      </div>
      <div className="ml-[249px] flex-1 h-screen overflow-y-auto"></div>
    </div>
  );
}

export default Sidebar;
