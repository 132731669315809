import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Anwar from "../../../../Assets/People/Anwar.jpg";
import People2 from "../../../../Assets/People/people2.png"
import Sijeena from "../../../../Assets/People/Sijeena.jpg";
import Noufal from "../../../../Assets/People/Noufal.jpg";
import people5 from "../../../../Assets/People/people5.png"

const teamMembers = [
  {
    name: 'Anwar Shahul Hameed',
    position: 'Managing Director',
    img: Anwar,
  },
  {
    name: 'Sijeena Masoodkhan',
    position: 'Director',
    img: Sijeena,
  },
 
  {
    name: 'Noufal Nowshad',
    position: 'Purchasing Manager',
    img: Noufal,
  },
  // {
  //   name: 'Muhammed Affan Anwar',
  //   position: 'Head of Development',
  //   img: people5,
  // },
];

function AboutOurTeam() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,             
    autoplaySpeed: 3000,        
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
  };


  return (
    <div className="sm:px-[80px] px-[16px] sm:mt-[120px] mt-[72px]">
      <div className="">
    <h1 className='text-[#070C3D] sm:text-[40px] text-[32px] text-center font-normal '>Our Team</h1>
      <p className="text-[#2A2E35] text-[16px] font-light sm:w-[720px] mx-auto text-center leading-[28px] sm:mt-[24px] mt-[32px]">
      At King Life LLC, our dedicated team drives innovation and excellence, crafting high-quality natural solutions for our global clients. Together, we ensure sustainability, quality, and trust.       </p>
      </div>
      
      {/* Grid View */}
      <div className="sm:mt-[48px] sm:flex sm:justify-center sm:gap-6   hidden ">
        {teamMembers.map((member, index) => (
          <div key={index} className="group rounded-lg overflow-hidden">
            <img
              src={member.img}
              alt={member.name}
              className="sm:w-[308px] transition-transform  duration-300 transform group-hover:scale-105  sm:h-[318px] mx-auto "
            />
            <div className='bg-white rounded-lg  w-[274px] sm:ml-[16px] text-center py-[8px]  h-[57px] absolute -translate-y-20 '>
              <h3 className="text-[14px] font-normal leading-[18px] ">{member.name}</h3>
              <p className="text-[#5E5E5E] text-[14px] font-normal">{member.position}</p>
            </div>
          </div>
        ))}
      </div>

      {/* Slider View for Small Screens */}
      <div className="sm:hidden block items-center justify-center px-[28px]">
  <Slider {...settings} className="">
    {teamMembers.map((member, index) => ( 
      <div key={index} className="mt-[45px] relative"> {/* Added relative here */}
        <div className="items-center">
          <img src={member.img} alt={member.name} className="w-[308px] h-[318px] mx-auto " />
          <div className="bg-white rounded-lg w-full text-center items-center mx-auto  py-[8px] h-[57px] absolute bottom-[16px] transform translate-y-[0]"> 
            <h3 className="text-[14px] font-normal leading-[18px]">{member.name}</h3> 
            <p className="text-[#5E5E5E] text-[14px] font-normal">{member.position}</p>
          </div>
        </div>
      </div>
    ))}
  </Slider>
</div>

    </div>
  );
}

export default AboutOurTeam;
