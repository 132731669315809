import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Sesbania from "../../../Assets/ProductPage/E7i9nJ5I4p8WvivgMmCy--1--zt0zz.jpg";
import Dawana  from "../../../Assets/ProductPage/Fragrance Oils 3.webp";
import Olesis from "../../../Assets/ProductPage/olesis.png";
import Aswagandha from "../../../Assets/ProductPage/Ashwagandha Powder 4.jpg";
import Spices from "../../../Assets/ProductPage/Spice powder 1.jpg";
import FrozenFoodAndVegitable from "../../../Assets/ProductPage/Frozon Food 1.jpg";
import Perfume from "../../../Assets/ProductPage/Perfume.jpg";
import { Link } from 'react-router-dom';
import './OurPremiumProduct.css';

function OurPremiumProduct() {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [autoHoveredIndex, setAutoHoveredIndex] = useState(2);

  const products = [
    { image:Sesbania, title: "Sesbania Extract", description: "A Natural Powerhouse for Wellness" },
    { image: Dawana, title: "Dawana Oils", description: "A Unique and Exotic Fragrance Experience" },
    { image: Aswagandha, title: "Aswagandha powder", description: "King Life LLC is a trusted exporter of..." },
    { image: Olesis, title: "Oleoresins", description: "Oleoresins are widely utilized in the..." },
    { image: FrozenFoodAndVegitable, title: "Frozen Fruits and Vegetables", description: "Specializing inhigh-quality..." },
    { image: Spices, title: "Spice powder", description: "King Life LLC is a trusted exporter of..." },
    { image: Perfume, title: "Perfume", description: "King Life LLC stands as a premier exporte..." },
  ];

  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (hoveredIndex === null) {
        setAutoHoveredIndex((prevIndex) => (prevIndex + 1) % products.length);
      }
    }, 2000);

    return () => clearInterval(interval);
  }, [hoveredIndex]);

  return (
    <div className='sm:mt-[120px] mt-[72px] sm:px-20 px-4'>
      <div className='sm:text-[40px] text-[32px] font-normal leading-[48px] text-center justify-center'>
        Our Premium Products
      </div>
      <p className='text-[16px] font-light leading-6 text-center justify-between mt-4 max-w-[628px] mx-auto'>
        Our premium products include essential oils, oleoresins, and natural extracts, meticulously crafted to ensure unmatched quality, purity, and versatile applications across industries.
      </p>

      {/* Carousel for mobile view */}
      <div className='sm:hidden px-4 mt-8 sm:mt-0'>
        <Slider {...carouselSettings}>
          {products.map((product, index) => (
            <Link to="productPage" key={index}>
              <div className="relative h-[320px] w-full overflow-hidden">
                <img src={product.image} alt="photo" className="h-full w-full rounded-xl object-cover" />
                <div className="overlay">
                  <h3 className="overlay-title">{product.title}</h3>
                  <p className="overlay-description">{product.description}</p>
                </div>
              </div>
            </Link>
          ))}
        </Slider>
      </div>

      {/* Grid view for larger screens */}
      <Link to="productPage">
        <div className='hidden sm:flex main-container items-center gap-2 w-full justify-between sm:mt-14 mt-9'>
          {products.map((product, index) => {
            const isHovered = hoveredIndex === index;
            const isAutoHovered = autoHoveredIndex === index && hoveredIndex === null;

            return (
              <div
                key={index}
                className={`relative h-[320px] transition-all duration-1000 ease-in-out overflow-hidden product`}
                style={{
                  width: isHovered || isAutoHovered ? '464px' : '140px',
                }}
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
              >
                <img src={product.image} alt="photo" className="h-full w-full rounded-lg object-cover" />
                \
                <div className={`overlay ${isHovered || isAutoHovered ? 'show-overlay' : ''}`}>
                  <h3 className="overlay-title">{product.title}</h3>
                  <p className="overlay-description font-light text-[14px]">{product.description}</p>
                </div>
              </div>
            );
          })}
        </div>
      </Link>
    </div>
  );
}

export default OurPremiumProduct;
