import React, { useContext, useEffect, useRef, useState } from 'react'
import kinglifeLogo from '../../../Assets/AdminComponents/Header/logo-removebg-preview 1.svg'
import notificationIcon from '../../../Assets/AdminComponents/Header/Group 2.svg'
import profileIcon from '../../../Assets/AdminComponents/Header/Frame (5).svg'
import logOutIcon from '../../../Assets/AdminComponents/Header/move_item.svg'
import { adminContext } from '../../AdminLayout'

function Header() {
  const [isDropdown, setIsDropdown] = useState(false);

  const {setToken} = useContext(adminContext)

  const dropdownRef = useRef(null);

  const handleLogout =()=> {
    setToken(null)
    localStorage.removeItem('adminToken');
  }

  const handleProfileClick = () => {
    setIsDropdown(!isDropdown);
  }

  useEffect(() => {
    const handleClickOutside =(event)=> {
      if(dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  },[]);

  return (
    <div className='bg-[#FFFFFF] h-[111px] flex justify-between items-center sm:pl-[72px] pl-2 sm:pr-[44px] pr-2 fixed w-full'>
      <div><img src={kinglifeLogo} className='w-[56px] h-[53px]'></img></div>
      <div className='flex items-center justify-center sm:gap-20 gap-4 relative'>
        <img src={notificationIcon} className='h-8 w-8'></img>
        <div className='' ref={dropdownRef}>
          <img src={profileIcon} className='h-6 w-6 cursor-pointer' onClick={handleProfileClick}></img>
          {isDropdown && (
            <div className='absolute text-[18px] font-normal right-0 mt-14 w-[160px]  py-4 bg-white shadow-lg'>
              <div className='flex items-center justify-center gap-2 cursor-pointer' onClick={handleLogout}>
                <img src={logOutIcon} className='w-6 h-6'></img>
                <div>Log out</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Header
