import React from 'react';

function PrivacyPolicyPage() {
  return (
    <div>
      {/* Spacer to accommodate any fixed header */}
      <div className="h-[104px]"></div>
      
      {/* Main container */}
      <div className="sm:px-[80px] sm:mt-[40px] mt-[60px] px-4">
        {/* Page title */}
        <div className="text-[32px] font-normal text-[#0D0D0D] text-center">
          Privacy Policy
        </div>
        
        {/* Content section */}
        <div className="text-justify text-base font-light leading-[28px] sm:mt-[72px] mt-[32px]">
          {/* Introduction */}
          <p>
            At King Life LLC, we are committed to protecting the privacy and personal information
            of our customers, partners, and visitors. This Privacy Policy explains how we collect,
            use, and safeguard your information when you interact with our website, products, or
            services. By engaging with King Life LLC, you agree to the practices described in this
            policy.
          </p>

          {/* Information We Collect */}
          <section className="sm:mt-[32px] mt-[24px]">
            <h2 className="font-normal text-lg">1. Information We Collect</h2>
            <p className="mt-4">
              <strong>1.1 Personal Information:</strong> We collect personal details such as your
              name, email address, phone number, shipping address, and payment information when you
              place orders or contact us.
            </p>
            <p>
              <strong>1.2 Non-Personal Information:</strong> We gather non-identifiable data,
              including browser type, IP address, and website usage patterns, to improve user
              experience.
            </p>
            <p>
              <strong>1.3 Cookies:</strong> We use cookies and similar tracking technologies to
              enhance website functionality and analyze visitor behavior. You can manage cookie
              preferences through your browser settings.
            </p>
          </section>

          {/* How We Use Your Information */}
          <section className="sm:mt-[32px] mt-[24px]">
            <h2 className="font-normal text-lg">2. How We Use Your Information</h2>
            <p className="mt-4">
              <strong>2.1 Order Fulfillment:</strong> To process orders, deliver products, and
              provide customer support.
            </p>
            <p>
              <strong>2.2 Communication:</strong> To send order confirmations, shipping updates, and
              promotional materials (with your consent).
            </p>
            <p>
              <strong>2.3 Improvement:</strong> To analyze website traffic, improve services, and
              develop new products.
            </p>
            <p>
              <strong>2.4 Legal Compliance:</strong> To comply with legal obligations or respond to
              lawful requests from authorities.
            </p>
          </section>

          {/* How We Share Your Information */}
          <section className="sm:mt-[32px] mt-[24px]">
            <h2 className="font-normal text-lg">3. How We Share Your Information</h2>
            <p className="mt-4">
              <strong>3.1 Third-Party Service Providers:</strong> We may share your information with
              trusted partners who assist with payment processing, shipping, and marketing. These
              providers are contractually obligated to protect your data.
            </p>
            <p>
              <strong>3.2 Legal Requirements:</strong> Your information may be disclosed if required
              by law or to protect the rights, safety, or property of King Life LLC.
            </p>
            <p>
              <strong>3.3 Business Transfers:</strong> In the event of a merger, acquisition, or
              sale of assets, your data may be transferred to the new entity.
            </p>
          </section>

          {/* How We Protect Your Information */}
          <section className="sm:mt-[32px] mt-[24px]">
            <h2 className="font-normal text-lg">4. How We Protect Your Information</h2>
            <p className="mt-4">
              We implement industry-standard security measures, including encryption and secure
              servers, to protect your personal information from unauthorized access, disclosure, or
              misuse. Despite our efforts, no method of data transmission or storage is 100% secure,
              and we cannot guarantee absolute security.
            </p>
          </section>

          {/* Your Rights */}
          <section className="sm:mt-[32px] mt-[24px]">
            <h2 className="font-normal text-lg">5. Your Rights</h2>
            <p className="mt-4">
              <strong>5.1 Access and Updates:</strong> You have the right to access, update, or
              correct your personal information at any time.
            </p>
            <p>
              <strong>5.2 Opt-Out:</strong> You can opt out of receiving promotional communications
              by following the unsubscribe link in our emails.
            </p>
            <p>
              <strong>5.3 Data Deletion:</strong> You may request the deletion of your personal data,
              subject to legal and contractual obligations.
            </p>
          </section>

          {/* Additional Sections */}
          <section className="sm:mt-[32px] mt-[24px]">
            <h2 className="font-normal text-lg">6. Third-Party Links</h2>
            <p className="mt-4">
              Our website may contain links to third-party websites. King Life LLC is not
              responsible for the privacy practices or content of these external sites.
            </p>
          </section>

          <section className="sm:mt-[32px] mt-[24px]">
            <h2 className="font-normal text-lg">7. International Users</h2>
            <p className="mt-4">
              If you are accessing our services from outside the United States, please note that
              your information may be transferred, stored, and processed in the United States, where
              data protection laws may differ from those in your jurisdiction.
            </p>
          </section>

          <section className="sm:mt-[32px] mt-[24px]">
            <h2 className="font-normal text-lg">8. Changes to This Privacy Policy</h2>
            <p className="mt-4">
              King Life LLC reserves the right to update this Privacy Policy to reflect changes in
              our practices or applicable laws. Updates will be posted on our website with the
              revised effective date.
            </p>
          </section>

          <section className="sm:mt-[32px] mt-[24px]">
            <h2 className="font-normal text-lg">9. Contact Us</h2>
            <p className="mt-4">
              If you have questions or concerns about this Privacy Policy or how your information is
              handled, please contact us:
            </p>
            <p className="mt-2">
              Email: <a href="mailto:support@kinglifellc.com" className="text-blue-500">support@kinglifellc.com</a><br />
              Phone: +97143445487<br />
              Address: Office no 308, Al Nayieli Building, Near Abuhail Center, Hor Al Anz East,
              Deira, Dubai, UAE
            </p>
          </section>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicyPage;
