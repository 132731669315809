import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Header from './Sections/Header/Header';
import Footer from './Sections/Footer/Footer';

function UserLayout() {
    const location = useLocation();

    return (
        <div>
            <Header />
            <Outlet />
            {/* Conditionally render Footer only if not on the contact page */}
            {location.pathname !== '/contact' && <Footer />}
        </div>
    );
}

export default UserLayout;
