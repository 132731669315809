import React from 'react'
import news1Img from '../../../Assets/Latest news and events/Rectangle 389.png'
import upArrow from '../../../Assets/arrow_insert.svg'
import news2Img from '../../../Assets/Latest news and events/Rectangle 389 (1).png'
import news3Img from '../../../Assets/Latest news and events/Rectangle 390.png'
import Slider from 'react-slick'
import { Link } from 'react-router-dom'

const latestEvents = [
  {
    name: "Kinglife LLC Unveils New Essential Oil Collection",
    text: "King Life LLC excels in premium natural extracts from spices, herbs, and botanicals, offering pure oils, oleoresins, and extracts to elevate food, cosmetics, and wellness products globally.",
    date: "March 24, 2024"
  },
  {
    name: "Expanding Our Natural Flavor Portfolio",
    text: "Kinglife LLC introduces new botanical flavors, enhancing food and drinks with authentic taste, vibrant color, and natural purity.",
    date: "March 24, 2024"
  },
  {
    name: "Sustainability Initiatives: Reducing Our Carbon Footprint",
    text: "At Kinglife LLC, we are committed to sustainable practices. Recently, we introduced supercritical CO₂ extraction to minimize waste and reduce our carbon footprint. This eco-friendly process ensures that our products meet the highest standards of purity without compromising the environment.",
    date: "March 24, 2024"
  }
]

function LatestNewsAndEvents() {

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768, // Displays two items side-by-side for screens wider than 768px
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  };

  return (
    <div className='mt-[120px] sm:mx-[80px] mx-4'>
      <div className='sm:text-[40px] text-[32px] text-center text-[#070C3D]'>Latest News and Events</div>
      <div className='text-[16px] font-light text-center mt-4 text-[#4B4B4B]'>Stay Updated: Discover the latest happenings, news, and events from King Life LLC.</div>

      <div className=' lg:flex mt-10 justify-between'>

        <div>
          <img src={news1Img} className='w-[737px] h-[228px]'></img>
          <div className='text-[14px] text-[#4B4B4B] sm:mt-5 mt-4'>March 24 2024</div>

          <Link to="newsdetails">
            <div className='group text-[#4B4B4B] hover:text-[#324D9F]'>
              <div className='text-[20px] font-medium  mt-4 group-hover:underline'>Kinglife LLC Unveils New Essential Oil Collection</div>
              <div className='text-[14px] mt-2 sm:max-w-[626px] w-full font-normal'>King Life LLC excels in premium natural extracts from spices, herbs, and botanicals, offering pure oils, oleoresins, and extracts to elevate food, cosmetics, and wellness products globally.</div>
              <div className=' btn flex items-center gap-2 sm:mt-4 mt-8'>
                <Link to="newsdetails"><div className='text-[16px]'>Read more</div></Link>
                <img src={upArrow} className='h-4 w-4 Btnarrow'></img>
              </div>
            </div>
          </Link>

        </div>

        <div className='hidden sm:block w-[517px] h-[400px] bg-[#FCFCFC] rounded-2xl px-6 overflow-y-scroll'
          style={{
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
            overflowY: 'scroll',
            WebkitOverflowScrolling: 'touch',
            WebkitMaskImage: '-webkit-radial-gradient(white, black)'
          }}>
          {latestEvents.map((latestEvent, index) => (
            <div className='flex gap-6 border-b mt-6 border-[#E9E9E9] pb-6'>
              <img src={news2Img} className='w-[167px] h-[152px]'></img>

              <div>
                <div className='text-[14px] text-[#4B4B4B]'>{latestEvent.date}</div>

                <div className='group text-[#4B4B4B] hover:text-[#324D9F]'>
                  <div className=' cursor-pointer'>
                    <div className='text-[20px] font-medium group-hover:underline'>{`${latestEvent.name.slice(0, 20)}...`}</div>
                    <Link to="newsdetails"><div className='text-[14px] font-normal'>{`${latestEvent.text.slice(0, 66)}...`}</div></Link>
                  </div>
                  <div className='flex items-center gap-2 mt-4 btn'>
                    <Link to="newsdetails"><div className='text-[16px] text-[#9C9C9C] group-hover:text-[#324D9F]'>Read more</div></Link>
                    <img src={upArrow} className='h-4 w-4 Btnarrow'></img>
                  </div>
                </div>

              </div>

            </div>
          ))}

        </div>



      </div>

      <div className='block lg:hidden mt-[48px] p-3'>
        <Slider {...settings}>
          {latestEvents.map((latestEvent, index) => (
            <div key={index} className='px-2'> {/* Added padding for spacing */}
              <div className='flex items-center justify-between gap-4'>
                <img src={news2Img} className='w-[127px] h-[144px]' alt="article" />
                <div className=''>
                  <div className='text-[14px] font-normal text-[#4B4B4B]'>{latestEvent.date}</div>
                  <div className='text-[16px] font-medium leading-6 mt-1'>
                    {`${latestEvent.name.slice(0, 18)}...`}
                  </div>
                  <div className='text-[12px] font-normal mt-2 text-[#4B4B4B]'>
                    {`${latestEvent.text.slice(0, 52)}...`}
                  </div>
                  <div className='flex gap-1 mt-4 cursor-pointer'>
                    <Link to="newsdetails"><div className='text-[#9C9C9C] font-normal text-[16px]'>Read more</div></Link>
                    <img src={upArrow} className='h-6 w-6' alt="arrow icon" />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>


    </div>
  )
}

export default LatestNewsAndEvents
