import React from 'react'
import cert1 from "../../../Assets/Certification/cert1.png"
import cert2 from "../../../Assets/Certification/cert2.png"
import cert3 from "../../../Assets/Certification/Group 2066.png"
import cert4 from "../../../Assets/Certification/cert4.png"
import cert5 from "../../../Assets/Certification/cert5.png"

function CertificationAndAffiliation() {
    return (
        <div className='bg-[#FAFAFB] sm:px-[190px] sm:py-14 px-4 py-6 sm:mt-[120px] mt-[72px] '>
            <div className='text-center justify-center '>
                <h1 className='sm:text-[40px] sm:leading-[48px] font-normal text-[32px]  text-[#4D4D4D] '>Certification and Affiliation</h1>
                <p className='text-[#717171] sm:text-[14px] text-[16px] mt-4'>Global Standards and Certifications You Can Trust </p>
            </div>
            <div className='hidden sm:block'>
                <div className='flex   items-center justify-between sm:mt-14'>

                    <div><img className='sm:w-[138px] sm:h-[68.09px] w-[62px] h-[31px]' src={cert1} alt="" /></div>
                    <div><img className='sm:w-[86.68px] sm:h-[87px] w-[46.83px] h-[47px] ' src={cert2} alt="" /></div>
                    <div><img className='sm:w-[176px] sm:h-[32.37px] w-[106.81px] h-[19px] ' src={cert3} alt="" /></div>
                    <div><img className='sm:w-[158px] sm:h-[50.92px] w-[83.35px] h-[27px] ' src={cert4} alt="" /></div>
                    <div><img className='sm:w-[120.3px] sm:h-[82px] w-[70.42px] h-[48px] ' src={cert5} alt="" /></div>

                </div>
            </div>
            <div className='sm:hidden block items-center justify-center'>
                <div className='flex   items-center justify-center gap-8  mt-9'>

                    <div><img className='sm:w-[176px] sm:h-[32.37px] w-[106.81px] h-[19px] ' src={cert3} alt="" /></div>
                    <div><img className='sm:w-[138px] sm:h-[68.09px] w-[62px] h-[31px]' src={cert1} alt="" /></div>
                    <div><img className='sm:w-[158px] sm:h-[50.92px] w-[83.35px] h-[27px] ' src={cert4} alt="" /></div>


                </div>
                <div className='flex items-center  justify-center gap-8  mt-12'>
                    <div><img className='sm :w-[86.68px] sm:h-[87px] w-[46.83px] h-[47px] ' src={cert2} alt="" /></div>
                    <div><img className='sm:w-[120.3px] sm:h-[82px] w-[70.42px] h-[48px] ' src={cert5} alt="" /></div>
                </div>
            </div>


        </div>
    )
}

export default CertificationAndAffiliation
