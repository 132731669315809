import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import searchIcon from "../../../Assets/CareerPage/search.svg";
import ButtonArrow from "../../../Assets/CareerPage/arrow_outward.svg";
import ButtonNext from "../../../Assets/CareerPage/nextButton.svg";
import ButtonPrevious from "../../../Assets/CareerPage/PreviousButton.svg";
import "../../Pages/CareerPage/career.css";

const mockJobs = [
  {
    id: 1,
    title: "Assistant Branch Manager",
    location: "Ajman",
    experience: "2-4 yrs",
    qualification: "Degree",
    description:
      "Branch Head- (Liability products only) Requirement- Experienced professionals from NBFC, Nidhi, Multistate Cooperative Societies, Banks , Insurance companies. Immediate joining Attractive Salary and Incentives with statutory benefits",
  },
  {
    id: 2,
    title: "Branch Head",
    location: "Dubai",
    experience: "2-4 yrs",
    qualification: "Degree",
    description:
      "Branch Head- (Liability products only) Location- Sreekaryam  Requirement- Experienced professionals from NBFC, Nidhi, Multistate Cooperative Societies,  Banks , Insurance companies. Educational qualification- DegreeImmediate joining Attractive Salary and Incentives with statutory benefits",
  },
  {
    id: 3,
    title: "Sales Head",
    location: "Dubai",
    experience: "2-4 yrs",
    qualification: "Degree",
    description:
      "Sales Head- (Liability products only)Location- Sreekaryam Have to handle multiple branches and support the Branch Heads and the branch team for effective performance Requirement- Experienced professionals from NBFC, Nidhi, Multistate Cooperative Societies,  Banks , Insurance companies. Educational qualification- Degree Immediate joining Attractive Salary and Incentives with statutory benefits",
  },
  // Add more jobs...
];

function CareerPage() {
  const [jobs, setJobs] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [jobsPerPage] = useState(5);
  const [expandedJobId, setExpandedJobId] = useState(null); // For managing expanded job descriptions
  const navigate = useNavigate();

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handleApply = (jobId) => {
    // navigate(`/career/${jobId}`);
  };

  const toggleJobDescription = (jobId) => {
    setExpandedJobId(expandedJobId === jobId ? null : jobId);
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    const fetchJobs = async () => {
      // Filter jobs based on the search term
      let filteredJobs = mockJobs;
  
      if (searchTerm.trim()) {
        filteredJobs = filteredJobs.filter((job) =>
          job.title.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }
  
      // Paginate the filtered results
      const indexOfLastJob = currentPage * jobsPerPage;
      const indexOfFirstJob = indexOfLastJob - jobsPerPage;
      const currentJobs = filteredJobs.slice(indexOfFirstJob, indexOfLastJob);
  
      setJobs(currentJobs);
    };
  
    fetchJobs();
  }, [searchTerm, currentPage]);
  

  const word = "Join Our Team"; // The text that will be typed
  const [currentWord, setCurrentWord] = useState(""); // The current text being displayed
  const fadeInSpeed = 500; // Fade-in effect speed
  const typingSpeed = 100; // Typing speed in milliseconds


 
  useEffect(() => {
    let j = 0;
    const type = () => {
      setCurrentWord((prev) => word.substring(0, j + 1));
      j++;

      if (j <= word.length) {
        setTimeout(type, typingSpeed); // Call type again after typingSpeed delay
      }
    };

    type(); // Start the typing effect

  }, []); // Empty dependency array ensures the effect runs only once on page load

  return (
    <div>
      <div className="sm:bg-CareerBanner bg-CareerBannerMob sm:h-[572px] h-[603px] bg-cover bg-center text-white text-center flex items-center justify-center">
        <h1 className="sm:text-[56px] text-[32px] font-extralight">
          <span
            className="typewriter-text"
            style={{
              opacity: currentWord ? 1 : 0,
              transition: `opacity ${fadeInSpeed}ms ease-out`,
            }}
          >
            {currentWord}
          </span>
        </h1>
      </div>
      <div className=" sm:flex sm:px-[185px] px-4 sm:mt-[120px] mt-[72px]">
        <div>
          <h1 className="sm:text-[40px] text-[32px] font-normal w-[264px]">
            Why KingLife ?
          </h1>
        </div>
        <div className="text-[16px] font-light text-justify sm:pl-[62px] sm:mt-0 mt-6 ">
          <p>
            At King Life LLC, we believe our people are the cornerstone of our
            success. By joining us, you’ll become part of a team committed to
            innovation, excellence, and sustainability in natural extracts and
            ingredients.{" "}
          </p>
          <p className="sm:mt-6 mt-4">
            We offer a dynamic work environment where creativity thrives,
            professional growth is prioritized, and collaboration drives
            progress. At King Life, your contributions will directly shape the
            future of natural product innovation. Together, we aim to deliver
            exceptional quality while upholding our values of integrity and
            sustainability. Join us to grow your career and make a meaningful
            impact in a thriving, purpose-driven industry!{" "}
          </p>
        </div>
      </div>
      <div className="container mx-auto px-4 md:px-60 py-8 sm:pt-[152px] pt-[168px]">
        <h1 className="text-5xl  font-normal my-5 text-center">
          Current Openings
        </h1>
        <div className="mb-6 mt-16 flex w-full p-3 border border-[#D7D7D7] rounded-md">
          <img src={searchIcon} alt="searchIcon" />
          <input
            type="text"
            placeholder="Search Job here"
            className="mx-2 bg-transparent  placeholder:text-[#D7D7D7] font-light text-[16px] focus:outline-none"
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
        {jobs.map((job) => (
          <div
            key={job.id}
            className="bg-white custom-box-shadow rounded-lg p-4 mb-4"
          >
            <div className="md:flex justify-between items-center">
              <div className="text-left">
                <h3 className="text-[16px] font-normal">{job.title}</h3>
                <p className="text-[14px] text-[#2A2E35] font-normal">
                  {job.location}
                </p>
                <div className="flex space-x-4 mt-2">
                  <span className="px-2 py-1 border text-[12px] font-normal border-[#2A2E35] rounded-lg">
                    {job.experience} Experience
                  </span>
                  <span className="px-2 py-1 border text-[12px] font-normal border-[#2A2E35] rounded-lg">
                    Qualification: {job.qualification}
                  </span>
                </div>
              </div>
              <div className="flex flex-col items-start md:items-end">
                {/* On mobile, View Job Description should appear below the Apply button */}
                {/* <button
                                onClick={() => handleApply(job.id)}
                                className="bg-[#5D8424] text-white px-12 md:px-6 py-4 rounded-full mt-4 md:mt-0"
                            >
                                Apply ↗
                            </button> */}
                <div>
                  <Link to="apply">
                    <button
                      onClick={() => handleApply(job.id)}
                      className="flex sm:ml-[50px] ml-auto bg-[#31C0F2] hover:bg-[#23ABDA] sm:py-4 py-3 mt-3 sm:mt-0 rounded-lg group"
                      style={{ paddingLeft: "48px", paddingRight: "45px" }}
                    >
                      <span className="text-white text-[16px] font-light leading-5">
                        Apply
                      </span>
                      <img
                        className="sm:w-6 sm:h-6 ml-2 transition-transform duration-500 transform group-hover:translate-x-2"
                        src={ButtonArrow}
                        alt="Button Arrow"
                      />
                    </button>
                  </Link>
                </div>
                <div
                  onClick={() => toggleJobDescription(job.id)}
                  className="mt-2 cursor-pointer text-[14px] font-light text-[#31C0F2]"
                >
                  {expandedJobId === job.id
                    ? "Hide Job Description"
                    : "View Job Description"}
                </div>
              </div>
            </div>

            {/* Job description collapsible section */}
            {expandedJobId === job.id && (
              <div className="mt-4 flex justify-start text-left font-light text-sm  bg-[#F7FCFE] p-4 rounded-lg">
                {/* <h4 className="text-lg font-semibold">Job Description</h4> */}
                <p className="mt-2">{job.description}</p>
              </div>
            )}
          </div>
        ))}

        <div className="flex md:justify-end justify-center  space-x-2 mt-8">
          <button
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
            className={`px-3 py-2 ${
              currentPage === 1 ? "text-gray-400 cursor-not-allowed" : ""
            }`}
          >
            <img src={ButtonPrevious} alt="ButtonPrevious" />
          </button>
          {[...Array(Math.ceil(mockJobs.length / jobsPerPage)).keys()].map(
            (number) => (
              <button
                key={number + 1}
                onClick={() => paginate(number + 1)}
                className={`px-4 py-1 rounded-none ${
                  currentPage === number + 1
                    ? "bg-white text-[#304BA0]"
                    : " hover:bg-gray-400"
                }`}
              >
                {number + 1}
              </button>
            )
          )}
          <button
            onClick={() => paginate(currentPage + 1)}
            disabled={currentPage === Math.ceil(mockJobs.length / jobsPerPage)}
            className={`px-3 py-2 ${
              currentPage === Math.ceil(mockJobs.length / jobsPerPage)
                ? "text-gray-400 cursor-not-allowed"
                : ""
            }`}
          >
            <img src={ButtonNext} alt="ArrowRight" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default CareerPage;
