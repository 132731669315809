import React, { useEffect, useState } from "react";
import deleteicon from "../../../Assets/Admin/delete.svg";
import downloadIcon from "../../../Assets/Admin/download.svg";
import arrowLeft from "../../../Assets/Admin/arrowLeft.svg";
import arrowRight from "../../../Assets/Admin/arrowRight.svg";
import "../../Pages/AdminCareerPage/adminCareers.css";

function AdminCareerPage() {
  const [activeTab, setActiveTab] = useState("jobs");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [applications, setApplications] = useState([
    {
      name: "Rahul Raj",
      position: "Assistant Branch Manager",
      date: "2024-11-11",
    },
    {
      name: "Affan Raj",
      position: "Digital Marketing Executive",
      date: "2024-10-10",
    },
    {
      name: "Anwar Raj",
      position: "Digital Marketing Executive",
      date: "2024-10-10",
    },
  ]);
  const [jobListings, setJobListings] = useState([
    {
      title: "Senior Software Engineer",
      experience: "5-7 yrs",
      qualification: "B-tech",
      location: "Ajman",
      description:
        "Responsible for developing high-quality applications and ensuring performance optimization.",
    },
    {
      title: "Project Manager",
      experience: "3-5 yrs",
      qualification: "MBA",
      location: "Ajman",
      description:
        "Manage project timelines, communicate with stakeholders, and lead teams to success.",
    },
  ]);
  const [formData, setFormData] = useState({
    title: "",
    experience: "",
    qualification: "",
    location: "",
    description: "",
  });
  const [filter, setFilter] = useState(""); // New state for filter criteria
  const [currentPage, setCurrentPage] = useState(1);
  const [applicationsPerPage] = useState(10);
  const totalPages = Math.ceil(applications.length / applicationsPerPage);
  const [selectedPosition, setSelectedPosition] = useState("All");
  const positions = [
    "All",
    ...new Set(applications.map((app) => app.position)),
  ];

  // Manage modal overflow behavior
  useEffect(() => {
    if (isModalOpen) document.body.classList.add("overflow-hidden");
    else document.body.classList.remove("overflow-hidden");
    return () => document.body.classList.remove("overflow-hidden");
  }, [isModalOpen]);

  // Handle tab switching
  const handleTabSwitch = (tab) => setActiveTab(tab);

  // Open modal for editing or adding job
  const openModal = (job = null, index = null) => {
    setIsModalOpen(true);
    if (job) {
      setFormData(job);
      setIsEditing(true);
      setEditIndex(index);
    } else {
      setFormData({
        title: "",
        experience: "",
        qualification: "",
        location: "",
        description: "",
      });
      setIsEditing(false);
    }
  };

  // Close modal and reset form
  const closeModal = () => {
    setIsModalOpen(false);
    setIsEditing(false);
    setEditIndex(null);
    setFormData({
      title: "",
      experience: "",
      qualification: "",
      location: "",
      description: "",
    });
  };

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Form validation
  const isFormValid = () => {
    return (
      formData.title &&
      formData.experience &&
      formData.qualification &&
      formData.location &&
      formData.description
    );
  };

  // Submit the job form
  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (isEditing) {
      setJobListings((prevListings) =>
        prevListings.map((job, index) => (index === editIndex ? formData : job))
      );
    } else {
      setJobListings((prevListings) => [...prevListings, formData]);
    }
    closeModal();
  };
  const [sortOrder, setSortOrder] = useState("newest");
  // Sort applications by date
  const sortedApplications = [...applications].sort((a, b) =>
    sortOrder === "newest"
      ? new Date(b.date) - new Date(a.date)
      : new Date(a.date) - new Date(b.date)
  );

  // // Paginate filtered applications
  // const indexOfLastApplication = currentPage * applicationsPerPage;
  // const indexOfFirstApplication = indexOfLastApplication - applicationsPerPage;
  // const currentApplications = filteredApplications.slice(
  //   indexOfFirstApplication,
  //   indexOfLastApplication
  // );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Handle delete job listing
  const handleDelete = (index) => {
    setJobListings((prevListings) =>
      prevListings.filter((_, i) => i !== index)
    );
  };

  // Handle resume download
  const handleDownload = async (app) => {
    try {
      // Assuming the file URL is in the application data (this is an example)
      const response = await fetch(app.resumeUrl, { method: "GET" });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${app.name}_Resume.pdf`;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  // File download handler for applications
  const handleFileDownload = async (appIndex) => {
    const resumeUrl = applications[appIndex].resumeUrl; // Assuming the resumeUrl is stored in the applications array
    if (resumeUrl) {
      try {
        const response = await fetch(resumeUrl, {
          method: "GET",
        });

        if (response.ok) {
          // Create a blob from the response data
          const blob = await response.blob();
          const link = document.createElement("a");
          const url = window.URL.createObjectURL(blob);
          link.href = url;
          link.download = `resume_${appIndex}.pdf`; // Modify the file name as needed
          link.click();
          window.URL.revokeObjectURL(url); // Clean up the URL object
        } else {
          throw new Error("File download failed");
        }
      } catch (error) {
        console.error("Error downloading file:", error);
      }
    }
  };
  const handlePublish = (index) => {
    const updatedJobListings = [...jobListings];
    updatedJobListings[index].published = !updatedJobListings[index].published; // Toggle published state
    setJobListings(updatedJobListings);
  };
  const filteredApplications = sortedApplications.filter((app) => {
    const matchesPosition =
      selectedPosition === "All" || app.position === selectedPosition;
    const matchesFilter =
      app.name.toLowerCase().includes(filter.toLowerCase()) ||
      app.position.toLowerCase().includes(filter.toLowerCase());
    return matchesPosition && matchesFilter;
  });
  // Paginate filtered applications
  const indexOfLastApplication = currentPage * applicationsPerPage;
  const indexOfFirstApplication = indexOfLastApplication - applicationsPerPage;
  const currentApplications = filteredApplications.slice(
    indexOfFirstApplication,
    indexOfLastApplication
  );

  return (
    <div className="px-8 w-full py-4">
      <div className="flex mt-4 bg-white w-[352px] h-[48px] justify-between shadow-md">
        <button
          onClick={() => handleTabSwitch("jobs")}
          className={`py-3 px-10 ${
            activeTab === "jobs"
              ? "border-b-4 w-[176px] border-[#857BA3] text-center"
              : "w-[176px] text-[#080723] text-base font-normal text-center"
          }`}
        >
          Jobs
        </button>
        <button
          onClick={() => handleTabSwitch("applications")}
          className={`py-3 px-10 ${
            activeTab === "applications"
              ? "border-b-4 w-[176px] text-center border-[#857BA3]"
              : "w-[176px] text-[#080723] text-base font-normal text-center"
          }`}
        >
          Applications
        </button>
      </div>

      <div className="mt-6">
        {activeTab === "jobs" ? (
          <div>
            <div className="flex justify-between items-center">
              <h2 className="text-xl font-normal text-[#3C3C3C]">
                Current Openings
              </h2>
              <button
                onClick={() => openModal()}
                className="bg-[#707FDD] text-white h-[38px] text-base font-normal px-6 rounded-lg"
              >
                Create New
              </button>
            </div>
            <div className="mt-6">
              {jobListings.map((job, index) => (
                <div
                  key={index}
                  className="flex justify-between w-full p-6 bg-white custom-shadow rounded-lg mb-2"
                  onClick={() => openModal(job, index)}
                >
                  <div>
                    <h3 className="font-normal text-base">{job.title}</h3>
                    <div className="text-[#2A2E35] text-sm font-normal mt-2">
                      {job.location}
                    </div>
                    <div className="flex mt-2 space-x-2">
                      <div className="h-[37px] border min-w-[150px] border-[#2A2E35] text-xs text-[#2A2E35] font-normal rounded-lg py-2 px-4">
                        {job.experience} Experience
                      </div>
                      <div className="h-[37px] border min-w-[150px] border-[#2A2E35] text-xs text-[#2A2E35] font-normal rounded-lg py-2 px-4">
                        Qualification - {job.qualification}
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center space-x-4">
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        handlePublish(index); // New publish handler
                      }}
                      className={`flex items-center text-[14px] font-light border px-4 py-2 rounded-md cursor-pointer transition-all duration-200 ${
                        job.published
                          ? "text-white bg-[#707FDD]"
                          : "text-[#4B5DD3] border-[#707FDD]"
                      }`}
                    >
                      {job.published ? "Unpublish" : "Publish"}
                    </div>

                    {/* <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDelete(index);
                      }}
                      className="text-[#C30303] font-light flex items-center text-sm"
                    >
                      Delete <img src={deleteicon} className="mr-1" alt="" />
                    </button> */}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="mt-[40px]">
            <div className="flex justify-between items-center">
              <div className="text-base text-[#01CA1F] font-light">
                All Applications
              </div>
              <div className="relative ml-[690px]">
                <select
                  value={selectedPosition}
                  onChange={(e) => setSelectedPosition(e.target.value)}
                  className="py-2 px-4 text-[#707FDD] border border-[#707FDD] focus:outline-none hover:border-[#505BBB] font-normal rounded-md transition duration-200"
                >
                  <option value="All">All Position</option>
                  {positions.map((position, index) => (
                    <option key={index} value={position}>
                      {position}
                    </option>
                  ))}
                </select>
              </div>
              <div className="relative">
                {/* Filter dropdown */}
                <select
                  value={sortOrder}
                  onChange={(e) => setSortOrder(e.target.value)}
                  className="py-2 px-4 text-[#707FDD] border border-[#707FDD] focus:outline-none hover:border-[#505BBB] font-normal rounded-md  transition duration-200"
                >
                  <option value="newest"> Newest</option>
                  <option value="oldest"> Oldest</option>
                </select>
              </div>
            </div>

            <div className="mt-6">
              {currentApplications.map((app, index) => (
                <div
                  key={index}
                  className="flex justify-between items-center w-full mb-2 pl-6 pr-8 h-[68px] rounded-lg bg-white custom-shadow"
                >
                  <div className="flex gap-[165px]">
                    <div className="font-normal w-[100px] text-base">
                      {app.name}
                    </div>
                    <div className="text-[#5D5D5D] w-[200px] text-left font-normal text-base">
                      {app.position}
                    </div>
                    <div className="text-[#6D6D6D] w-[50px] ml-[175px] text-left font-light text-sm item-center">
                      {new Date(app.date).toLocaleDateString()}
                    </div>
                  </div>

                  <button
                    onClick={() => handleDownload(app)}
                    className="text-[#1AA201] text-sm font-normal flex"
                  >
                    Download <img src={downloadIcon} className="ml-2" alt="" />
                  </button>
                </div>
              ))}
            </div>

            {/* Pagination */}
            <div className="flex justify-end align-middle mt-8">
              <button
                onClick={() => paginate(currentPage > 1 ? currentPage - 1 : 1)}
                className={`p-2 ${
                  currentPage === 1 ? "cursor-not-allowed opacity-50" : ""
                }`}
                disabled={currentPage === 1}
              >
                <img src={arrowLeft} alt="Previous" />
              </button>
              {[...Array(totalPages)].map((_, i) => (
                <button
                  key={i}
                  onClick={() => paginate(i + 1)}
                  className={`px-3 py-1 rounded-md text-sm font-normal ${
                    currentPage === i + 1 ? "text-[#C8C8C8]" : "text-[#373737]"
                  }`}
                >
                  {i + 1}
                </button>
              ))}
              <button
                onClick={() =>
                  paginate(
                    currentPage < totalPages ? currentPage + 1 : totalPages
                  )
                }
                className={`p-2 ${
                  currentPage === totalPages
                    ? "cursor-not-allowed opacity-50"
                    : ""
                }`}
                disabled={currentPage === totalPages}
              >
                <img src={arrowRight} alt="Next" />
              </button>
            </div>
          </div>
        )}
      </div>
      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-10 w-[807px] max-h-[90vh] overflow-y-auto">
            {/* Job Creation Form */}
            <form onSubmit={handleFormSubmit}>
              <div>
                <label className="block text-sm font-light">
                  Add Job title
                </label>
                <input
                  type="text"
                  name="title"
                  value={formData.title}
                  onChange={handleInputChange}
                  className="w-full bg-[#F7F7F7] text-[#414141] text-base font-light py-[13px] px-[16px] rounded-lg h-[48px] focus:outline-none mt-2"
                />
              </div>
              <div className="flex gap-x-6 justify-between mt-6">
                <div>
                  <label className="block text-sm font-light">
                    Required Experience
                  </label>
                  <input
                    type="text"
                    name="experience"
                    value={formData.experience}
                    onChange={handleInputChange}
                    className="w-full bg-[#F7F7F7] text-[#414141] text-base font-light py-[13px] px-[16px] rounded-lg h-[48px] focus:outline-none mt-2"
                  />
                </div>
                <div>
                  <label className="block text-sm font-light">
                    Required Qualification
                  </label>
                  <input
                    type="text"
                    name="qualification"
                    value={formData.qualification}
                    onChange={handleInputChange}
                    className="w-full bg-[#F7F7F7] text-[#414141] text-base font-light py-[13px] px-[16px] rounded-lg h-[48px] focus:outline-none mt-2"
                  />
                </div>
                <div>
                  <label className="block text-sm font-light">Location</label>
                  <input
                    type="text"
                    name="location"
                    value={formData.location}
                    onChange={handleInputChange}
                    className="w-full bg-[#F7F7F7] text-[#414141] text-base font-light py-[13px] px-[16px] rounded-lg h-[48px] focus:outline-none mt-2"
                  />
                </div>
              </div>
              <div className="mt-6">
                <label className="block text-sm font-light">
                  Add Job Description
                </label>
                <textarea
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                  className="w-full bg-[#F7F7F7] text-[#414141] text-base font-light py-[13px] px-[16px] rounded-lg h-[226px] resize-none focus:outline-none mt-2"
                ></textarea>
              </div>
              <div className="flex justify-end mt-6">
                <button
                  type="button"
                  onClick={closeModal}
                  className="border border-[#707FDD] text-center text-base font-medium text-[#707FDD] px-6 w-[97px] h-[48px] rounded-lg"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={!isFormValid()}
                  className={`h-[48px] w-[97px] ml-4 font-medium text-base px-4 py-2 rounded-lg ${
                    isFormValid()
                      ? "bg-[#707FDD] text-white"
                      : "bg-[#707FDD] opacity-50 text-white cursor-not-allowed"
                  }`}
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default AdminCareerPage;
