import React from "react";

function TermsAndCondition() {
  return (
    <div>
      <div className="h-[104px]"></div>
      <div className="sm:px-[80px] sm:mt-[40px] mt-[60] px-4">
        <div className="text-[32px] font-normal text-[#0D0D0D] text-center items-center">
          Terms And Conditions
        </div>
        <div className=" text-start text-base font-light leading-[28px] sm:mt-[72px] mt-[32px]">
          <p>
            <strong>Terms and Conditions for King Life LLC</strong>
          </p>
          <p className="sm:mt-[24px] mt-8">
            These Terms and Conditions ("Terms") govern your use of the products
            and services offered by King Life LLC. By engaging with our
            services or purchasing our products, you agree to be bound by these
            Terms. Please read them carefully before proceeding.
          </p>
          <p className="sm:mt-[24px] mt-8">
            <strong>1. General Provisions</strong>
            <br />
            <strong>1.1 Acceptance of Terms:</strong> By accessing or using our
            website, products, or services, you accept these Terms in full.
            <br />
            <strong>1.2 Modifications:</strong> King Life LLC reserves the right
            to modify these Terms at any time. Changes will be effective upon
            posting, and continued use indicates acceptance of revised Terms.
          </p>
          <p className="sm:mt-[24px] mt-8">
            <strong>2. Products and Services</strong>
            <br />
            <strong>2.1 Product Specifications:</strong> We strive to provide
            accurate product descriptions and specifications. However, slight
            variations may occur.
            <br />
            <strong>2.2 Availability:</strong> All products and services are
            subject to availability. King Life LLC reserves the right to limit
            or discontinue items without notice.
          </p>
          <p className="sm:mt-[24px] mt-8">
            <strong>3. Ordering and Payment</strong>
            <br />
            <strong>3.1 Order Confirmation:</strong> Orders are subject to
            acceptance and availability. A confirmation will be sent upon
            processing.
            <br />
            <strong>3.2 Pricing:</strong> Prices are subject to change without
            notice. Taxes, shipping, and additional fees will be disclosed
            during checkout.
            <br />
            <strong>3.3 Payment Terms:</strong> Full payment is required before
            shipment unless otherwise agreed in writing.
          </p>
          <p className="sm:mt-[24px] mt-8">
            <strong>4. Shipping and Delivery</strong>
            <br />
            <strong>4.1 Shipping Policy:</strong> Shipping timelines are
            estimates and may vary. Delays caused by unforeseen circumstances
            are not King Life LLC's liability.
            <br />
            <strong>4.2 Customs and Duties:</strong> Customers are responsible
            for customs clearance and any applicable duties or taxes.
          </p>
          <p className="sm:mt-[24px] mt-8">
            <strong>5. Returns and Refunds</strong>
            <br />
            <strong>5.1 Return Policy:</strong> Returns are accepted for
            defective or damaged items within 14 days of delivery. Proof of
            purchase is required.
            <br />
            <strong>5.2 Refunds:</strong> Refunds are processed after inspection
            of returned items. Shipping costs are non-refundable.
          </p>
          <p className="sm:mt-[24px] mt-8">
            <strong>6. Intellectual Property</strong>
            <br />
            <strong>6.1 Ownership:</strong> All content on King Life LLC’s
            website, including text, images, and trademarks, is the property of
            King Life LLC. Unauthorized use is prohibited.
          </p>
          <p className="sm:mt-[24px] mt-8">
            <strong>7. Liability</strong>
            <br />
            <strong>7.1 Limitation of Liability:</strong> King Life LLC is not
            liable for indirect, incidental, or consequential damages arising
            from the use of our products or services.
            <br />
            <strong>7.2 Disclaimer:</strong> Products are provided "as is." We
            do not guarantee specific results from the use of our products.
          </p>
          <p className="sm:mt-[24px] mt-8">
            <strong>8. Confidentiality and Privacy</strong>
            <br />
            <strong>8.1 Privacy Policy:</strong> King Life LLC is committed to
            protecting your privacy. Personal information is handled in
            accordance with our Privacy Policy.
          </p>
          <p className="sm:mt-[24px] mt-8">
            <strong>9. Dispute Resolution</strong>
            <br />
            <strong>9.1 Governing Law:</strong> These Terms are governed by the
            laws of the jurisdiction in which King Life LLC operates.
            <br />
            <strong>9.2 Arbitration:</strong> Any disputes shall be resolved
            through binding arbitration in accordance with applicable laws.
          </p>
          <p className="sm:mt-[24px] mt-8">
            <strong>10. Termination</strong>
            <br />
            King Life LLC reserves the right to terminate services or accounts
            for violations of these Terms or other misuse.
          </p>
          <p className="sm:mt-[24px] mt-8">
            <strong >11. Contact Information</strong>
            <br />
            For questions or concerns about these Terms, please contact us at:
            <br />
            Email: support@kinglifellc.com
            <br />
            Phone: +97143445487
            <br />
            Address: Office no 308 Al Nayieli Building Near Abuhail Center
            Hor Al Anz East, Deira, Dubai, UAE
          </p>
        </div>
      </div>
    </div>
  );
}

export default TermsAndCondition;
