import React, { createContext, useState } from 'react'
import Header from './Sections/Header/Header'
import Sidebar from './Sections/Sidebar/Sidebar'
import { Outlet } from 'react-router-dom'
import AdminLogin from './Pages/AdminLogin/AdminLogin'
import AdminNewLogin from './Pages/AdminLogin/AdminNewLogin'

export const adminContext = createContext();

function AdminLayout() {

  const [token, setToken] = useState(localStorage.getItem('adminToken'))

  return (
    <adminContext.Provider value={{ token, setToken }}>
      <div>
        {token ? <div>
          <Header />
          <div className='flex gap-0 pt-[111px] bg-[#F8F9FA]'>
            <Sidebar />
            <Outlet />
          </div>
        </div> : <AdminNewLogin />}
      </div>
    </adminContext.Provider>
  )
}

export default AdminLayout
