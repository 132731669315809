import React, { useState } from 'react';
import Swal from 'sweetalert2';
import DeleteIcon from "../../../Assets/Admin/delete.svg";
import RightArrow from "../../../Assets/Admin/chevron_forward.svg";

function AdminBlogPage() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
    const [isSubBlogModalOpen, setIsSubBlogModalOpen] = useState(false);
    const [isSubPreviewModalOpen, setIsSubPreviewModalOpen] = useState(false);
    const [BlogcategoryName, setBlogCategoryName] = useState('');
    const [image, setImage] = useState(null);
    const [BlogCategories, setBlogCategories] = useState([]);
    const [selectedBlogCategoryIndex, setSelectedBlogCategoryIndex] = useState(null);
    const [subBlogtName, setSubBlogName] = useState('');
    const [subBlogDescription, setSubBlogDescription] = useState('');
    const [subBlogImage, setSubBlogImage] = useState(null);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => {
        setIsModalOpen(false);
        setBlogCategoryName('');
        setImage(null);
    };

    const closePreviewModal = () => {
        setIsPreviewModalOpen(false);
    }

    const openSubBlogModal = (index) => {
        setSelectedBlogCategoryIndex(index);
        setIsSubBlogModalOpen(true);
    };

    const closeSubBlogModal = () => {
        setIsSubBlogModalOpen(false);
        setSubBlogName('');
        setSubBlogDescription('');
        setSubBlogImage(null);
    };

    const closeSubPreviewModal = () => {
        setIsSubPreviewModalOpen(false)
    }

    const BloghandleSave = () => {
        if (!BlogcategoryName.trim()) {
            Swal.fire({
                icon: 'warning',
                title: 'Empty Field',
                text: 'Category name cannot be empty.',
                confirmButtonColor: '#707FDD',
            });
            return;
        }
        setBlogCategories([...BlogCategories, { BlogcategoryName, image, subBlogs: [] }]);
        setBlogCategoryName('');
        setImage(null);
        closeModal();
    };


    const BlogHandleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImage(file);
        }
    };

    const handleSubBlogFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSubBlogImage(file);
        }
    };

    const handleAddSubBlog = () => {
        if (!subBlogtName.trim()) {
            Swal.fire({
                icon: 'warning',
                title: 'Empty Field',
                text: 'Sub-Product name cannot be empty.',
                confirmButtonColor: '#707FDD',
            });
            return;
        }
        const updatedCategories = BlogCategories.map((category, index) => {
            if (index === selectedBlogCategoryIndex) {
                return {
                    ...category,
                    subBlogs: [
                        ...category.subBlogs,
                        { subBlogtName, subBlogDescription, subBlogImage }
                    ]
                };
            }
            return category;
        });
        setBlogCategories(updatedCategories);
        closeSubBlogModal();
    };


    const handleDelete = (index) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#707FDD',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                setBlogCategories(BlogCategories.filter((_, i) => i !== index));
                if (selectedBlogCategoryIndex === index) {
                    setSelectedBlogCategoryIndex(null);
                } else if (selectedBlogCategoryIndex > index) {
                    setSelectedBlogCategoryIndex(selectedBlogCategoryIndex - 1);
                }
                Swal.fire({
                    icon: 'success',
                    title: 'Deleted!',
                    text: 'Category has been deleted.',
                    confirmButtonColor: '#707FDD',
                });
            }
        });
    };

    // Disable the Save button if categoryName is empty or image is not selected
    const isSaveButtonDisabled = !BlogcategoryName.trim() || !image;
    const isSubSaveButtonDisabled = !subBlogtName.trim() || !subBlogDescription || !subBlogImage;

    const handleSubBlogDelete = (subBlogIndex) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#707FDD',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                const updatedCategories = BlogCategories.map((category, index) => {
                    if (index === selectedBlogCategoryIndex) {
                        const updatedSubBlogs = category.subBlogs.filter((_, i) => i !== subBlogIndex);
                        return { ...category, subBlogs: updatedSubBlogs };
                    }
                    return category;
                });
                setBlogCategories(updatedCategories);
                Swal.fire({
                    icon: 'success',
                    title: 'Deleted!',
                    text: 'Sub-product has been deleted.',
                    confirmButtonColor: '#707FDD',
                });
            }
        });
    };


    const selectedCategory = selectedBlogCategoryIndex !== null ? BlogCategories[selectedBlogCategoryIndex] : null;

    return (
        <div className="p-10 w-full">
            {!selectedCategory && (
                <div className="flex justify-between">
                    <div className="font-normal text-[20px]">Blogs</div>
                    <button onClick={openModal} className="px-6 py-2 rounded-lg bg-[#707FDD] text-white font-normal text-[16px]">
                        Create New
                    </button>
                </div>
            )}

            {selectedCategory ? (
                <div className="mt-10">
                    <div className="flex justify-between items-center">
                        <div className='flex items-center '>
                            <button
                                onClick={() => setSelectedBlogCategoryIndex(null)}
                                className=" rounded-lg text-[#6E6E6E] font-normal text-[20px]"
                            >
                                Blog
                            </button>
                            <h2 className="font-normal text-[20px] flex items-center ml-2">
                                <img className='mr-2' src={RightArrow} alt="" />
                                {selectedCategory.BlogcategoryName}
                            </h2>
                        </div>
                        <button
                            onClick={() => openSubBlogModal(selectedBlogCategoryIndex)}
                            className="mt-4 px-6 py-2 rounded-lg bg-[#707FDD] text-white font-normal text-[16px]"
                        >
                            Create Sub-Product
                        </button>

                    </div>


                    {/* Display Sub-Blogs */}
                    <div className="mt-8">

                        {selectedCategory.subBlogs.length === 0 ? (
                            <p className="text-gray-600 mt-2">No sub-products available.</p>
                        ) : (
                            <ul className="space-y-2 mt-3">
                                {selectedCategory.subBlogs.map((subBlogs, subBlogIndex) => (
                                    <li key={subBlogIndex} className="bg-white custom-box-shadow flex justify-between items-center h-[174px] py-4 pl-[12px] pr-[38px] border-l-[12px] border-transparent hover:border-[#B7BDE3] transition-all duration-300">

                                        <div className="flex items-center">
                                            {subBlogs.subBlogImage && (
                                                <img
                                                    src={URL.createObjectURL(subBlogs.subBlogImage)}
                                                    alt={subBlogs.subBlogtName}
                                                    className="w-[199px] h-[142px] object-cover mr-6"
                                                />
                                            )}
                                            <div>
                                                <div className="text-black font-medium text-[20px]">{subBlogs.subBlogtName}</div>
                                                <div className="text-[#7B7B7B] text-[14px] font-normal">{subBlogs.subBlogDescription}</div>
                                            </div>
                                        </div>

                                        <button
                                            onClick={() => handleSubBlogDelete(subBlogIndex)}
                                            className="text-[#C30303] flex items-center justify-start translate-y-[-58px] hover:opacity-75 h-fit transition-opacity duration-200"
                                        >
                                            Delete <img src={DeleteIcon} alt="Delete" className="ml-1 w-4 h-4" />
                                        </button>

                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </div>
            ) : (

                /* BlogCategories */
                <div className="mt-8">
                    {BlogCategories.length === 0 ? (
                        <p className="text-gray-600">No Blog Categories available. Click "Create New" to add one.</p>
                    ) : (
                        <ul className="space-y-2">
                            {BlogCategories.map((category, categoryIndex) => (
                                <li key={categoryIndex}
                                    onClick={() => setSelectedBlogCategoryIndex(categoryIndex)}
                                    className="bg-white custom-box-shadow cursor-pointer">
                                    <div className="flex justify-between items-center h-[54px] py-4 pl-[12px] pr-[38px] border-l-[12px] border-transparent hover:border-[#B7BDE3] transition-all duration-300">
                                        <span
                                            className="text-[#4B5DD3] font-normal text-[14px] cursor-pointer"

                                        >
                                            {category.BlogcategoryName}
                                        </span>
                                        <div className="flex items-center space-x-[20px] font-normal text-[14px]">
                                            <span className="text-[#4B5DD3] cursor-default">
                                                {category.subBlogs.length} {category.subBlogs.length === 1 ? 'product' : 'products'}
                                            </span>
                                            <button onClick={() => handleDelete(categoryIndex)} className="text-[#C30303] flex items-center">
                                                Delete <span className="ml-1"><img src={DeleteIcon} alt="Delete" /></span>
                                            </button>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            )}

            {/* Modal for Adding Category */}
            {isModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white p-10 rounded-lg w-[807px] max-w-full mx-4">
                        <h2 className="text-[14px] font-light">Add Blog Category</h2>
                        <input
                            type="text"
                            placeholder="Category Name"
                            value={BlogcategoryName}
                            onChange={(e) => setBlogCategoryName(e.target.value)}
                            className="bg-[#F7F7F7] rounded-lg px-4 py-3 mt-3 w-full text-[16px] font-light focus:outline-none placeholder:text-[16px] placeholder:font-light"
                        />

                        <div className="text-[14px] font-light mt-6">Add Image</div>
                        <div className='flex'>
                            <div className="mt-3 flex items-center bg-[#F8FCFF] p-4 w-full max-w-xs">
                                <label className="border-[2px] border-[#707FDD] rounded cursor-pointer flex items-center text-[#707FDD] font-normal px-4 p-2 text-[14px]">
                                    Choose file
                                    <input type="file" onChange={BlogHandleFileChange} className="hidden" />
                                </label>
                                <span className="ml-2 text-gray-500 text-sm">
                                    {image ? image.name : 'No file chosen'}
                                </span>
                            </div>
                            {image && (
                                <button
                                    onClick={() => setIsPreviewModalOpen(true)}
                                    className="mt-3 text-blue-500 underline ml-3">
                                    Preview Image
                                </button>
                            )}
                        </div>

                        <div className="flex justify-end mt-6">
                            <button onClick={closeModal} className="px-8 py-[15px] border-[2px] border-[#707FDD] text-[#707FDD] rounded-lg text-[16px] font-medium">Cancel</button>
                            <button onClick={BloghandleSave} className={`px-8 py-[15px] ${isSaveButtonDisabled ? 'bg-[#707FDD] opacity-50 text-white cursor-not-allowed' : 'bg-[#707FDD] text-white'} rounded-lg text-[16px] font-medium ml-4`}
                                disabled={isSaveButtonDisabled}
                            >Save</button>
                        </div>
                    </div>
                </div>
            )}

            {isPreviewModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white p-6  w-[700px]  mx-4">
                        <div className='flex justify-between items-end'>
                            <h2 className="text-[16px] font-light mb-4">Image Preview</h2>
                            <button
                                onClick={closePreviewModal}
                                className='mb-4'
                            >
                                X
                            </button>
                        </div>
                        {image ? (
                            <img
                                src={URL.createObjectURL(image)}
                                alt="Preview"
                                className="w-full h-auto r shadow-sm"
                            />
                        ) : (
                            <p className="text-gray-500 text-sm">No image selected</p>
                        )}
                        <div className="flex justify-end mt-6">

                        </div>
                    </div>
                </div>
            )}

            {/* Modal for Adding Sub-Product */}
            {isSubBlogModalOpen && selectedBlogCategoryIndex !== null && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white p-10 rounded-lg w-[807px] max-w-full mx-4">
                        <h2 className="text-[14px] font-light">Add Tittle</h2>
                        <input
                            type="text"
                            placeholder=""
                            value={subBlogtName}
                            onChange={(e) => setSubBlogName(e.target.value)}
                            className="bg-[#F7F7F7] rounded-lg px-4 py-3 mt-3 w-full text-[16px] font-light focus:outline-none placeholder:text-[16px] placeholder:font-light"
                        />
                        <h2 className="text-[14px] font-light mt-6">Add Description</h2>
                        <textarea
                            placeholder="Sub-Product Description"
                            value={subBlogDescription}
                            onChange={(e) => setSubBlogDescription(e.target.value)}
                            className="bg-[#F7F7F7] rounded-lg px-4 py-3 mt-3 w-full h-[298px] text-[16px] font-light focus:outline-none placeholder:text-[16px] placeholder:font-light"
                            rows="4"
                        ></textarea>

                        <div className="text-[14px] font-light mt-6">Add Image</div>
                        <div className="mt-3 flex items-center bg-[#F8FCFF] p-4 w-full max-w-xs">
                            <label className="border-[2px] border-[#707FDD] rounded cursor-pointer flex items-center text-[#707FDD] font-normal px-4 p-2 text-[14px]">
                                Choose file
                                <input type="file" onChange={handleSubBlogFileChange} className="hidden" />
                            </label>
                            <span className="ml-2 text-gray-500 text-sm">{subBlogImage ? subBlogImage.name : 'No file chosen'}</span>
                        </div>
                        {subBlogImage && (
                            <button
                                onClick={() => setIsSubPreviewModalOpen(true)}
                                className="mt-3 text-blue-500 underline ml-3"
                            >
                                Preview Image
                            </button>
                        )}

                        <div className="flex justify-end mt-6">
                            <button onClick={closeSubBlogModal} className="px-8 py-[15px] border-[2px] border-[#707FDD] text-[#707FDD] rounded-lg text-[16px] font-medium">Cancel</button>
                            <button onClick={handleAddSubBlog} className={`px-8 py-[15px] ${isSubSaveButtonDisabled ? 'bg-[#707FDD] opacity-50 text-white cursor-not-allowed' : 'bg-[#707FDD] text-white'} rounded-lg text-[16px] font-medium ml-4`}
                                disabled={isSubSaveButtonDisabled}>Add</button>
                        </div>
                    </div>
                </div>
            )}

            {isSubPreviewModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white p-6  w-[700px] mx-4">
                        <h2 className="text-[16px] font-medium mb-4">Image Preview</h2>
                        {subBlogImage ? (
                            <img
                                src={URL.createObjectURL(subBlogImage)}
                                alt="Preview"
                                className="w-full h-auto  shadow-sm"
                            />
                        ) : (
                            <p className="text-gray-500 text-sm">No image selected</p>
                        )}
                        <div className="flex justify-end mt-6">
                            <button
                            onClick={closeSubPreviewModal}
                                className="px-6 py-3 bg-[#707FDD] text-white rounded-lg text-[14px] font-medium"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}

        </div>
    );
}

export default AdminBlogPage;
