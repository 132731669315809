import React, { useRef } from "react";
import { motion, useInView } from "framer-motion";

function AboutBanner() {
  const foundationYear = 2019;
  const currentYear = new Date().getFullYear();
  const yearsOfTrust = currentYear - foundationYear;

  // Create a ref for the text container
  const textRef = useRef(null);
  const inView = useInView(textRef, { once: true });

  return (
    <div className="bg-cover bg-center sm:bg-AboutBanner bg-AboutBannerRes sm:rounded-b-[16px] w-full">
      <div className="sm:h-[477px] h-[343px] flex sm:justify-between sm:pr-[128px]">
        {/* Left Text (About Us) */}
        <motion.div
          ref={textRef}
          initial={{ opacity: 0, y: 50 }}
          animate={inView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 1, ease: "easeInOut" }}
          className="text-white sm:text-[32px] mt-[288px] ml-[24px] text-[24px] font-medium sm:ml-[80px] sm:mt-[411px]"
        >
          About Us
        </motion.div>

        {/* Right Text (Years Of Trust) */}
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={inView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 1, delay: 0.3, ease: "easeInOut" }}
          className="font-semibold sm:text-[82px] text-[82px] leading-[24px] mt-[118px] items-center justify-center sm:pb-[40px] sm:pl-[120px] text-white sm:justify-end sm:mt-[368px]"
        >
          {yearsOfTrust}
          <span className="text-[34px] ml-[8px] font-light leading-[24px] text-white">
            Years
          </span>
          <div className="text-white font-normal mt-[8px] sm:pb-[40px] leading-[40px] text-[37px]">
            Of Trust
          </div>
        </motion.div>
      </div>
    </div>
  );
}

export default AboutBanner;
