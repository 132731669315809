import React from "react";
import Image1 from "../../../Assets/ProductDetailedPage/Rectangle 5538.png";
import Image2 from "../../../Assets/ProductDetailedPage/Rectangle 5539.png";
import NeedMoreHelp from "../../Component/NeedMoreHelp/NeedMoreHelp";
import products from "../../../Json/products.json";
import { useParams } from "react-router-dom";

function ProductDetailedPage() {
  const { productId } = useParams();
  const product = products.find((item) => item.id === parseInt(productId));

  if (!product) {
    return <div>Product not found</div>;
  }

  return (
    <div className="text-[#4B4B4B]">
      <div className="w-full sm:h-[480px] h-[438px] sm:pt-[242px] pt-[166px] sm:bg-newsdetailBG bg-newsdetailMob bg-cover bg-center text-center items-center">
      <h1 className="font-medium sm:text-[32px] text-[24px] text-white ">Crafted By Nature</h1>
      <h1 className="font-light sm:text-[24px] text-[16px] text-white ">Bringing global flavors closer to you</h1>


      
      </div>
    
      <div className="sm:px-20 px-4 sm:mt-14 mt-10">
        <div>
          <h1 className="text-[32px] font-medium">{product.name}</h1>
          <p className="sm:mt-6 mt-8 text-[16px] font-light text-justify">
           {product.description1}
          </p>
        </div>
        <div className="sm:flex hidden columns-2 gap-6 items-center justify-center sm:mt-10">
          <div>
            <img className="sm:w-full sm:h-[372px]" src={Image1} alt="" />
          </div>
          <div>
            <img className="sm:w-full sm:h-[372px]" src={Image2} alt="" />
          </div>
        </div>
        <div className="sm:hidden mt-10 mb-10 ">
          <div>
            <img className="" src={Image1} alt="" />
          </div>
        </div>
        <div>
          <p className="sm:mt-10 text-[16px] font-light text-justify">
       {product.description2}
          </p>
        </div>
        {/* <div>
          <h1 className="text-[32px] font-medium mt-14">
            King Life LLC stands
          </h1>
          <p className="sm:mt-4 text-[16px] font-light text-justify">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's <br /> standard dummy
            text ever since the 1500s
          </p>
        </div> */}
        {/* <div>
          <h1 className="text-[32px] font-medium mt-14">
            King Life LLC stands
          </h1>
          <p className="mt-4 text-[16px] font-light text-justify">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's <br /> standard dummy
            text ever since the 1500s
          </p>
        </div>
        <div>
          <p className="mt-10 text-[16px] font-light text-justify">
            King Life LLC stands as a premier exporter in the realm of natural
            products, specializing in a diverse array of offerings that include
            herbal extracts, fragrance oils, spice powders, and oleoresins. Our
            commitment to quality is unwavering, as wemeticulously source and
            process each product to ensure that it meets the highest standards
            of purity and efficacy. By leveraging advanced extraction techniques
            and adhering to stringent quality control measures, we guarantee
            that our products not only fulfill but exceed the expectations of
            our discerning King Life LLC stands as a premier exporter in the
            realm of natural products, specializing in a diverse array of
            offerings that include herbal extracts, fragrance oils, spice
            powders, and oleoresins. Our commitment to quality is unwavering, as
            wemeticulously source and process each product to ensure that it
            meets the highest standards of purity and efficacy. By leveraging
            advanced extraction techniques and adhering to stringent quality
            control measures, we guarantee that our products not only fulfill
            but exceed the expectations of our discerning{" "}
          </p>
        </div> */}
      </div>
      <NeedMoreHelp />
    </div>
  );
}

export default ProductDetailedPage;
