import React from "react";

// Function to calculate the number of years since a given start year
function calculateYearsSince(startYear) {
  const currentYear = new Date().getFullYear();
  return currentYear - startYear;
}

function AboutContent() {
  const foundingYear = 2019; // Replace with King Life LLC's founding year
  const years = calculateYearsSince(foundingYear);

  return (
    <div className="sm:px-[80px] sm:mt-[56px] mt-[56px] px-[16px]">
      <div className="text-[#070C3D] sm:text-[40px] text-[32px] font-normal leading-[40px] sm:leading-[48px] ">
        Premier Exporter in the Realm of natural products
      </div>
      <div className="sm:mt-[32px] mt-[24px] text-justify text-[#4B4B4B] sm:text-[16px] text-[16px] sm:leading-[32px] leading-[28px] font-light">
        King Life LLC is a pioneer in the natural products industry,
        specializing in the production and export of high-quality herbal
        extracts, fragrance oils, spice powders, and oleoresins. By sourcing the
        finest raw materials from trusted suppliers worldwide, we ensure the
        potency and purity of our offerings. Our advanced extraction techniques
        preserve the natural essence of each product, meeting the demands of
        industries like food and beverages, personal care, and nutraceuticals.
        With a commitment to excellence and sustainability, our products elevate
        flavor, fragrance, and functionality while adhering to the highest
        international standards.
      </div>
      <div className="sm:mt-[32px] mt-[24px] text-justify text-[#4B4B4B] sm:text-[16px] text-[16px] sm:leading-[32px] leading-[28px] font-light">
        At King Life LLC, we blend traditional knowledge with cutting-edge
        technology to create innovative, eco-friendly solutions. Guided by a
        respect for biodiversity, our processes minimize waste through
        sustainable techniques like supercritical CO₂ extraction. Rigorous
        quality controls ensure each product meets customer expectations, while
        our customer-centric approach offers tailored solutions. By fostering
        trust and delivering unmatched value, we aim to shape the future of the
        natural products industry as a global leader.
      </div>

      <div className=" sm:mt-[56px] mt-[72px] text-[#070C3D] sm:text-[40px] text-[32px] font-normal leading-[40px] sm:leading-[48px] ">
        Key milestones in the company’s history
      </div>
      <div className="sm:mt-[32px] mt-[24px] text-justify text-[#4B4B4B] sm:text-[16px] text-[16px] sm:leading-[32px] leading-[28px] font-light">
        King Life LLC has established itself as a trusted leader in the natural
        products industry, guided by an unwavering commitment to quality,
        innovation, and sustainability. Over the past {years} years, we have
        cultivated strong partnerships with more than 200 clients worldwide,
        offering a diverse portfolio of 30 premium products, including herbal
        extracts, fragrance oils, and spice powders. Each product exemplifies
        our dedication to purity, potency, and eco-conscious practices,
        reflecting our deep respect for the environment. Leveraging cutting-edge
        extraction technologies and sustainable sourcing, we cater to the
        evolving needs of industries such as nutraceuticals, personal care, and
        food and beverages. Through rigorous quality control measures and
        customer-centric solutions, King Life LLC has built enduring
        relationships, earning a reputation for reliability and trust. Our focus
        on innovation allows us to stay ahead in an ever-changing market,
        delivering products that meet the highest industry standards. Every
        milestone reaffirms our mission to lead and excel in the natural
        products domain, shaping a sustainable and prosperous future
      </div>

      <div className="sm:flex sm:justify-between mt-[66px] sm:mt-[56px]">
        <div className="sm:h-[160px] mt-[66px] sm:mt-0 sm:w-[283px]">
          <h2 className="text-[#070C3D] sm:text-[32px] text-[32px] font-normal">
            {years} Years
          </h2>
          <p className="sm:mt-[16px] mt-[16px] text-justify text-[#4B4B4B] sm:text-[16px] text-[16px] sm:leading-[24px] leading-[24px] font-light">
            Pioneering excellence for over {years} years, King Life LLC remains
            a trusted leader in premium natural products globally.
          </p>
        </div>
        <div className="sm:h-[160px] sm:mt-0 mt-6 sm:w-[283px]">
          <h2 className="text-[#070C3D] sm:text-[32px] text-[32px] font-normal">
            200+ Clients
          </h2>
          <p className="sm:mt-[16px] mt-[16px] text-justify text-[#4B4B4B] sm:text-[16px] text-[16px] sm:leading-[24px] leading-[24px] font-light">
            Serving 200+ satisfied clients worldwide with tailored natural
            solutions and unwavering commitment to quality.
          </p>
        </div>
        <div className="sm:h-[160px] sm:mt-0 mt-6 sm:w-[283px]">
          <h2 className="text-[#070C3D] sm:text-[32px] text-[32px] font-normal">
            30+ Products
          </h2>
          <p className="sm:mt-[16px] mt-[16px] text-justify text-[#4B4B4B] sm:text-[16px] text-[16px] sm:leading-[24px] leading-[24px] font-light">
            Offering 30+ premium products, including herbal extracts, essential
            oils, and spice-based ingredients for diverse industries.
          </p>
        </div>
        <div className="sm:h-[160px] sm:mt-0 mt-6 sm:w-[283px]">
          <h2 className="text-[#070C3D] sm:text-[32px] text-[32px] font-normal">
            20 Values
          </h2>
          <p className="sm:mt-[16px] mt-[16px] text-justify text-[#4B4B4B] sm:text-[16px] text-[16px] sm:leading-[24px] leading-[24px] font-light">
            We uphold excellence, sustainability, trust, and innovation to
            deliver unmatched quality and service.
          </p>
        </div>
      </div>
    </div>
  );
}

export default AboutContent;
